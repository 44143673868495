import { createSelector } from 'reselect'

const tagsSelector = state => state.tags.list
const idSelector = (state,parent_tag_id)=> parent_tag_id

const getTagsByParent = (tags,parent_tag_id)=> {
  const result = Object.values(tags).filter( tag => tag.parent_tag_id === parent_tag_id )
  return result
}

export default createSelector(
  [tagsSelector,idSelector],
  getTagsByParent
)