import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'
import { obj2search } from '|/utils'

import {
  FETCH_POSTS,
  FETCHING_POSTS,
  FETCHED_POSTS,
  FETCHING_POSTS_FAILED,
  SEARCH_POSTS,
  SEARCHING_POSTS,
  SEARCHED_POSTS,
  SEARCHING_POSTS_FAILED,
  SEARCH_NEXT_POSTS,
  SEARCHING_NEXT_POSTS,
  SEARCHED_NEXT_POSTS,
  SEARCHING_NEXT_POSTS_FAILED,
  CREATE_POST,
  CREATE_COMMENT,
  CREATING_POST,
  CREATED_POST,
  CREATING_POST_FAILED,
  UPDATE_POST,
  UPDATING_POST,
  UPDATED_POST,
  UPDATING_POST_FAILED,
  DELETE_POST,
  DELETING_POST,
  DELETED_POST,
  DELETING_POST_FAILED,
  FETCH_POST,
  FETCHING_POST,
  FETCHED_POST,
  FETCHING_POST_FAILED,
} from '|/store/constants'

function* fetchPosts({ query }) {
  yield put({
    type: FETCHING_POSTS
  })
  let token = yield call(storage.getItem,'token')
  const search = obj2search(query)
  const { results, error } = yield call(api.fetchPosts, search, token)
  if(error) {
    yield put({
      type: FETCHING_POSTS_FAILED,
      error: error
    })
  } else {
    yield put({
      type: FETCHED_POSTS,
      data: results
    })
  }
}

function* searchPosts({ query }) {
  yield put({
    type: SEARCHING_POSTS
  })
  let token = yield call(storage.getItem,'token')
  const search = obj2search(query)
  const { count, results, next, facets, error } = yield call(api.fetchPosts, search, token)
  if(error) {
    yield put({
      type: SEARCHING_POSTS_FAILED,
      error: error
    })
  } else {
    yield put({
      type: SEARCHED_POSTS,
      data: {
        results,
        count,
        facets,
        next,
      },
    })
  }
}

function* searchNextPosts({ url }) {
  yield put({
    type: SEARCHING_NEXT_POSTS
  })
  let token = yield call(storage.getItem,'token')
  const { count, results, next, facets, error } = yield call(api.fetchURL, url, token)
  if(error) {
    yield put({
      type: SEARCHING_NEXT_POSTS_FAILED,
      error: error
    })
  } else {
    yield put({
      type: SEARCHED_NEXT_POSTS,
      data: {
        results,
        count,
        facets,
        next,
      },
    })
  }
}

function* fetchPost({ post_id }) {
  yield put({
    type: FETCHING_POST
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchPost, post_id, token)
  if(response.error) {
    yield put({
      type: FETCHING_POST_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: FETCHED_POST,
      data: response
    })
  }
}

function* createPost({ context_id, post }) {
  yield put({
    type: CREATING_POST
  })
  let token = yield call(storage.getItem,'token')
  const { error, non_field_errors, ...response } = yield call(api.createPost, context_id, post, token)
  if(error) {
    yield put({
      type: UPDATING_POST_FAILED,
      error: error
    })
  } else if(non_field_errors) {
    yield put({
      type: UPDATING_POST_FAILED,
      error: { non_field_errors }
    })
  } else {
    yield put({
      type: CREATED_POST,
      data: response
    })
  }
}

function* createComment({ post_id, post }) {
  yield put({
    type: CREATING_POST
  })
  let token = yield call(storage.getItem,'token')
  const { error, non_field_errors, ...response } = yield call(api.createComment, post_id, post, token)
  if(error) {
    yield put({
      type: UPDATING_POST_FAILED,
      error: error
    })
  } else if(non_field_errors) {
    yield put({
      type: UPDATING_POST_FAILED,
      error: { non_field_errors }
    })
  } else {
    yield put({
      type: CREATED_POST,
      data: response
    })
  }
}

function* updatePost({ id, post }) {
  yield put({
    type: UPDATING_POST
  })
  let token = yield call(storage.getItem,'token')
  const { error, non_field_errors, ...response } = yield call(api.updatePost, id, post, token)
  if(error) {
    yield put({
      type: UPDATING_POST_FAILED,
      error: error
    })
  } else if(non_field_errors) {
    yield put({
      type: UPDATING_POST_FAILED,
      error: { non_field_errors }
    })
  } else {
    yield put({
      type: UPDATED_POST,
      data: response
    })
  }
}

function* deletePost({ post_id, callback }) {
  yield put({
    type: DELETING_POST
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.deletePost, post_id, token)
  console.log('CALLBACK',response)
  if(response.error) {
    yield put({
      type: DELETING_POST_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: DELETED_POST,
      data: post_id
    })
    if(callback)
      callback(post_id)
  }
}

export default function* posts(App) {
  yield all([
    takeEvery(FETCH_POSTS,fetchPosts),
    takeEvery(SEARCH_POSTS,searchPosts),
    takeEvery(SEARCH_NEXT_POSTS,searchNextPosts),
    takeEvery(CREATE_POST,createPost),
    takeEvery(CREATE_COMMENT,createComment),
    takeEvery(UPDATE_POST,updatePost),
    takeEvery(DELETE_POST,deletePost),
    takeEvery(FETCH_POST,fetchPost),
  ])
}