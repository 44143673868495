import '|/utils/prototypes'
import '|/icons'
import '|/variables.styl'
// import registerServiceWorker from './registerServiceWorker'
import React from 'react'
import { render } from 'react-dom'
import Root from 'Root'

window.IS_MOBILE = window.innerWidth <= 1024

render(
  <Root />
,document.getElementById('root'))

// registerServiceWorker()