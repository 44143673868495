import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import api from '|/api'
import storage from '|/utils/storage'

import {
  ADD_ATTACHMENT,
  ADDING_ATTACHMENT,
  ADDED_ATTACHMENT,
  SIGNING_ATTACHMENT,
  SIGNED_ATTACHMENT,
  SIGNING_ATTACHMENT_FAILED,
  UPLOADING_ATTACHMENT,
  UPLOADED_ATTACHMENT,
  UPLOADING_ATTACHMENT_FAILED,
} from '../constants'

function* signAttachment(name,type) {
  yield put({
    type: SIGNING_ATTACHMENT,
  })
  const token = yield call(storage.getItem,'token')
  const signature = yield call(api.signAttachment,name,type,token)
  if(signature.temp_upload_id) {
    return signature
  } else {
    yield put({
      type: SIGNING_ATTACHMENT_FAILED,
    })
  }
}

function* uploadAttachment(file,signedUrl,state,setState) {
  console.log('uploading file',file)
  yield put({
    type: UPLOADING_ATTACHMENT,
  })
  const channel = yield call(api.uploadAttachment,file,signedUrl)
  while(true) {
    const { progress=0, error, success } = yield take(channel)
    if(success) {
      yield put({
        type: UPLOADED_ATTACHMENT,
      })
      return true
    } else if(error) {
      yield put({
        type: UPLOADING_ATTACHMENT_FAILED,
      })
      return false
    }
    yield call(setState,{
      ...state,
      [file.name]: {
        filename: file.name,
        progress,
      }
    })
  }
}

function* addAttachment({ attachment, state, setState }) {
  yield put({
    type: ADDED_ATTACHMENT,
  })
  let { path, name, type } = attachment
  yield call(setState,{
    ...state,
    [name]: {
      filename: name,
      progress: 0,
    }
  })
  const { temp_upload_id, url, signedUrl } = yield call(signAttachment,name,type)
  yield put({
    type: SIGNED_ATTACHMENT,
  })
  const didUpload = yield call(uploadAttachment,attachment,signedUrl,state,setState)
  if(didUpload) {
    yield call(setState,{
      ...state,
      [name]: {
        id: temp_upload_id,
        thumb: url,
        filename: name,
        filetype: type,
        new: true,
      }
    })
    yield put({
      type: ADDED_ATTACHMENT,
    })
  }
}

export default function* posts(App) {
  yield all([
    takeEvery(ADD_ATTACHMENT,addAttachment),
  ])
}



















