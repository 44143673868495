import merge from 'lodash/merge'

import {
  FETCHING_POSTS,
  FETCHED_POSTS,
  SEARCHING_POSTS,
  SEARCHED_POSTS,
  SEARCHING_NEXT_POSTS,
  SEARCHED_NEXT_POSTS,
  CREATING_POST,
  CREATED_POST,
  CREATING_POST_FAILED,
  UPDATING_POST,
  UPDATED_POST,
  UPDATING_POST_FAILED,
  DELETED_POST,
  FETCHING_POST,
  FETCHED_POST,
  FETCHING_POST_FAILED,
  CLEAR_POSTS_ERRORS,
} from '../constants'

const INITIAL_STATE = {
  list: {},
  search_result_ids: [],
  facets: [],
  loading: false,
  saving: false,
  saved: undefined,
  next: undefined,
  error: undefined,
}

export default function(state=INITIAL_STATE,action) {
  switch(action.type) {
    case FETCHING_POSTS:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case FETCHED_POSTS:
      const { data: fetch_data } = action
      const new_fetched_posts = fetch_data.reduce((obj,pst)=> {
        obj[pst.id] = merge(pst,state.list[pst.id])
        return obj
      },{})
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...new_fetched_posts
        },
      }
    case SEARCHING_POSTS:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SEARCHED_POSTS:
      const new_searched_posts = action.data.results.reduce((obj,pst)=> {
        obj[pst.id] = merge(pst,state.list[pst.id])
        return obj
      },{})
      const search_result_ids = action.data.results.reduce((arr,pst)=> {
        arr.push(pst.id)
        return arr
      },[])
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...new_searched_posts
        },
        search_result_ids,
        search_result_count: action.data.count,
        facets: {
          ...action.data.facets
        },
        next: action.data.next,
      }
    case SEARCHING_NEXT_POSTS:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case SEARCHED_NEXT_POSTS:
      const next_searched_posts = action.data.results.reduce((obj,pst)=> {
        obj[pst.id] = merge(pst,state.list[pst.id])
        return obj
      },{})
      const next_search_result_ids = action.data.results.reduce((arr,pst)=> {
        arr.push(pst.id)
        return arr
      },[])
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...next_searched_posts
        },
        search_result_ids: [
          ...state.search_result_ids,
          ...next_search_result_ids,
        ],
        search_result_count: action.data.count,
        facets: {
          ...state.facets,
          ...action.data.facets
        },
        next: action.data.next,
      }
    case CREATING_POST:
      return {
        ...state,
        error: null,
        saving: true,
        saved: undefined,
      }
    case CREATED_POST:
      const new_post = action.data
      return {
        ...state,
        saving: false,
        list: {
          ...state.list,
          [new_post.id]: {
            ...new_post,
            ...( new_post.tags && { tag_ids: new_post.tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
          }
        },
        saved: new_post,
      }
    case CREATING_POST_FAILED:
      return {
        ...state,
        error: action.error,
        saving: false,
      }
    case UPDATING_POST:
      return {
        ...state,
        error: null,
        saving: true,
        saved: undefined,
      }
    case UPDATED_POST:
      const updated_post = action.data
      return {
        ...state,
        saving: false,
        list: {
          ...state.list,
          [updated_post.id]: {
            ...updated_post,
            ...( updated_post.tags && { tag_ids: updated_post.tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
          }
        },
        saved: updated_post,
      }
    case UPDATING_POST_FAILED:
      return {
        ...state,
        error: action.error,
        saving: false,
      }
    case FETCHING_POST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case FETCHED_POST:
      const timeline = action.data
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...timeline.reduce((obj,p)=> {
            obj[p.id] = {
              ...state.list[p.id],
              ...p,
              ...( p.tags && { tag_ids: p.tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
            }
            console.log(obj)
            return obj
          },{})
        }
      }
    case FETCHING_POST_FAILED:
      return {
        ...state,
        loading: false,
      }
    case DELETED_POST:
      const clear_id = action.data
      const { [clear_id]:_, ...new_list } = state.list
      const new_search_result_ids = state.search_result_ids.without(clear_id)
      return {
        ...state,
        list: new_list,
        search_result_ids: new_search_result_ids,
      }
    case CLEAR_POSTS_ERRORS:
      return {
        ...state,
        error: null,
      }
  }
  return state
}