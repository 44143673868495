import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getContext } from '|/store/contexts/selectors'

import Markdown from '|/components/Markdown'

import Plink from '|/helpers/Plink'

import {
  FETCH_CONTEXT
} from '|/store/constants'

import styles from './main.styl'

const Context = ({ context })=> {

  const dispatch = useDispatch()
  const parent = useSelector( state => getContext(state, context.parent_context_id))

  useEffect(()=> {
    dispatch({
      type: FETCH_CONTEXT,
      id: context.parent_context_id
    })
  },[])

  if(!context) return null
  return (
    <div className={ `${styles.context}` }>
      <Plink
        to={{
          left: {
            c: context.parent_context_id || 0
          },
          search: {
            context_ids: context.parent_context_id || undefined
          }
        }}
      >
        &larr; { parent && `'${parent.title}'` }
      </Plink>
      <h2 className={ styles.title }>{ context.title }</h2>
      <div className={ styles.description }>
        <Markdown source={ context.description } />
      </div>
    </div>
  )
}

Context.propTypes = {
  context: PropTypes.object
}

export default Context