import React from 'react'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'

const replacer = (match, id)=> `[digest-link](#post-${id})`

const renderers = {
  link: ({ children, href, title })=> {
    if(children.length && children[0].props.children === 'digest-link')
      return (
        <a className="dot" href={ href } />
      )
    return (
      <a href={ href } title={ title } target="_blank">{ children }</a>
    )
  }
}

const Markdown = ({ source })=> {

  const parsedSource = source.replace(/\[#([\d]+)]/g, replacer)

  return (
    <ReactMarkdown renderers={ renderers } plugins={[ breaks ]} source={ parsedSource } />
  )
}

export default Markdown