import moment from 'moment'
import React, { Fragment, useState, useLayoutEffect, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '|/store'

import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import Player from 'react-player'

import { getPost, getComments } from '|/store/posts/selectors'
import { getTagsByPost } from '|/store/tags/selectors'
import { getContext, getContextsByIds } from '|/store/contexts/selectors'
import { useParams } from '|/hooks/router'
import { PostContext } from '|/contexts/Post'
import { usePathState } from '|/hooks/pathState'

import {
  NA,
  SUCCEEDED,
  FETCH_POST,
} from '|/store/constants'

import Markdown from '|/components/Markdown'

import { Fa } from '|/icons'
import Overlay from '|/helpers/Overlay'
import Img from '|/helpers/Img'
import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'
import Clickable from '|/helpers/Clickable'
import Loader from '|/helpers/Loader'
import Comment from './Comment'
import CommentForm from './Comment/CommentForm'
import ListItem from '|/components/ListItem'

import styles from './main.styl'

const PostView = props => {

  const params = useParams()
  const pathState = usePathState()
  const dispatch = useDispatch()
  const [ swiper, updateSwiper ] = useState(null)
  const [ main_context_id, setMain_context_id ] = useState()
  const [ context_ids, setContext_ids ] = useState([])
  const post = useSelector( state => getPost(state,params.post_id) )
  const comments = useSelector( state => getComments(state,params.post_id) )
  const context = useSelector( state => getContext(state,main_context_id) )
  const tags = useSelector( state => getTagsByPost(state,post) )
  const contexts = useSelector( state => getContextsByIds(state,context_ids) )
  const [ created_at, setCreated_at ] = useState()
  const [ ownerUsableName, setOwnerUsableName ] = useState()
  const [ isMaximised, setIsMaximised ] = useState()
  const [ hasAttachments, setHasAttachments ] = useState()
  const { setEditing, setCurrentComment, currentComment } = useContext(PostContext)

  console.log('COMMENTS',comments)

  const _handleEditClick = (e)=> {
    setEditing(true)
  }

  const _onNewCommentClick = (e)=> {
    setCurrentComment({ parent_post_id: post.id })
  }

  const _toggleMaximised = (e)=> {
    setIsMaximised(!isMaximised)
  }

  const _updateSwiperSize = (e)=> {
    console.log('UPDATING SWIPER SIZE')
    swiper.update()
  }

  useLayoutEffect(()=> {
    if(swiper)
      swiper.update()
  },[ isMaximised ])

  useEffect(()=> {
    if(post)
      setHasAttachments(post.attachments && post.attachments.length > 0)
  },[ post ])

  useEffect(()=> {
    const { post_id } = params
    if(post_id) {
      dispatch({
        type: FETCH_POST,
        post_id
      })
    }
  },[ params.post_id ])

  useEffect(()=> {
    if(post) {
      console.log(post)
      let created_on = moment(post.created_on)
      // TBF
      if(!created_on.isValid()) created_on = moment(post.created_on,'DD.MM.YYYY HH:mm:ss')
      setCreated_at(created_on.format('YYYY.MM.DD - HH:mm'))
      setMain_context_id(post.main_context_id)
      setContext_ids(post.context_ids)
      setOwnerUsableName(post.owner_usable_name)
    }
  },[ post ])

  const Comments = ()=> {
    return <div className={ styles.comments }>
      { comments.map((comment)=> {
        return <Comment key={`comment_${comment.id}`} comment={ comment } />
      })}
    </div>
  }

  const renderContent = (post)=> {
    return (
      <div
        className={ styles.content }
      >
        { hasAttachments &&
          <div className={ styles.carousel }>
            <Fragment>
              <Swiper
                getSwiper={ updateSwiper }
                watchOverflow={ true }
                navigation={{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                }}
                renderPrevButton={ ()=> <Button className="swiper-button-prev" round={ true } icon='chevron-left'/> }
                renderNextButton={ ()=> <Button className="swiper-button-next" round={ true } icon='chevron-right'/> }
              >
                { post.attachments.map((med,i)=> {
                  return <div
                      key={ `post_${post.id}_image_${i}` }
                      className={ styles.slide }
                    >
                      { med.filetype && med.filetype.indexOf('image/') === 0 &&
                          <img
                            src={ med.big_thumb !== undefined ? med.big_thumb : med }
                          />
                      }
                      { med.filetype && med.filetype === 'application/pdf' &&
                          <>
                            <img
                              src={ med.big_thumb !== undefined ? med.big_thumb : med }
                            />
                            <a
                              className={ styles.downloadFromSlide }
                              key={ `post_${post.id}_attachment_${i}` }
                              href={ med.download_url !== undefined ? med.download_url : med }
                              download
                            >
                              <Button
                                kind="highlighted"
                                icon={ 'arrow-to-bottom' }
                              >Download PDF</Button>
                            </a>
                          </>
                      }
                      { med.filetype && med.filetype.indexOf('video/') === 0 &&
                          med.processing_state !== SUCCEEDED ?
                            <div className={ styles.processing }>Processing video...</div>
                          :
                            <Player
                              url={ med.video_url }
                              controls={ true }
                            />
                      }
                    </div>
                })}
              </Swiper>
            </Fragment>
          </div>
        }
        <div
          className={ styles.body }
          onClick={ _updateSwiperSize }
        >
          <div className={ styles.md }>
            <Markdown source={ post.body } />
          </div>
          <div className={ styles.tools }>
            { post.is_owner &&
              <Button
                disabled={ currentComment }
                onClick={ _handleEditClick }
              >
                Edit
              </Button>
            }
          </div>
        </div>
        { post.data_attachments &&
          <div className={ styles.attachments }>
            { post.data_attachments.map((attch,i)=> {
              if(!attch.filename) return null
              return <a
                className={ styles.attachment }
                key={ `post_${post.id}_attachment_${i}` }
                href={ attch.download_url !== undefined ? attch.download_url : attch }
                download
              >
                <Button
                  material={ false }
                  link={ true }
                  icon={ 'arrow-to-bottom' }
                >
                  { attch.filename }
                </Button>
              </a>
            })}
          </div>
        }
      </div>
    )
  }

  if(!post) return null
  return (
  	<Overlay
      className={[
        styles.container,
        isMaximised ? styles.maximised : null,
      ].join(' ')}
      title={ `${created_at} by ${ownerUsableName}` }
      maximised={ isMaximised }
      onMaximise={ hasAttachments ? _toggleMaximised : undefined }
      onUnMaximise={ _toggleMaximised }
      onClose={ ()=> {
        const to = {
          overlay: undefined
        }
        history.push(pathState.toString(pathState.merge(to)))
      }}
    >
      { renderContent(post) }
      { comments &&
        <Comments />
      }
      { (!currentComment && context && context.is_member) &&
        <div className={ styles.tools }>
          <Button
            kind="constructive"
            onClick={ _onNewCommentClick }
          >
            Comment +
          </Button>
        </div>
      }
      { (currentComment && !currentComment.id) &&
        <div className={ styles.newComment }>
          <CommentForm
            comment={ currentComment }
          />
        </div>
      }
      <div className={ styles.footer }>
        { tags.length > 0 &&
          <div className={ styles.tags }>
            { tags.map((tag,i)=> {
              return <Plink
                  className={ styles.tag }
                  key={ `post_${post.id}_tag_${tag.id}` }
                  to={{
                    search: {
                      toggle: {
                        tag_ids: tag.id
                      }
                    }
                  }}
                >
                  <Button
                    material={ false }
                  >
                    { tag.name }
                  </Button>
                </Plink>
            })}
          </div>
        }
        { contexts.length > 0 &&
          <div className={ styles.contexts }>
            { contexts.map((context,i)=> {
              return <Plink
                  className={ styles.context }
                  key={ `post_${post.id}_tag_${context.id}` }
                  to={{
                    left: {
                      s: 'max',
                      c: context.id
                    },
                    search: {
                      main_context_id: context.parent_context_id || context.id
                    }
                  }}
                >
                  <Button
                    className={ styles.contextLink }
                    material={ false }
                    link={ true }
                    icon="arrow-left"
                  >
                    { context.title }
                  </Button>
                </Plink>
            })}
          </div>
        }
      </div>
    </Overlay>
  )
}

export default PostView