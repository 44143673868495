import React, { Fragment, useMemo, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getCurrentUser } from '|/store/users/selectors'
import { createGetFacetCount } from '|/store/posts/selectors/getFacetCount'

import ListItem from '|/components/ListItem'

import Collapse from '@material-ui/core/Collapse'

import { Fa } from '|/icons'
import Plink from '|/helpers/Plink'
import SelectButton from '|/helpers/SelectButton'
import ExpandButton from '|/helpers/ExpandButton'

import * as types from './types'
import styles from './main.styl'

import { AccordionContext } from '|/contexts/Accordion'

import {
  FETCH_CONTEXT
} from '|/store/constants'

const ContextListItem = ({ context, checked })=> {

  const getFacetCount = useMemo(createGetFacetCount,[])
  const currentUser = useSelector( state => getCurrentUser(state))
  const contextFacetCount = useSelector( state => getFacetCount(state,['context_ids'],context.id))
  const mainContextFacetCount = useSelector( state => getFacetCount(state,['main_context_id'],context.id))
  const [ expanded, handleChange ] = useContext(AccordionContext)
  const [ is_member, setIs_member ] = useState(false)

  const Type = types[context.type.capitalise()]
  const has_body = Type.Body && context.description !== undefined && context.description.length !== 0
  const facetCount = context.type === 'project' ? mainContextFacetCount : contextFacetCount

  useEffect(()=> {
    if(context.member_ids && currentUser)
      setIs_member(context.member_ids.indexOf(currentUser.id) !== -1)
  },[ currentUser, context ])

  return (
    <Fragment>
      <ListItem
        className={[ styles.listItem, facetCount === 0 ? styles.empty : undefined].join(' ')}
      >
        { is_member &&
          <Fa
            className={ styles.member_indicator }
            style="fas"
            icon="circle"
          />
        }
        { context.is_completed &&
          <Fa
            className={ styles.completed_indicator }
            icon="check"
          />
        }
        <Type.Head
          context={ context }
        />
        { has_body &&
          <ExpandButton
            onClick={ e => handleChange(context.id) }
            expanded={ expanded == context.id }
          />
        }
        <div
          className={ styles.facet }
        >
          { facetCount }
        </div>
        { context.type !== 'project' &&
          <Plink
            to={{
              search: {
                toggle: {
                  context_ids: context.id
                }
              }
            }}
          >
            <SelectButton
              selected={ checked }
            />
          </Plink>
        }
      </ListItem>
      { has_body &&
        <Collapse
          in={ expanded === context.id }
          className={ styles.listBody }
        >
          <Type.Body context={ context } />
        </Collapse>
      }
    </Fragment>
  )
}

ContextListItem.propTypes = {
  context: PropTypes.object
}

export default ContextListItem