import React from 'react'
import PropTypes from 'prop-types'

import Plink from '|/helpers/Plink'

import styles from './main.styl'

const ListItem = ({ children, className, to, ...props })=> {

  const classList = [ styles.listItem ]

  return (
    <div
      className={ `${classList.join(' ')} ${className}` }
      { ...props }
    >
      { children }
    </div>
  )
}

ListItem.propTypes = {
  to: PropTypes.object,
  label: PropTypes.node,
  className: PropTypes.string,
}

ListItem.defaultProps = {
  label: undefined,
  className: '',
}

export default ListItem