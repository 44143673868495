import merge from 'lodash/merge'

import {
  FETCHING_CONTEXTS,
  FETCHED_CONTEXTS,
  CREATING_CONTEXT,
  CREATED_CONTEXT,
  CREATING_CONTEXT_FAILED,
  UPDATING_CONTEXT,
  UPDATED_CONTEXT,
  UPDATING_CONTEXT_FAILED,
  FETCHING_CONTEXT,
  FETCHED_CONTEXT,
  FETCHING_CONTEXT_FAILED,
  DELETING_CONTEXT,
  DELETED_CONTEXT,
  DELETING_CONTEXT_FAILED,
  LOGGED_OUT,
} from '../constants'

const INITIAL_STATE = {
  list: {},
  loading: false,
  saving: false,
  saved: undefined,
}

export default function(state=INITIAL_STATE,action) {
  switch(action.type) {
    case LOGGED_OUT:
      return {
        ...INITIAL_STATE,
      }
    case FETCHING_CONTEXTS:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_CONTEXTS:
      const { data } = action
      const new_contexts = data.reduce((obj,ctx)=> {
        obj[ctx.id] = {
          ...ctx
        }
        return obj
      },{})
      return {
        ...state,
        loading: false,
        list: merge({},state.list,new_contexts),
      }
    case CREATING_CONTEXT:
      return {
        ...state,
        saving: true,
      }
    case CREATED_CONTEXT:
      const new_context = action.data
      return {
        ...state,
        saving: false,
        list: {
          ...state.list,
          [new_context.id]: {
            ...new_context,
            ...( new_context.tags && { tag_ids: new_context.tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
            ...( new_context.default_tags && { default_tag_ids: new_context.default_tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
          }
        },
        saved: new_context,
      }
    case CREATING_CONTEXT_FAILED:
      return {
        ...state,
        saving: false,
      }
    case UPDATING_CONTEXT:
      return {
        ...state,
        saving: true,
      }
    case UPDATED_CONTEXT:
      const updated_context = action.data
      return {
        ...state,
        saving: false,
        list: {
          ...state.list,
          [updated_context.id]: {
            ...updated_context,
            ...( updated_context.tags && { tag_ids: updated_context.tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
            ...( updated_context.default_tags && { default_tag_ids: updated_context.default_tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
          }
        },
        saved: updated_context,
      }
    case UPDATING_CONTEXT_FAILED:
      return {
        ...state,
        saving: false,
      }
    case FETCHING_CONTEXT:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_CONTEXT:
      const current = action.data
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          [current.id]:{
            ...current,
            ...( current.tags && { tag_ids: current.tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
            ...( current.default_tags && { default_tag_ids: current.default_tags.reduce((arr,t)=>(arr.push(t.id),arr),[]) } ),
          }
        },
      }
    case FETCHING_CONTEXT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case DELETED_CONTEXT:
      const clear_id = action.data
      const { [clear_id]:_, ...new_list } = state.list
      return {
        ...state,
        list: new_list,
      }
  }
  return state
}