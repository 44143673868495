import { createSelector } from 'reselect'

const searchCountSelector = (state)=> state.posts.search_result_count

const getSearchResultCount = (search_result_count)=> {
  return search_result_count
}

export default createSelector(
  [searchCountSelector],
  getSearchResultCount
)