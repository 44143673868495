import { createSelector } from 'reselect'

const contextsSelector = state => state.contexts.list
const idSelector = (state,id)=> id
const typeSelector = (state,id,type)=> type

const getContextsByType = (contexts,id,type)=> {
  return Object.values(contexts)
    .reduce((arr,ctx)=> {
      if((id == 0 || ctx.parent_context_id == id) && ctx.type === type)
        arr.push(ctx)
      return arr
    },[]).sort((a,b)=> a.rank - b.rank )
}

export default createSelector(
  [contextsSelector,idSelector,typeSelector],
  getContextsByType
)