import differenceBy from 'lodash/differenceBy'
import unionBy from 'lodash/unionBy'
import React, { Fragment, useRef, useState, useEffect, useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getContext, getSavedContext, getMyChannels } from '|/store/contexts/selectors'
import { getSavedDigest } from '|/store/digests/selectors'
import { getTagsByGroup } from '|/store/tags/selectors'
import { getUsers, getCurrentUser, getUsersByIds } from '|/store/users/selectors'

import { history } from '|/store'
import { useValidation } from '|/hooks/validation'
import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { LeftEditorContext } from '|/contexts/LeftEditor'

import { DigestContext } from '|/contexts/Digest'

import {
  ADD_ATTACHMENT,
  FETCH_CONTEXTS,
  FETCH_CONTEXT,
  CREATE_CONTEXT,
  UPDATE_CONTEXT,
  DELETE_CONTEXT,
  CREATE_DIGEST,
  UPDATE_DIGEST,
  FETCH_USERS,
} from '|/store/constants'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import ConfirmButton from '|/helpers/ConfirmButton'
import Input from '|/helpers/Input'
import Plink from '|/helpers/Plink'

import styles from './main.styl'

const DigestForm = props => {

  const {
    context,
    controls,
    nextRecord,
    minimised,
  } = props

  if(!context) return null

  const [ selectionStart, setSelectionStart ] = useState(0)
  const { setDigesting, post_id, setPost_id, setMain_context_id } = useContext(DigestContext)
  const myChannels = useSelector( state => getMyChannels(state,context.parent_context_id) )

  const _onChange = (key,val)=> {
    props.onChange(key,val)
  }

  const _onBlur = (e)=> {
    setSelectionStart(e.target.selectionStart)
  }

  const _onSubmit = (e)=> {
    props.onSubmit(e)
  }

  useEffect(()=> {
    if(post_id) {
      const body = nextRecord.body || ''
      const new_value = [body.slice(0,selectionStart),`[#${post_id}]`,body.slice(selectionStart)].join('')
      props.onChange('body',new_value)
      setPost_id(undefined)
    }
  },[ post_id ])

  useEffect(()=> {
    setDigesting(!minimised)
  },[ minimised ])

  useEffect(()=> {
    setDigesting(true)
    setMain_context_id(context.parent_context_id)
    return ()=> {
      setDigesting(false)
      setMain_context_id(undefined)
    }
  },[])

  return (
    <form onSubmit={ _onSubmit }>
      { Object.keys(controls).map((key)=>
          <Input
            key={ `left-formElement-${key}` }
            name={ key }
            {...{
              ...controls[key],
              ...( key === 'channel_id' ? { options:myChannels } : {} ),
              ...( key === 'body' ? { onBlur:_onBlur } : {} ),
            }}
            onChange={ _onChange }
          />
      )}
    </form>
  )
}


export default DigestForm