import React from 'react'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import Button from '|/helpers/Button'

import styles from './main.styl'

const ExpandButton = ({ expanded, className, children, ...props })=> {
  const classList = [ styles.container, className ]
  if(expanded) classList.push(styles.expanded)
  return (
    <Button
      { ...props }
      className={ classList.join(' ') }
      material={ false }
      round={ true }
    >
      <Fa icon="chevron-down" />
    </Button>
  )
}

ExpandButton.propTypes = {
  expanded: PropTypes.bool
}

ExpandButton.defaultProps = {
  expanded: false
}

export default ExpandButton