import { createSelector } from 'reselect'

const usersSelector = state => state.users.list
const roleSelector = (state,role)=> role

const getUserIdsByRole = createSelector(
  [usersSelector,roleSelector],
  (users,role)=> {
    return Object.values(users).reduce((arr,u)=> {
      if(u.role === role)
        arr.push(u.id)
      return arr
    },[])
  }
)

export default getUserIdsByRole

export const createGetUserIdsByRole = ()=> getUserIdsByRole