import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getTagsByParent } from '|/store/tags/selectors'
import { AccordionProvider } from '|/contexts/Accordion'

import TagsList from './TagsList'

import styles from './main.styl'

import {
  FETCH_TAGS
} from '|/store/constants'

const Tags = ({ ...props })=> {
  
  const dispatch = useDispatch()
  const tags = useSelector( state => getTagsByParent(state,null) )

  useEffect(()=> {
    dispatch({
      type: FETCH_TAGS
    })
  },[])
  
  return (
    <AccordionProvider>
      <div className={ `${styles.tags}` }>
        <TagsList tags={ tags } />
      </div>
    </AccordionProvider>
  )
}

export default Tags