import { createSelector } from 'reselect'

const contextsSelector = state => state.contexts.list
const idSelector = (state,id)=> id

const getContext = createSelector(
  [contextsSelector,idSelector],
  (contexts,id)=> {
    return contexts[id]
  }
)

export default getContext

export const createGetContext = ()=> getContext