import { createSelector } from 'reselect'

const exportsSelector = state => state.exports.list

const getExports = (exports)=> {
  return [ ...Object.values(exports) ]
}

export default createSelector(
  [exportsSelector],
  getExports
)