import unionBy from 'lodash/unionBy'
import union from 'lodash/union'
import React, { useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPost, getSavedPost } from '|/store/posts/selectors'
import { getContext, getTaggableContexts } from '|/store/contexts/selectors'

import { history } from '|/store'
// import { getSearchStringAsObject } from '|/store/router/selectors'
import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { PostContext } from '|/contexts/Post'

import {
  ADD_ATTACHMENT,
  CREATE_COMMENT,
  UPDATE_POST,
  DELETE_POST,
} from '|/store/constants'

import Button from '|/helpers/Button'
import ConfirmButton from '|/helpers/ConfirmButton'
import Input from '|/helpers/Input'

import styles from './main.styl'

const CommentForm = ({ comment }) => {

  const dispatch = useDispatch()
  // const query = useSelector( state => getSearchStringAsObject(state) )
  const saved = useSelector( state => getSavedPost(state) )
  const [ saving, setSaving ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ attachmentUploadState, setAttachmentUploadState ] = useState(undefined)
  const { setCurrentComment, setDidSave } = useContext(PostContext)
  const [ form_is_valid, setForm_is_valid ] = useState(false)
  const [ controls, setControls ] = useState({
    parent_post_id: {
      type: 'Input',
      config: {
        type: 'hidden',
      },
      value: 0,
    },
    attachments: {
      type: 'Attachments',
      label: 'Images',
      config: {
        multiple: true
      },
      value: [],
      touched: false,
    },
    body: {
      type: 'Textarea',
      label: 'Text',
      config: {
        placeholder: 'Write something...',
        rows: 4,
      },
      value: '',
      touched: false,
    },
  })

  const _onChange = (key,value)=> {
    _updateFormValue(key,value)
  }

  const _onAttachmentsChange = (key,value)=> {
    if(value.length < controls.attachments.value.length) {
      _updateFormValue(key,value)
    } else {
      value.map( attachment =>
        attachment instanceof File &&
          dispatch({
            type: ADD_ATTACHMENT,
            attachment,
            state: attachmentUploadState,
            setState: setAttachmentUploadState,
          })
      )
    }
  }

  const _updateFormValue = (key,value)=> {
    const new_controls = {
      ...controls,
      [key]: {
        ...controls[key],
        value,
        touched: true,
        valid: controls[key].validation ? validate(value,controls[key].validation) : true,
      },
    }
    const _valid = Object.values(new_controls).reduce((bool,ctrl)=> (ctrl.valid === undefined || ctrl.valid) && bool ? true : false, true )
    setForm_is_valid(_valid)
    setControls(new_controls)
  }
  
  const _onCancelClick = (e)=> {
    cancel()
  }

  const _onSubmitClick = (e)=> {
    _submitForm()
  }

  const _onDeleteClick = ()=> {
    dispatch({
      type: DELETE_POST,
      post_id: comment.id,
      callback: cancel
    })
  }

  const cancel = ()=> {
    setCurrentComment(undefined)
  }

  const createComment = ()=> {
    const formData = Object.keys(controls).reduce((obj,key)=> {
      obj[key] = controls[key].value
      return obj
    },{})
    dispatch({
      type: CREATE_COMMENT,
      post: formData,
      post_id: comment.parent_post_id
    })
  }

  const updateComment = ()=> {
    const formData = Object.keys(controls).reduce((obj,key)=> {
      obj[key] = controls[key].value
      return obj
    },{})
    dispatch({
      type: UPDATE_POST,
      id: comment.id,
      post: formData,
    })
  }

  const _onFormSubmit = (e)=> {
    e.preventDefault()
    _submitForm()
  }

  const _submitForm = ()=> {
    setSaving(true)
    if(comment.id)
      updateComment()
    else
      createComment()
  }

  useEffect(()=> {
    setDidSave(false)
    return ()=>
      setCurrentComment(undefined)
  },[])

  useEffect(()=> {
    if(saving && saved) {
      setDidSave(true)
      setCurrentComment(undefined)
    }
  },[ saved ])

  useEffect(()=> {
    if(comment) {
      const new_controls = Object.keys(controls).reduce((obj,key)=> {
        obj[key] = {
          ...controls[key],
          value: comment[key] ? comment[key] : controls[key].value,
        }
        return obj
      },{})
      setControls(new_controls)
    }
  },[ comment ])

  useEffect(()=> {
    if(attachmentUploadState) {
      const new_attachments = Object.values(attachmentUploadState).reduce((arr,a)=> ( arr.push(a), arr ),[])
      const attachments = unionBy(new_attachments,controls.attachments.value,'filename')
      const attachment_loading = attachments.find((a)=> a.progress && a.progress !== 1) !== undefined
      setLoading(attachment_loading)
      _updateFormValue('attachments',attachments)
      setAttachmentUploadState(undefined)
    }
  },[ attachmentUploadState ])

  const Footer = ()=>
    <div className={ styles.footer }>
      <Button
        type="submit"
        disabled={ loading || saving || !form_is_valid }
        onClick={ _onSubmitClick }
      >
        { saving ? 'Saving...' : 'Save' }
      </Button>
      <Button
        onClick={ _onCancelClick }
      >
        { 'Cancel' }
      </Button>
      { comment.id &&
        <ConfirmButton
          kind={ 'destructive' }
          onClick={ _onDeleteClick }
        >
          Delete
        </ConfirmButton>
      }
    </div>

  return (
    <form
      className={ styles.form }
      onSubmit={ _onFormSubmit }
    >
      { Object.keys(controls).map((key)=> <Input
            key={ key }
            name={ key }
            {...{
              ...controls[key],
            }}
            onChange={ key === 'attachments' ? _onAttachmentsChange : _onChange }
          /> ) }
      <Footer />
    </form>
  )
}


export default CommentForm