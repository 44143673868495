import { createSelector } from 'reselect'

const tagsSelector = state => state.tags.list

const getTagsByGroup = (tags)=> {
  return Object.values(tags).reduce((obj,tag)=> {
    return {
      ...obj,
      [tag.group]: (obj[tag.group] ? [
        ...obj[tag.group],
        tag
      ] : [tag])
    }
  },{})
}

export default createSelector(
  [tagsSelector],
  getTagsByGroup
)