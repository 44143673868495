import merge from 'lodash/merge'

import {
  CREATING_EXPORT,
  CREATED_EXPORT,
  CREATING_EXPORT_FAILED,
  FETCHING_EXPORT,
  FETCHED_EXPORT,
  FETCHING_EXPORT_FAILED,
  CLEAR_EXPORT,
} from '../constants'

const INITIAL_STATE = {
  list: {},
  loading: false,
  saving: false,
}

export default function(state=INITIAL_STATE,action) {
  switch(action.type) {
    case CREATING_EXPORT:
      return {
        ...state,
        saving: true,
      }
    case CREATED_EXPORT:
      const new_export = action.data
      return {
        ...state,
        saving: false,
        list: {
          ...state.list,
          [new_export.id]: {
            ...new_export,
          }
        },
      }
    case CREATING_EXPORT_FAILED:
      return {
        ...state,
        saving: false,
      }
    case FETCHING_EXPORT:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_EXPORT:
      const fetched_export = action.data
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          [fetched_export.id]: {
            ...state.list[fetched_export.id],
            ...fetched_export,
          }
        },
      }
    case FETCHING_EXPORT_FAILED:
      return {
        ...state,
        loading: false,
      }
    case CLEAR_EXPORT:
      const clear_id = action.data
      const { [clear_id]:_, ...new_list } = state.list
      return {
        ...state,
        list: new_list,
      }
  }
  return state
}