import { createSelector } from 'reselect'

const usersSelector = state => state.users.list
const idSelector = (state,id)=> id

const getUser = createSelector(
  [usersSelector,idSelector],
  (users,id)=> {
    return users[id]
  }
)

export default getUser

export const createGetUser = ()=> getUser