import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'
import { obj2search } from '|/utils'

import {
  CREATE_EXPORT,
  CREATING_EXPORT,
  CREATED_EXPORT,
  CREATING_EXPORT_FAILED,
  FETCH_EXPORT,
  FETCHING_EXPORT,
  FETCHED_EXPORT,
  FETCHING_EXPORT_FAILED,
} from '|/store/constants'

function* createExport({ query, extension }) {
  yield put({
    type: CREATING_EXPORT
  })
  let token = yield call(storage.getItem,'token')
  const search = obj2search(query)
  const { id, error } = yield call(api.createExport, search, extension, token)
  console.log(id)
  if(error) {
    yield put({
      type: CREATING_EXPORT_FAILED,
      error: error
    })
  } else {
    yield put({
      type: CREATED_EXPORT,
      data: {
        id,
        extension,
      },
    })
  }
}

function* fetchExport({ id, extension }) {
  yield put({
    type: FETCHING_EXPORT
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchExport, id, extension, token)
  if(response.error) {
    yield put({
      type: FETCHING_EXPORT_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: FETCHED_EXPORT,
      data: response
    })
  }
}

export default function* exports(App) {
  yield all([
    takeEvery(CREATE_EXPORT,createExport),
    takeEvery(FETCH_EXPORT,fetchExport),
  ])
}