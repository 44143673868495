import { createSelector } from 'reselect'

const postsSelector = state => state.posts.list
const searchSelector = (state)=> state.posts.search_result_ids

const getSearchResults = (posts,search_result_ids)=> {
  return search_result_ids
    .map( id => posts[id] )
}

export default createSelector(
  [postsSelector,searchSelector],
  getSearchResults
)