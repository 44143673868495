import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { getExport } from '|/store/exports/selectors'

import { Fa } from '|/icons'
import Button from '|/helpers/Button'

import {
  CLEAR_EXPORT
} from '|/store/constants'

import styles from './main.styl';

const Download = ({ id })=> {

  const dispatch = useDispatch()
  const exprt = useSelector( state => getExport(state,id) )

  const _onClearClick = (e)=> {
    dispatch({
      type: CLEAR_EXPORT,
      data: id,
    })
  }

  return (
    <>
      <a
        className={ styles.download }
        href={ exprt.pdf_file || exprt.zip_file }
        download={ exprt.pdf_file || exprt.zip_file }
      >
        <Button
          kind="default"
        >{ `Download ${exprt.pdf_file ? 'PDF' : 'ZIP'}` }</Button>
      </a>
      <Button
        className={ styles.clear }
        onClick={ _onClearClick }
        round={ true }
        material={ false }
        link={ true }
      >
        <Fa icon="times"/>
      </Button>
    </>
  );
}

export default Download