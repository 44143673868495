import React, { useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from '|/hooks/router'
import { useQuery } from '|/hooks/pathState'

import { getContext } from '|/store/contexts/selectors'
import { getCurrentUser } from '|/store/users/selectors'

import { Fa } from '|/icons'
import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'

import {
  CREATE_EXPORT,
} from '|/store/constants'

import styles from './main.styl'

const Burger = props => {

  const dispatch = useDispatch()
  const params = useParams()
  const query = useQuery()
  const [ open, setOpen ] = useState(false)
  const { authenticated } = useSelector( state => state.auth )
  const currentUser = useSelector( state => getCurrentUser(state) )
  const context = useSelector( state => getContext(state,params.context_id) )
  const mainContext = useSelector( state => getContext(state,query.main_context_id) )
  
  const _onToggleClick = (e)=> {
    setOpen(!open)
  }

  const _export = (extension)=> {
    dispatch({
      type: CREATE_EXPORT,
      extension,
      query,
    })
  }

  return (
    <div
      className={[ styles.container, ( open ? styles.open : undefined ) ].join(' ')}
    >
      <div className={ styles.toggle }>
        <Button
          material={ false }
          link={ true }
          onClick={ _onToggleClick }
          data-cy="burgerButton"
        >
          <Fa
            icon={ open ? 'times' : 'bars' }
          />
        </Button>
      </div>
      <div className={ styles.menu }>
        { !authenticated &&
          <Plink className={ styles.nav } to="/login">
            <Button
              data-cy="loginButton"
              material={ false }
              link={ true }
            >Log in</Button>
          </Plink>
        }
        { context && mainContext &&
          <Fragment>
            {(
                context.is_member ||
                mainContext.is_member
            ) &&
              <div>
                <Button
                  material={ false }
                  link={ true }
                  onClick={ e => _export('pdf') }
                >Print</Button>
              </div>
            }
            { mainContext.is_admin &&
              <div>
                <Button
                  material={ false }
                  link={ true }
                  onClick={ e => _export('zip') }
                >Download</Button>
              </div>
            }
          </Fragment>
        }
        { authenticated &&
          <Fragment>
            <Plink
              className={ styles.nav }
              to={{
                overlay: 'account'
              }}
            >
              <Button
                material={ false }
                link={ true }
              >Account</Button>
            </Plink>
            <Plink className={ styles.nav } to="/logout">
              <Button
                material={ false }
                link={ true }
              >Log out</Button>
            </Plink>
          </Fragment>
        }
        { (currentUser && currentUser.is_staff) &&
          <Plink
            className={ styles.nav }
            to="/users"
          >
            <Button
              material={ false }
              link={ true }
            >Teachers</Button>
          </Plink>
        }
      </div>
    </div>
  )
}

export default Burger