import unionBy from 'lodash/unionBy'
import union from 'lodash/union'
import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPost, getSavedPost } from '|/store/posts/selectors'
import { getContext, getTaggableContexts } from '|/store/contexts/selectors'

import { history } from '|/store'
// import { getSearchStringAsObject } from '|/store/router/selectors'
import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { PostContext } from '|/contexts/Post'

import {
  ADD_ATTACHMENT,
  FETCH_POST,
  CREATE_POST,
  UPDATE_POST,
  DELETE_POST,
  CLEAR_POSTS_ERRORS,
} from '|/store/constants'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import ConfirmButton from '|/helpers/ConfirmButton'
import Input from '|/helpers/Input'

import styles from './main.styl'

const CONTROLS = {
  attachments: {
    type: 'Attachments',
    label: 'Images',
    config: {
      multiple: true
    },
    value: [],
    touched: false,
  },
  body: {
    type: 'Textarea',
    label: 'Text',
    config: {
      placeholder: 'Write something...',
      rows: 12,
    },
    value: '',
    touched: false,
  },
  relevance: {
    type: 'Input',
    label: 'Relevance',
    config: {
      type: 'range'
    },
    value: 100,
    touched: false,
  },
  context_ids: {
    type: 'MultiSelect',
    label: 'Contexts',
    config: {
      
    },
    value: [],
    touched: false,
  },
  tag_ids: {
    type: 'MultiSelect',
    label: 'Tags',
    config: {
      
    },
    value: [],
    touched: false,
  },
}

const PostForm = props => {

  const params = useParams()
  const pathState = usePathState()
  const dispatch = useDispatch()
  // const query = useSelector( state => getSearchStringAsObject(state) )
  const post = useSelector( state => getPost(state,params.post_id) )
  const saved = useSelector( state => getSavedPost(state) )
  const { error } = useSelector( state => state.posts )
  const context = useSelector( state => getContext(state,params.context_id) )
  const mainContext = useSelector( state => getContext(state,context && context.parent_context_id ? context.parent_context_id : params.context_id) )
  const taggableContexts = useSelector( state => getTaggableContexts(state,mainContext && mainContext.id) )
  const [ saving, setSaving ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ attachmentUploadState, setAttachmentUploadState ] = useState(undefined)
  const { setEditing, setDidSave } = useContext(PostContext)
  const [ form_is_valid, setForm_is_valid ] = useState(false)
  const [ _errors, _setErrors ] = useState([])
  const [ _fieldErrors, _setFieldErrors ] = useState()
  const [ controls, setControls ] = useState(CONTROLS)

  const _onChange = (key,value)=> {
    _updateFormValue(key,value)
  }

  const _onAttachmentsChange = (key,value)=> {
    if(value.length < controls.attachments.value.length) {
      _updateFormValue(key,value)
    } else {
      value.map( attachment =>
        attachment instanceof File &&
          dispatch({
            type: ADD_ATTACHMENT,
            attachment,
            state: attachmentUploadState,
            setState: setAttachmentUploadState,
          })
      )
    }
  }

  const _updateFormValue = (key,value)=> {
    const new_controls = Object.assign({},
      controls,
      {
        [key]: {
          ...controls[key],
          value,
          touched: true,
          valid: controls[key].validation ? validate(value,controls[key].validation) : true,
        },
      }
    )
    const _valid = Object.values(new_controls).reduce((bool,ctrl)=> (ctrl.valid === undefined || ctrl.valid) && bool ? true : false, true )
    setForm_is_valid(_valid)
    setControls(new_controls)
  }

  const _updateFormValues = (new_values)=> {
    const new_controls = Object.assign({},
      controls,
      Object.keys(new_values).reduce((obj,key)=> {
          obj[key] = {
            ...controls[key],
            value: new_values[key],
            touched: true,
            valid: controls[key].validation ? validate(new_values[key],controls[key].validation) : true,
          }
          return obj
        },{})
      )
    console.log(new_controls)
    const _valid = Object.values(new_controls).reduce((bool,ctrl)=> (ctrl.valid === undefined || ctrl.valid) && bool ? true : false, true )
    setForm_is_valid(_valid)
    setControls(new_controls)
  }
  
  const _onCancelClick = (e)=> {
    cancel()
  }

  const _onSubmitClick = (e)=> {
    _submitForm()
  }

  const _onDeleteClick = ()=> {
    dispatch({
      type: DELETE_POST,
      post_id: post.id,
      callback: cancel
    })
  }

  const cancel = ()=> {
    if(!post || !post.id) {
      const to = {
        overlay: undefined
      }
      const redirectTo = pathState.toString(pathState.merge(to))
      history.replace(redirectTo)
    }
    setEditing(false)
  }

  const createPost = ()=> {
    const formData = Object.keys(controls).reduce((obj,key)=> {
      obj[key] = controls[key].value
      return obj
    },{})
    dispatch({
      type: CREATE_POST,
      context_id: context.id,
      post: formData,
    })
  }

  const updatePost = ()=> {
    const formData = Object.keys(controls).reduce((obj,key)=> {
      obj[key] = controls[key].value
      return obj
    },{})
    dispatch({
      type: UPDATE_POST,
      id: post.id,
      post: formData,
    })
  }

  const _onFormSubmit = (e)=> {
    e.preventDefault()
    _submitForm()
  }

  const _submitForm = ()=> {
    setSaving(true)
    if(post)
      updatePost()
    else
      createPost()
  }

  useEffect(()=> {
    if(error) {
      if(error['non_field_errors'])
        if(!Array.isArray(error['non_field_errors']))
          _setErrors([error['non_field_errors']])
        else
          _setErrors(error['non_field_errors'])
      else
        _setFieldErrors(error)
      setSaving(false)
    } else {
      _setErrors([])
      _setFieldErrors(null)
    }
  },[ error ])

  useEffect(()=> {
    setEditing(true)
    setDidSave(false)
    dispatch({
      type: CLEAR_POSTS_ERRORS,
    })
    return ()=>
      setEditing(false)
  },[])

  useEffect(()=> {
    if(saving && saved) {
      setEditing(false)
      setDidSave(true)
      const to = {
        overlay: {
          p: saved.id+''
        }
      }
      const redirectTo = pathState.toString(pathState.merge(to))
      history.push(redirectTo)
    }
  },[ saved ])

  useEffect(()=> {
    const { post_id } = params
    if(post_id !== 'NEW') {
      dispatch({
        type: FETCH_POST,
        post_id
      })
    }
  },[ params.post_id ])

  useEffect(()=> {
    if(post) {
      const new_controls = Object.keys(controls).reduce((obj,key)=> {
        obj[key] = {
          ...controls[key],
          value: post[key] ? post[key] : controls[key].value,
        }
        return obj
      },{})
      setControls(new_controls)
    }
  },[ post ])

  useEffect(()=> {
    if(context) {
      if(params.post_id === 'NEW') {
        const default_tag_ids = context.default_tags.reduce((arr,t)=>(arr.push(t.id),arr),[])
        let default_context_ids = [ context.id ]
        if(context.has_parent_context) default_context_ids.push(context.parent_context_id)
        _updateFormValues({
          tag_ids: default_tag_ids,
          context_ids: default_context_ids,
        })
      }
    }
  },[ context ])

  useEffect(()=> {
    if(attachmentUploadState) {
      const new_attachments = Object.values(attachmentUploadState).reduce((arr,a)=> ( arr.push(a), arr ),[])
      const attachments = unionBy(new_attachments,controls.attachments.value,'filename')
      const attachment_loading = attachments.find((a)=> a.progress && a.progress !== 1) !== undefined
      setLoading(attachment_loading)
      _updateFormValue('attachments',attachments)
      setAttachmentUploadState(undefined)
    }
  },[ attachmentUploadState ])

  const Footer = ()=>
    <div className={ styles.footer }>
      <Button
        type="submit"
        disabled={ loading || saving || !form_is_valid }
        onClick={ _onSubmitClick }
      >
        { saving ? 'Saving...' : 'Save' }
      </Button>
      <Button
        onClick={ _onCancelClick }
      >
        { 'Cancel' }
      </Button>
      { post &&
        <ConfirmButton
          kind={ 'destructive' }
          onClick={ _onDeleteClick }
        >
          Delete
        </ConfirmButton>
      }
    </div>

  return (
    <Overlay
      className={ styles.container }
      title={ `${post ? 'Editing' : 'Creating'} post` }
      footer={ Footer }
    >
      <div
        className={ styles.content }
      >
        { _errors.map( e =>
          <div
            key={ `${e}` }
            className={ styles.error }
          >{ e }</div>
        )}
        <form onSubmit={ _onFormSubmit }>
          { Object.keys(controls).map((key)=>
            <Fragment
              key={ key }
            >
              { (_errors && _errors[key]) &&
                <div className={ styles.error }>{ _errors[key] }</div>
              }
              <Input
                name={ key }
                {...{
                  ...controls[key],
                  ...( mainContext && key === 'tag_ids' ? {
                      options: mainContext ? mainContext.tags : [],
                    } : {} ),
                  ...( mainContext && key === 'context_ids' ? {
                      options: taggableContexts,
                    } : {} ),
                }}
                onChange={ key === 'attachments' ? _onAttachmentsChange : _onChange }
              />
            </Fragment>
          )}
        </form>
      </div>  
    </Overlay>
  )
}


export default PostForm