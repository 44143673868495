import { createSelector } from 'reselect'
import { search2obj } from '|utils'

const search = state => state.router.location.search

const getParamsAsObject = (search)=> {
  return search2obj(search)
}

export default createSelector(
  [search],
  getParamsAsObject
)