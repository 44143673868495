import React from 'react'
import PropTypes from 'prop-types'

import styles from './main.styl'

const Clickable = ({ children, ...props })=> {
  
  return (
    <div
      { ...props }
      className={ [ styles.clickable, props.className ].join(' ') }
    >{ children }</div>
  )
}

Clickable.propTypes = {
  
}

Clickable.defaultProps = {
  
}

export default Clickable