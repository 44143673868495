import { createSelector } from 'reselect'

const contextsSelector = state => state.contexts.list
const idsSelector = (state,ids)=> ids

const getContextsByIds = (contexts,ids)=> {
  return Object.values(contexts).filter( c => ids.indexOf(c.id) !== -1)
}

export default createSelector(
  [contextsSelector,idsSelector],
  getContextsByIds
)