import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'

import {
	AUTHENTICATE,
	AUTHENTICATING,
	AUTHENTICATED,
	SET_REDIRECT,
	CLEAR_REDIRECT,
	IDENTIFY,
	IDENTIFYING,
	IDENTIFIED,
	LOG_IN,
	LOGGING_IN,
	LOGGED_IN,
	LOG_OUT,
	LOGGED_OUT,
	GO_OFFLINE,
	CODE_SIGNIN,
	CODE,
	FETCHED_USER,
	FETCH_CURRENT_USER,
	FETCHING_CURRENT_USER,
	FETCHED_CURRENT_USER,
	FETCHING_CURRENT_USER_FAILED,
	UPDATED_USER,
	UPDATE_CURRENT_USER,
	UPDATING_CURRENT_USER,
	UPDATED_CURRENT_USER,
	UPDATING_CURRENT_USER_FAILED,
	REFRESH_TOKEN,
	REFRESHING_TOKEN,
	REFRESH_TOKEN_SENT,
	REFRESH_TOKEN_FAILED,
	RESET_PASSWORD,
	RESETTING_PASSWORD,
	RESET_PASSWORD_SENT,
	RESET_PASSWORD_FAILED,
	SET_PASSWORD,
	SETTING_PASSWORD,
	SET_PASSWORD_SENT,
	SET_PASSWORD_FAILED,
} from '|/store/constants'

function* authenticate() {
	yield put({
		type: AUTHENTICATING
	})
	const token = yield call(storage.getItem,'token')
	if(token) {
		const { error, detail, is_offline, ...user } = yield call(api.getCurrentUser,token)
		console.log('ERROR',detail)
		if(detail) {
			yield call(refreshToken, token)
		} else if(is_offline) {
			yield put({
				type: GO_OFFLINE,
				error
			})
		} else if(error) {
			yield put({
				type: LOG_OUT,
				error: error,
			})
		} else if(user) {
			yield put({
				type: AUTHENTICATED,
				user_id: user.id,
			})
			yield put({
				type: FETCHED_USER,
				data: user,
			})
		}
	} else {
		yield put({
			type: LOG_OUT,
			error: 'No token'
		})
	}
}

function* refreshToken(old_token) {
	yield put({
		type: REFRESHING_TOKEN
	})
	let expiresOn = yield call(storage.getItem,'expiresOn')
	// console.log('old_token:',old_token)
	if(old_token) {
		let { token, expiresIn, non_field_errors, is_offline } = yield call(api.refreshToken,old_token)
		console.log('BLUB',non_field_errors)
		if(token) {
			yield call(storage.setItem,'token',token)
			if(expiresIn) {
				expiresOn = new Date(new Date().getTime() + expiresIn * 1000)
				yield call(storage.setItem,'expiresOn',expiresOn)
			}
			if(expiresOn && expiresOn <= new Date()) {
				yield put({
					type: LOG_OUT,
					error: 'Token expired'
				})
			} else {
				yield call(authenticate)
			}
		} else if(is_offline) {
			yield put({
				type: GO_OFFLINE,
				error
			})
		} else if(non_field_errors) {
			yield call(storage.removeItem,'token')
			yield put({
				type: LOG_OUT,
				error: non_field_errors,
			})
		}
	} else {
		yield put({
			type: LOG_OUT,
			error: 'No token'
		})
	}
}

function* codeSignIn({ id, code, reset_password }) {
	yield put({
		type: AUTHENTICATING
	})
	yield put({
		type: LOG_OUT,
		error: 'Clear store'
	})
	const { token, non_field_errors, error } = yield call(api.codeSignIn,id,code,reset_password)
	if(token) {
		yield call(storage.setItem,'token',token)
		const user = yield call(api.getCurrentUser,token)
		yield put({
			type: AUTHENTICATED,
			user_id: user.id,
		})
		yield put({
			type: FETCHED_USER,
			data: user,
		})
	} else if(non_field_errors) {
		yield put({
			type: LOG_OUT,
			error: non_field_errors.join('; ')
		})
	} else {
		yield put({
			type: LOG_OUT,
			error: 'Something went wrong. Please contact administrator.'
		})
	}
}

function* login({ email, password }) {
	yield put({
		type: LOGGING_IN
	})
	const { token, non_field_errors, error } = yield call(api.login,email,password)
	if(token) {
		yield call(storage.setItem,'token',token)
		yield put({
			type: AUTHENTICATE,
		})
	} else if(non_field_errors) {
		yield put({
			type: LOG_OUT,
			error: 'Invalid credentials'
		})
	} else {
		yield put({
			type: LOG_OUT,
			error: 'Invalid credentials'
		})
	}
}

function* resetPassword({ email }) {
	yield put({
		type: RESETTING_PASSWORD,
	})
	const { non_field_errors, error } = yield call(api.resetPassword,email)
	if(error) {
		yield put({
			type: RESET_PASSWORD_FAILED,
			error,
		})
	} else if(non_field_errors) {
		yield put({
			type: RESET_PASSWORD_FAILED,
			error: 'Something went wrong, please try again later',
		})
	} else {
		yield put({
			type: RESET_PASSWORD_SENT,
			info: 'Password reset email sent successfully'
		})
	}
}

function* setPassword({ email, callback }) {
	yield put({
		type: SETTING_PASSWORD,
	})
	const { non_field_errors, error } = yield call(api.resetPassword,email)
	if(error) {
		yield put({
			type: SET_PASSWORD_FAILED,
			error,
		})
	} else if(non_field_errors) {
		yield put({
			type: SET_PASSWORD_FAILED,
			error: 'Something went wrong, please try again later',
		})
	} else {
		yield put({
			type: SET_PASSWORD_SENT,
			info: 'New password saved successfully'
		})
	}
}

function* logout({ error }) {
	yield call(storage.removeItem,'token')
	yield call(storage.removeItem,'expiresOn')
	yield put({
		type: LOGGED_OUT,
		error
	})
}

export default function* auth(App) {
	yield all([
		takeEvery(AUTHENTICATE,authenticate),
		takeEvery(RESET_PASSWORD,resetPassword),
		takeEvery(SET_PASSWORD,setPassword),
		takeEvery(CODE_SIGNIN,codeSignIn),
		takeEvery(LOG_IN,login),
		takeEvery(LOG_OUT,logout),
	])
}