import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'

import {
  FETCH_DIGESTS,
  FETCHING_DIGESTS,
  FETCHED_DIGESTS,
  FETCHING_DIGESTS_FAILED,
  CREATE_DIGEST,
  CREATING_DIGEST,
  CREATED_DIGEST,
  CREATING_DIGEST_FAILED,
  UPDATE_DIGEST,
  UPDATING_DIGEST,
  UPDATED_DIGEST,
  UPDATING_DIGEST_FAILED,
  DELETE_DIGEST,
  DELETING_DIGEST,
  DELETED_DIGEST,
  DELETING_DIGEST_FAILED,
  FETCH_CONTEXT,
} from '|/store/constants'

function* fetchDigests({ id }) {
  console.log('fetching digests')
  yield put({
    type: FETCHING_DIGESTS
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchDigests, id, token)
  if(response.error) {
    yield put({
      type: FETCHING_DIGESTS_FAILED,
      error: error
    })
  } else {
    yield put({
      type: FETCHED_DIGESTS,
      data: response
    })
  }
}

function* createDigest({ context_id, digest_id, digest }) {
  yield put({
    type: CREATING_DIGEST
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.createDigest, context_id, digest_id, digest, token)
  if(response.error) {
    yield put({
      type: CREATING_DIGEST_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: CREATED_DIGEST,
      data: response
    })
    yield put({
      type: FETCH_CONTEXT,
      context_id,
    })
  }
}

function* updateDigest({ id, digest }) {
  yield put({
    type: UPDATING_DIGEST
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.updateDigest, id, digest, token)
  if(response.error) {
    yield put({
      type: UPDATING_DIGEST_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: UPDATED_DIGEST,
      data: response
    })
    yield put({
      type: FETCH_CONTEXT,
      context_id: digest.context_id,
    })
  }
}

function* deleteDigest({ digest_id, callback }) {
  yield put({
    type: DELETING_DIGEST
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.deleteDigest, digest_id, token)
  if(response.error) {
    yield put({
      type: DELETING_DIGEST_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: DELETED_DIGEST,
      data: digest_id
    })
    if(callback)
      callback(digest_id)
  }
}

export default function* digests(App) {
  yield all([
    takeEvery(FETCH_DIGESTS,fetchDigests),
    takeEvery(CREATE_DIGEST,createDigest),
    takeEvery(UPDATE_DIGEST,updateDigest),
    takeEvery(DELETE_DIGEST,deleteDigest),
  ])
}