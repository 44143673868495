import React, { useState, Suspense } from 'react'
import PropTypes from 'prop-types'

import DigestListItem from './DigestListItem'

import { AccordionProvider } from '|/contexts/Accordion'

import styles from './main.styl'

const DigestsList = ({ digests, context, mainContext })=> {

  // const [ expanded, setExpanded ] = useState(false)

  // const handleChange = key => (e,isExpanded)=> {
  //   setExpanded(isExpanded ? key : false)
  // }

  if(!digests) return null
  return (
    <Suspense fallback="Loading...">
      <AccordionProvider>
        <div className={ styles.digests }>
          { digests.map((digest)=> {
            const panel_id = `panel_${digest.id}`
            return <DigestListItem
                key={ panel_id }
                // expanded={ expanded === panel_id }
                // onChange={ handleChange(panel_id) }
                digest={ digest }
                context={ context }
                mainContext={ mainContext }
              />
          })}
        </div>
      </AccordionProvider>
    </Suspense>
  )
}

DigestsList.propTypes = {
  digests: PropTypes.array
}

export default DigestsList