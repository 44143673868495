import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { history } from '|/store'
import { useParams } from '|/hooks/router'
import { useQuery, usePathState } from '|/hooks/pathState'

import { init } from '|/routes'

import {
  UPDATE_CURRENT_USER,
  CLEAR_REDIRECT,
} from '|/store/constants'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import Input from '|/helpers/Input'

import styles from './main.styl'

const ActivateForm = props => {

  const dispatch = useDispatch()
  const pathState = usePathState()
  const { verification_code } = useParams()
  const { authenticated, authenticating, info, redirectTo } = useSelector( state => state.auth )
  const { saving, error } = useSelector( state => state.users )
  const [ loading, setLoading ] = useState(false)
  const [ controls, setControls ] = useState({
    firstname: {
      type: 'Input',
      label: 'First name',
      config: {
        placeholder: 'Your first name',
      },
      value: '',
      touched: false,
      onChange: _onChange,
    },
    lastname: {
      type: 'Input',
      label: 'Last name',
      config: {
        placeholder: 'Your last name',
      },
      value: '',
      touched: false,
      onChange: _onChange,
    },
    verification_code: {
      type: 'Hidden',
      config: {
        
      },
      value: verification_code,
    },
    password: {
      type: 'Input',
      label: 'Password',
      config: {
        type: 'password',
        placeholder: 'Choose a password',
        autoComplete: "new-password",
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      onChange: _onChange,
    },
  })

  const _onChange = (key, value)=> {
    const new_controls = {
      ...controls,
    }
    new_controls[key] = {
      ...controls[key],
      value,
      // valid: checkValidity(e.target.value, controls[controlName].validation),
      touched: true,
    }
    setControls(new_controls)
  }

  const _onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = {}
    for(let key in controls) {
      formData[key] = controls[key].value
    }
    dispatch({
      type: UPDATE_CURRENT_USER,
      user: formData
    })
  }

  const _resetPassword = (e) => {
    setLoading(true)
    const email = controls.email.value
    dispatch({
      type: RESET_PASSWORD,
      email,
    })
  }

  const formElements = []
  for(let key in controls) {
    formElements.push({
      key,
      control: controls[key],
    })
  }

  useEffect(()=> {
    console.log('SAVING',saving)
    console.log('LOADING',loading)
    if(loading && !saving && !error) {
      history.push(pathState.toString(init))
      dispatch({
        type: CLEAR_REDIRECT
      })
    }
  },[ saving ])

  return (
    <Overlay
      title={ `User details` }
      disableClose={ true }
    >
      <div className={ styles.content }>
        { info &&
          <div className="info">{ info }</div>
        }
        { (error && (typeof error === 'string')) &&
          <div className="error">{ error }</div>
        }
        <form onSubmit={ _onSubmit }>
          { formElements.map(({ key, control })=> {
            const field_error = error[key]
            return (
              <>
                <Input
                  key={ key }
                  name={ key }
                  { ...control }
                  onChange={ _onChange }
                />
                { field_error &&
                  <div className="error">{ field_error }</div>
                }
              </>
            )
          })}
          <Button
            type="submit"
            data-cy="submitButton"
          >Save and continue</Button>
        </form>
        <div className={ styles.footer }>
        </div>
      </div>
    </Overlay>
  )
}


export default ActivateForm