import React from 'react'
import PropTypes from 'prop-types'

const Hidden = props => {

  const _onChange = (e)=> {
    const value = e.target.value
    props.onChange(props.name,value)
  }

  const _onFocus = (e)=> {
    
  }

  return <input
      type="hidden"
      { ...props.config }
      name={ props.name }
      value={ props.value }
      onChange={ _onChange }
      onFocus={ _onFocus }
    />
}

Hidden.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

Hidden.defaultProps = {
  name: '',
  onChange: ()=> {}
}

export default Hidden