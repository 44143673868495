import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import Markdown from '|/components/Markdown'
import Img from '|/helpers/Img'
import Digests from '|/containers/Context/Digests'

import {
  FETCH_CONTEXTS,
} from '|/store/constants'

import styles from './main.styl'

const Context = ({ context })=> {

  if(!context) return null

  const dispatch = useDispatch()
  const [ swiper, updateSwiper ] = useState(null)

  useEffect(()=> {
    dispatch({
      type: FETCH_CONTEXTS,
      id: context.parent_context_id
    })
  },[])

  return (
    <div className={ `${styles.context}` }>
      { context.attachments && context.attachments.length > 0 && context.attachments[0] !== null &&
        <div className={ styles.carousel }>
          <Fragment>
            <Swiper
              getSwiper={ updateSwiper }
              watchOverflow={ true }
              navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
              }}
            >
              { context.attachments.map((a,i)=> {
                return a !== null && <div key={ `context_${context.id}_attachment_${i}` } className={ styles.slide }>
                  <img src={ a.thumb || a.attachment || a } />
                </div>
              })}
            </Swiper>
          </Fragment>
        </div>
      }
      <div className={ styles.description }>
        <Markdown source={ context.description } />
      </div>
      <Digests context={ context } />
    </div>
  )
}

Context.propTypes = {
  context: PropTypes.object
}

export default Context