import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import AuthReducers from './auth/reducers'
import ContextsReducers from './contexts/reducers'
import DigestReducers from './digests/reducers'
import PostsReducers from './posts/reducers'
import TagsReducers from './tags/reducers'
import ExportsReducers from './exports/reducers'
import UsersReducers from './users/reducers'

export default (history)=> combineReducers({
	router: connectRouter(history),
	auth: AuthReducers,
  contexts: ContextsReducers,
  digests: DigestReducers,
  posts: PostsReducers,
  tags: TagsReducers,
  exports: ExportsReducers,
  users: UsersReducers,
})