import { fork, all } from 'redux-saga/effects'
import Auth from './auth/sagas'
import Contexts from './contexts/sagas'
import Digests from './digests/sagas'
import Posts from './posts/sagas'
import Tags from './tags/sagas'
import Exports from './exports/sagas'
import Users from './users/sagas'
import Attachments from './attachments/sagas'

export default function* sagas() {
	yield all([
    fork(Auth),
    fork(Contexts),
    fork(Digests),
    fork(Posts),
    fork(Tags),
    fork(Exports),
    fork(Users),
    fork(Attachments),
	])
}