import React from 'react'
import qs from 'qs'
import differenceWith from 'lodash/differenceWith'

export const renderChildrenWithProps = (children,{...props})=>
  React.Children.map(children, child => React.cloneElement(child, {...props}))

export const search2obj = (search)=> {
  let obj = qs.parse(search,{
    ignoreQueryPrefix: true,
    allowDots: true,
    comma: true,
  })
  Object.keys(obj).map( k => obj[k] = Array.isArray(obj[k]) ? obj[k].toNumbers() : toNumber(obj[k]) )
  return obj
}

export const obj2search = (query)=> qs.stringify(query,{
  encode: false,
  allowDots: true,
  arrayFormat: 'comma',
})

export const search2array = (search)=> {
  if(!search || search === '') return []
  let string = search.indexOf('?') === 0 ? search.substring(1) : search
  const arr_string = '[{"key":"'+string.replace(/&/g,'"},{"key":"').replace(/=/g,'","val":"')+'"}]'
  const arr = JSON.parse(arr_string)
  arr.map( param => {
    if(Array.isArray(param.val))
      param.val = param.val.map( val => {
        if(!isNaN(val))
          val = parseInt(val)
        return val
      })
    else
      if(!isNaN(param.val))
        param.val = parseInt(param.val)
      return param.val
  })
  return arr
}

export const array2search = (arr,comma=false)=> {
  if(arr === undefined) return ''
  return arr.map((param,i)=> {
    return `${param.key}=${param.val}`
  }).join('&')
}

export const updateObject = (source, changes)=> {
  return {
    ...source,
    ...changes,
  }
}

export const addToSearchObj = (search, toAdd)=> {
  let query = typeof search === 'object' && search !== null ? search : search2obj(search) || {}
  Object.keys(toAdd).map((key)=> {
    query[key] = [
      ...(query[key] ? Array.isArray(query[key]) ? query[key] : [query[key]] : []),
      ...Array.isArray(toAdd[key]) ? toAdd[key] : [toAdd[key]],
    ]
  })
  return query
  // return array2search(query)
}

export const removeFromSearchObj = (search, toRemove)=> {
  let query = typeof search === 'object' && search !== null ? search : search2obj(search) || {}
  Object.keys(toRemove).map((key)=> {
    const toRemove_array = Array.isArray(toRemove[key]) ? toRemove[key] : [toRemove[key]]
    console.log(JSON.stringify(query[key]),JSON.stringify(toRemove_array))
    // const to_remove = differenceWith(query[key],toRemove_array,isEqual)
    const new_queryKey = differenceWith(query[key],toRemove_array,isEqual)
    query[key] = new_queryKey.length > 0 ? new_queryKey : undefined
  })
  return query
  // return array2search(diffed_params)
}

export const toggleInSearchObj = (search, toToggle)=> {
  let query = typeof search === 'object' && search !== null ? search : search2obj(search) || {}
  Object.keys(toToggle).map((key)=> {
    const toToggle_array = Array.isArray(toToggle[key]) ? toToggle[key] : [toToggle[key]]
    if(!query[key]) {
      query[key] = toToggle_array
    } else {
      const query_array = Array.isArray(query[key]) ? query[key] : [query[key]]
      toToggle_array.map((toTo)=> {
        const i = query_array.findIndex( o => o == toTo )
        if(i !== -1)
          query_array.splice(i,1)
        else
          query_array.push(toTo)
      })
      query[key] = query_array.length === 0 ? undefined : query_array
    }
  })
  return query
  // return array2search(query)
}

export const addToSearchArray = (search, toAdd)=> {
  let query = Array.isArray(search) ? search : search2array(search) || []
  toAdd.map(({ key, val })=> {
    query.push({
      key,
      val
    })
  })
  return query
  // return array2search(query)
}

export const removeFromSearchArray = (search, toRemove)=> {
  const query = Array.isArray(search) ? search : search2array(search) || []
  const param_array = toRemove.reduce((arr,p)=> {
    arr.push({
      key: p.key,
      val: p.val
    })
    return arr
  },[])
  const diffed_params = differenceWith(query,param_array,isEqual)
  return diffed_params
  // return array2search(diffed_params)
}

export const toggleInSearchArray = (search, toToggle)=> {
  let query = Array.isArray(search) ? search : search2array(search) || []
  toToggle.map(({ key, val })=> {
    const i = query.findIndex( o => o.key == key && o.val == val )
    if(i !== -1)
      query.splice(i,1)
    else
      query.push({
        key,
        val
      })
  })
  return query
  // return array2search(query)
}

export const replaceInSearchArray = (search, toReplace)=> {
  let query = Array.isArray(search) ? search : search2array(search) || []
  const param_array = toReplace.reduce((arr,p)=> {
    arr.push({
      key: p.key,
      val: p.val
    })
    return arr
  },[])
  const query_for_id = query.filter( p => p.val === id )
  const to_remove = differenceWith(query_for_id,param_array,isEqual)
  const to_add = differenceWith(param_array,query_for_id,isEqual)
  query = [
    ...query,
    ...to_add
  ]
  query = differenceWith(query,to_remove,isEqual)
  return query
  // return array2search(query)
}