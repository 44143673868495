import moment from 'moment'
import React, { Fragment, useEffect, useMemo, useState, useContext } from 'react'
import { useSelector } from 'react-redux'

import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'
import Player from 'react-player'

import { createGetUser } from '|/store/users/selectors/getUser'
import { PostContext } from '|/contexts/Post'

import {
  NA,
  SUCCEEDED,
  FETCH_POST,
} from '|/store/constants'

import Markdown from '|/components/Markdown'

import Overlay from '|/helpers/Overlay'
import Img from '|/helpers/Img'
import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'
import Loader from '|/helpers/Loader'
import ListItem from '|/components/ListItem'
import CommentForm from './CommentForm'

import styles from './main.styl'

const CommentView = ({ comment })=> {

  const [ created_at, setCreated_at ] = useState()
  const [ swiper, updateSwiper ] = useState(null)
  const getUser = useMemo(createGetUser,null)
  const user = useSelector( state => getUser(state, comment.owner))
  const { setCurrentComment, currentComment } = useContext(PostContext)

  useEffect(()=> {
    setCreated_at(moment(comment.created_on).format('YYYY.MM.DD - HH:mm'))
  },[ comment ])

  const openCommentForm = (comment)=> {
    setCurrentComment(comment)
  }

  const renderForm = ()=> {
    return <CommentForm comment={ comment } />
  }

  const renderContent = ()=> {
    return (
      <Fragment>
        <div
          className={ styles.content }
        >
          { comment.media_attachments &&
            <div className={ styles.carousel }>
              <Fragment>
                <Swiper
                  getSwiper={ updateSwiper }
                  watchOverflow={ true }
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                  }}
                  renderPrevButton={ ()=> <Button className="swiper-button-prev" round={ true } icon='chevron-left'/> }
                  renderNextButton={ ()=> <Button className="swiper-button-next" round={ true } icon='chevron-right'/> }
                >
                  { comment.media_attachments.map((med,i)=> {
                    return <div
                        key={ `post_${comment.id}_image_${i}` }
                        className={ styles.slide }
                      >
                        { med.filetype && med.filetype.indexOf('image/') === 0 ?
                            <img
                              src={ med.big_thumb !== undefined ? med.big_thumb : med }
                            />
                          :
                            med.processing_state !== SUCCEEDED ?
                              <div className={ styles.processing }>Processing video...</div>
                            :
                              <Player
                                url={ med.video_url }
                                controls={ true }
                              />
                        }
                      </div>
                  })}
                </Swiper>
              </Fragment>
            </div>
          }
          <div className={ styles.body }>
            <div className={ styles.md }>
              <Markdown source={ comment.body } />
            </div>
            <div className={ styles.tools }>
              <Button
                disabled={ currentComment }
                onClick={ ()=> openCommentForm(comment) }
              >
                Edit
              </Button>
            </div>
          </div>
          { comment.data_attachments &&
            comment.data_attachments.map((attch,i)=> 
              <a
                key={ `post_${comment.id}_attachment_${i}` }
                href={ attch.download_url !== undefined ? attch.download_url : attch }
                download
              >
                <Button
                  material={ false }
                  link={ true }
                  icon={ 'arrow-to-bottom' }
                >
                  { attch.filename !== undefined ? attch.filename : attch }
                </Button>
              </a>
          )}
        </div>
      </Fragment>
    )
  }

  if(!comment)
    return null
  return (
    <div className={ styles.comment }>
      <ListItem className={ styles.listItem }>
        <div className={ styles.header }>
          <div className={ styles.creator }>
            { user &&
                `${ user.usable_name }`
            }
          </div>
          <div className={ styles.created_at }>
            { created_at }
          </div>
        </div>
      </ListItem>
      { (currentComment && currentComment.id === comment.id) ?
          <CommentForm comment={ comment } />
        :
          renderContent()
      }
    </div>
  )
}

export default CommentView