export const init = {
  left: {
    s: 'max',
    c: 0,
  },
  right: {
    s: 'max',
    tg: 'ALL'
  },
  center: {
    s: 'timeline'
  },
}

export const login = '/login'

export const logout = '/logout'

export const contexts = {
  left: {
    s: 'max',
  },
  right: {
    s: 'min',
  }
}

export const tags = {
  left: {
    s: 'min',
  },
  right: {
    s: 'max',
  }
}

export const posts = {
  left: {
    s: 'min',
  },
  right: {
    s: 'min',
  }
}