import React from 'react'
import PropTypes from 'prop-types'

const Input = props => {

  const _onChange = (e)=> {
    const value = e.target.value
    props.onChange(props.name,value)
  }

  const _onFocus = (e)=> {
    
  }

  return (
    <div>
      <input
        { ...props.config }
        name={ props.name }
        value={ props.value }
        onChange={ _onChange }
        onFocus={ _onFocus }
      />
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

Input.defaultProps = {
  name: '',
  onChange: ()=> {}
}

export default Input