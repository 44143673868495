import React, { useState, createContext } from 'react'
import { useStorage } from '|/utils/storage'

export const LeftEditorContext = createContext()

export const LeftEditorProvider = ({ children })=> {

  const [ record, setRecord ] = useStorage('left-editor-record-original')
  const [ nextRecord, setNextRecord ] = useStorage('left-editor-record-next')
  const [ minimised, setMinimised ] = useState(false)

  const leftEditorContext = {
    record,
    setRecord,
    nextRecord,
    setNextRecord,
    minimised,
    setMinimised,
  }

  return <LeftEditorContext.Provider
      value={ leftEditorContext }
    >
      { children }
    </LeftEditorContext.Provider>
}