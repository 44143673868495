import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux'

import * as routes from '|/routes'

import styles from './main.styl';

import Plink from '|/helpers/Plink'
import ErrorBoundary from '|/helpers/ErrorBoundary'

import Panel from '|/components/Panel'
import Auth from '|/containers/Auth'
import AuthRoute from '|/containers/Auth/Route'
import Burger from '|/containers/Burger'
import Exports from '|/containers/Exports'
import Activate from '|/containers/Auth/Activate'
import InitiateUser from '|/containers/Auth/InitiateUser'
import LoginForm from '|/containers/Auth/LoginForm'
import LogoutForm from '|/containers/Auth/LogoutForm'
import ResetPassword from '|/containers/Auth/ResetPassword'
import Teachers from '|/containers/Users/Teachers'
import Account from '|/containers/Auth/Account'
import Context from '../Context';
import Tags from '../Tags';
import Posts from '../Posts';
import Post from '../Post';

import { NetworkProvider } from '|/contexts/Network'
import { LeftEditorProvider } from '|/contexts/LeftEditor'
import { PostProvider } from '|/contexts/Post'
import { DigestProvider } from '|/contexts/Digest'

const App = props => {

  return (
    <NetworkProvider>
      <ErrorBoundary>
        <Auth>
          <div id="App">
            <DigestProvider>
            <main>
              <PostProvider>
                <Switch>
                  <Route exact path="/activate/:id/:code">
                    <Activate/>>
                  </Route>
                  <Route exact path="/newpassword/:verification_code">
                    <ResetPassword/>
                  </Route>
                  <Route exact path="/initiate/:verification_code">
                    <InitiateUser/>
                  </Route>
                  <Route exact path="/login">
                    <LoginForm/>
                  </Route>
                  <Route exact path="/logout">
                    <LogoutForm/>
                  </Route>
                  <AuthRoute exact path="/users">
                    <Teachers/>
                  </AuthRoute>
                  <Route path="/s::left_status;c::context_id/s::right_status;tg::tag_group/s::center_status">
                    <Posts/>
                  </Route>
                </Switch>
                <Switch>
                  <Route exact path="/s::left_status;c::context_id/s::right_status;tg::tag_group/s::center_status/p::post_id">
                    <Post />
                  </Route>
                  <Route
                    path="/s::left_status;c::context_id/s::right_status;tg::tag_group/s::center_status/account"
                    component={ Account }
                  />
                </Switch>
              </PostProvider>
            </main>
            <Route path="/s::left_status;c::context_id/s::right_status;tg::tag_group/s::center_status">
              <div className={ styles.frame }>
                <div className={ styles.panels }>
                  {/*<Plink className={ styles.BG } to={ routes.posts } persistQuery>Posts</Plink>*/}
                  <Panel side="left">
                    <LeftEditorProvider>
                      <Context />
                    </LeftEditorProvider>
                  </Panel>
                  <Panel side="right">
                    <Tags />
                    <Burger />
                  </Panel>
                </div>
              </div>
              <Exports />
            </Route>
            </DigestProvider>
          </div>
        </Auth>
      </ErrorBoundary>
    </NetworkProvider>
  );
}

export default App