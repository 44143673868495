import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Markdown from '|/components/Markdown'

import Plink from '|/helpers/Plink'

import styles from './main.styl'

const Head = ({ context })=> {

  if(!context) return null
  return (
    <Plink
      className="label"
      to={{
        left: {
          c: context.id || undefined
        },
        search: {
          add: {
            context_ids: context.id
          }
        }
      }}
    >
      { context.title }
    </Plink>
  )
}

Head.propTypes = {
  context: PropTypes.object
}

const Body = ({ context })=> {

  if(!context) return null
  return (
    <Fragment>
      <div className={ styles.description }>
        <Markdown source={ context.description } />
      </div>
    </Fragment>
  )
}

Body.propTypes = {
  context: PropTypes.object
}

export default {
  Head,
  Body
}