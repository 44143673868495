import { useContext } from 'react'
import storage from '|/utils/storage'
import { eventChannel, buffers, END } from 'redux-saga'

import { API_PATH } from '|/settings'

const authHeader = (token)=> `JWT ${token.trim()}`

const handleResponse = (res)=> {
	return res.ok ?
			res.json()
			.then( json => json )
		:
			res.json()
			.then( json => json )
}

const handleNetworkError = (err)=> {
	setTimeout(()=> {
		throw new Error("MAINTENANCE")
	})
	return {
		error: 'Network error. Please try again.',
		is_offline: true
	}
}

const api = {
	codeSignIn(id, code, reset_password) {
		const url = `${API_PATH}/code-signin/${reset_password ? 'reset_password=true' : ''}`
		return fetch(url,{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				id,
				code,
			})
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	login(email, password) {
		const url = `${API_PATH}/signin/`
		return fetch(url,{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email,
				password
			})
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	getCurrentUser(token) {
		const url = `${API_PATH}/user/`
		return fetch(url,{
			headers: { 
				'Authorization': authHeader(token)
			}
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	updateCurrentUser(user,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/user/`
		return fetch(url,{
			method: 'PATCH',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(user)
		})
		.then( res => res.status === 200 ? res.json().then( json => json ) : res.json().then( json => ({ error: json }) ))
		.catch(handleNetworkError)
	},
	refreshToken(token) {
		console.log('test')
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/refresh-token/`
		return fetch(url,{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				token
			})
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	resetPassword(email) {
		const url = `${API_PATH}/reset-password/`
		return fetch(url,{
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email
			})
		})
		.then( res => res.status === 200 ? { ok: true } : { error: 'No such email in system' })
		.catch(handleNetworkError)
	},
	fetchURL(url,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		return fetch(url,{
			headers
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchContexts(context_id,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = context_id ? `${API_PATH}/search/contexts/?parent_context_id=${context_id}&limit=1000` : `${API_PATH}/search/contexts/?has_parent_context=false&limit=1000`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	createContext(context_id,context,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = context_id ? `${API_PATH}/contexts/${context_id}/contexts/${context.type}/` : `${API_PATH}/contexts/`
		return fetch(url,{
			method: 'POST',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(context)
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	updateContext(id,context,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/contexts/${id}/`
		return fetch(url,{
			method: 'PATCH',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(context)
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	deleteContext(id,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/contexts/${id}/`
		return fetch(url,{
			method: 'DELETE',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
		})
		.then( res => res.status === 204 ? { ok: true } : { error: 'Failed to delete post' })
		.catch(handleNetworkError)
	},
	updateMembers(context_id,members,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/contexts/${context_id}/members/bulk/`
		return fetch(url,{
			method: 'POST',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(members)
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchContext(context_id,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/contexts/${context_id}/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchDigests(context_id,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/contexts/${context_id}/digests/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	createDigest(context_id,digest_id,digest,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = digest_id ? `${API_PATH}/digests/${digest_id}/digests/` : `${API_PATH}/contexts/${context_id}/digests/`
		return fetch(url,{
			method: 'POST',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(digest)
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	updateDigest(id,digest,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/digests/${id}/`
		return fetch(url,{
			method: 'PATCH',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(digest)
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	deleteDigest(id,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/digests/${id}/`
		return fetch(url,{
			method: 'DELETE',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
		})
		.then( res => res.status === 204 ? { ok: true } : { error: 'Failed to delete post' })
		.catch(handleNetworkError)
	},
	fetchDigest(digest_id,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/digests/${digest_id}/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchPosts(search,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/search/posts/?facets=true&limit=100&ordering=-created_on&has_parent_post=false&${search}`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	signAttachment(filename,filetype,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/s3-sign-url/?filename=${filename}&filetype=${filetype}`
		return fetch(url,{
			headers: { 
				'Authorization': authHeader(token)
			}
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	uploadAttachment(file,signedUrl) {
		return eventChannel( emitter => {
			const xhr = new XMLHttpRequest()
			const onProgress = e => {
				if(e.lengthComputable){
					const progress = e.loaded / e.total
					emitter({ progress })
				}
			}
			const onError = e => {
				emitter({
					error: new Error('Upload failed')
				})
				emitter(END)
			}
			xhr.upload.addEventListener('progress',onProgress)
			xhr.upload.addEventListener('error',onError)
			xhr.upload.addEventListener('abort',onError)
			xhr.onreadystatechange = ()=> {
				const { readyState, status } = xhr
				if(readyState === 4) {
					if(status === 200) {
						emitter({
							success: true
						})
						emitter(END)
					} else {
						onError(null)
					}
				}
			}
			xhr.open('PUT',signedUrl)
			// xhr.setRequestHeader('x-amz-acl', 'public-read') <= required for S3 API 4
			xhr.send(file)

			return ()=> {
				xhr.upload.removeEventListener('progress', onProgress)
				xhr.upload.removeEventListener('error', onError)
				xhr.upload.removeEventListener('abort', onError)
				xhr.onreadystatechange = null
				xhr.abort()
			}
		}, buffers.sliding(2))
	},
	createPost(context_id,post,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/contexts/${context_id}/posts/`
		return fetch(url,{
			method: 'POST',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(post)
		})
		.then( res => res.ok ?
				res.json()
				.then( json => json )
			:
				res.json()
				.then( json => ({ error: json }))
		)
		.catch(handleNetworkError)
	},
	createComment(post_id,post,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/posts/${post_id}/posts/`
		return fetch(url,{
			method: 'POST',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(post)
		})
		.then( res => res.ok ?
				res.json()
				.then( json => json )
			:
				res.json()
				.then( json => ({ error: json }))
		)
		.catch(handleNetworkError)
	},
	updatePost(id,post,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/posts/${id}/`
		return fetch(url,{
			method: 'PATCH',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(post)
		})
		.then( res => res.ok ?
				res.json()
				.then( json => json )
			:
				res.json()
				.then( json => ({ error: json }))
		)
		.catch(handleNetworkError)
	},
	deletePost(id,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/posts/${id}/`
		return fetch(url,{
			method: 'DELETE',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
		})
		.then( res => res.status === 204 ? { ok: true } : { error: 'Failed to delete post' })
		.catch(handleNetworkError)
	},
	fetchPost(post_id,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/posts/${post_id}/timeline/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	createExport(search,extension,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/search/posts/${extension}/?facets=true&limit=100&${search}`
		return fetch(url,{
			headers: { 
				'Authorization': authHeader(token),
			},
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchExport(export_id,extension,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/${extension}/${export_id}/`
		return fetch(url,{
			headers: { 
				'Authorization': authHeader(token)
			}
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchTags(context,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/tags/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchMembers(context_id,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/contexts/${context_id}/members/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchUsers(token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/users/all/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchTeachers(token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/users/teachers/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchStudents(token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/users/students/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	fetchUser(user_id,token) {
		const headers = !token ? {} :
			{
				'Authorization': authHeader(token)
			}
		const url = `${API_PATH}/users/${user_id}/`
		return fetch(url,{ headers })
		.then(handleResponse)
		.catch(handleNetworkError)
	},
	inviteUser(email,role,token) {
		if(!token)
			return {
				error: 'No token'
			}
		const url = `${API_PATH}/invite-${role}/`
		return fetch(url,{
			method: 'POST',
			headers: { 
				'Authorization': authHeader(token),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email
			})
		})
		.then(handleResponse)
		.catch(handleNetworkError)
	},

}

module.exports = api