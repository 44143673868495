import { createSelector } from 'reselect'

const authSelector = state => state.auth.current_user_id
const usersSelector = state => state.users.list

const getCurrentUser = createSelector(
  [authSelector,usersSelector],
  (current_user_id,users)=> {
    return users[current_user_id]
  }
)

export default getCurrentUser

export const createGetCurrentUser = ()=> getCurrentUser