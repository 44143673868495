import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  AUTHENTICATE
} from '|/store/constants'

import storage from '|/utils/storage'

const Auth = ({ children })=> {

  const dispatch = useDispatch()
  const { authenticated, authenticating } = useSelector( state => state.auth )

  useEffect(()=> {
    console.log('AUTHENTICATED:',authenticated)
    if(!authenticated)
      dispatch({
        type: AUTHENTICATE
      })
  }, [authenticated])

  return (
    <React.Fragment>
      { !authenticating &&
          children
      }
    </React.Fragment>
  )
}


export default Auth