import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as PathStateProvider } from '|/hooks/pathState'
import { Switch, Route, Redirect } from 'react-router'

import store, { history, persistor } from './store'
import sagas from './store/sagas'

import { usePathState } from '|/hooks/pathState'
import { init } from '|/routes'

store.runSagas(sagas)

import Root from './Root'
import App from './containers/App'
require('moment/locale/de-ch')
import moment from 'moment'
moment.locale('de-ch')


export default props => {

  const pathState = usePathState()

  const indexRoute = pathState.toString(init)

  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={ persistor }>
        <ConnectedRouter history={history}>
          <PathStateProvider init={ init }>
              <Switch>
                <Route exact path="/">
                  <Redirect to={ indexRoute } />
                </Route>
                <Route>
                  <App />
                </Route>
              </Switch>
          </PathStateProvider>
        </ConnectedRouter>
      </PersistGate>
    </ReduxProvider>
  )
  // return (
  //   <ReduxProvider store={store}>
  //     <ConnectedRouter history={history}>
  //       <PathStateProvider init={ init }>
  //           <Switch>
  //             <Route exact path="/">
  //               <Redirect to={ indexRoute } />
  //             </Route>
  //             <Route>
  //               <App />
  //             </Route>
  //           </Switch>
  //       </PathStateProvider>
  //     </ConnectedRouter>
  //   </ReduxProvider>
  // )
}