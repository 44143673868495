import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import Markdown from '|/components/Markdown'

import Img from '|/helpers/Img'

import styles from './main.styl'

const Context = ({ context })=> {

  if(!context) return null

  const [ swiper, updateSwiper ] = useState(null)

  return (
    <div className={ `${styles.context}` }>
      { context.attachments && context.attachments.length > 0 && context.attachments[0] !== null &&
        <div className={ styles.carousel }>
          <Fragment>
            <Swiper
              getSwiper={ updateSwiper }
              watchOverflow={ true }
              navigation={{
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
              }}
            >
              { context.attachments.map((a,i)=> {
                return a !== null && <div key={ `context_${context.id}_attachment_${i}` } className={ styles.slide }>
                  <img src={ a.thumb || a.attachment || a } />
                </div>
              })}
            </Swiper>
          </Fragment>
        </div>
      }
      <div className={ styles.description }>
        <Markdown source={ context.description } />
      </div>
    </div>
  )
}

Context.propTypes = {
  context: PropTypes.object
}

export default Context