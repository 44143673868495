import {
  FETCHING_TAGS,
  FETCHED_TAGS
} from '../constants'

const INITIAL_STATE = {
  list: {},
  loading: false
}

export default function(state=INITIAL_STATE,action) {
  switch(action.type) {
    case FETCHING_TAGS:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_TAGS:
      const { data } = action
      const new_tags = data.reduce((obj,tg)=> {
        obj[tg.id] = {
          ...tg,
          ...( tg.group ? { group: tg.group.toLowerCase() } : {} )
        }
        return obj
      },{})
      return {
        ...state,
        loading: false,
        list: {
          ...state.list,
          ...new_tags
        },
      }
  }
  return state
}