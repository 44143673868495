import React, { useState, useEffect, useContext, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useQuery } from '|/hooks/pathState'

import { getContextsByType } from '|/store/contexts/selectors'
import { LeftEditorContext } from '|/contexts/LeftEditor'
import ContextListItem from './ContextListItem'
import Button from '|/helpers/Button'

import styles from './main.styl'

const ContextsList = ({ contextId, mainContext, type })=> {

  const { context_ids } = useQuery()
  const [ checked_ids, setChecked_ids ] = useState([])
  const { authenticated } = useSelector( state => state.auth )
  const contexts = useSelector( state => getContextsByType(state,contextId,type))
  const { record, setRecord, minimised, setMinimised } = useContext(LeftEditorContext)

  useEffect(()=> {
    if(!isEqual(context_ids,checked_ids))
      setChecked_ids(Array.isArray(context_ids) ? context_ids : [context_ids])
  }, [ context_ids ])

  // const [ expanded, setExpanded ] = useState(false)

  // const handleChange = key => (e,isExpanded)=> {
  //   setExpanded(isExpanded ? key : false)
  // }

  const _handleOnNewClick = ()=> {
    setRecord({
      parent_context_id: contextId,
      type
    })
  }

  return (
    <div className={ [ styles.contexts, styles[type+'s'] ].join(' ') }>
      <h4 className={ styles.listSubHeader }>{ `${type}s` }</h4>
      <div>
        { contexts.map((context)=> {
          const panel_id = `panel_${context.id}`
          const checked = checked_ids && checked_ids.indexOf(context.id) !== -1
          return <ContextListItem
              key={ panel_id }
              // expanded={ expanded === panel_id }
              // onChange={ handleChange(panel_id) }
              context={ context }
              checked={ checked }
            />
        })}
      </div>
      <div className={ styles.footer }>
        { (
            authenticated &&
            ((mainContext &&
              (type === 'channel' && mainContext.is_member) ||
              (type === 'milestone' && mainContext.is_admin)
            ) ||
            (!mainContext && !contextId))
          ) &&
          <Button
            kind="constructive"
            onClick={ _handleOnNewClick }
            disabled={ record }
            data-cy={`create${type.capitalise()}Button`}
          >{ `Create new ${type} +` }</Button>
        }
      </div>
    </div>
  )
}

ContextsList.propTypes = {
  contexts: PropTypes.array
}

export default ContextsList