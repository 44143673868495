import React from 'react'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import styles from './main.styl'

const Label = ({ el, icon, iconStyle, kind, className, children, ...props })=> {
  const classList = [ styles.container, className ]
  const El = el
  if(kind) classList.push(styles[kind])
  return (
    <El { ...props } className={ classList.join(' ') }>
      { children }
      { icon &&
        <Fa className="icon" style={ iconStyle } icon={ icon } />
      }
    </El>
  )
}

Label.propTypes = {
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  kind: PropTypes.string,
  el: PropTypes.string,
}

Label.defaultProps = {
  icon: undefined,
  iconStyle: 'fal',
  el: 'label',
}

export default Label