import merge from 'lodash/merge'

import {
  FETCHING_DIGESTS,
  FETCHED_DIGESTS,
  FETCHING_DIGEST,
  CREATING_DIGEST,
  CREATED_DIGEST,
  CREATING_DIGEST_FAILED,
  UPDATING_DIGEST,
  UPDATED_DIGEST,
  UPDATING_DIGEST_FAILED,
  DELETED_DIGEST,
} from '../constants'

const INITIAL_STATE = {
  list: {},
  loading: false,
  saving: false,
  saved: undefined,
}

export default function(state=INITIAL_STATE,action) {
  switch(action.type) {
    case FETCHING_DIGESTS:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_DIGESTS:
      const { data } = action
      const new_digests = data.reduce((obj,dgst)=> {
        obj[dgst.id] = {
          ...dgst
        }
        return obj
      },{})
      return {
        ...state,
        loading: false,
        list: merge({},state.list,new_digests),
      }
    case CREATING_DIGEST:
      return {
        ...state,
        saving: true,
      }
    case CREATED_DIGEST:
      const new_digest = action.data
      return {
        ...state,
        saving: false,
        list: {
          ...state.list,
          [new_digest.id]: {
            ...new_digest,
          }
        },
        saved: new_digest,
      }
    case CREATING_DIGEST_FAILED:
      return {
        ...state,
        saving: false,
      }
    case UPDATING_DIGEST:
      return {
        ...state,
        saving: true,
      }
    case UPDATED_DIGEST:
      const updated_digest = action.data
      return {
        ...state,
        saving: false,
        list: {
          ...state.list,
          [updated_digest.id]: {
            ...updated_digest,
          }
        },
        saved: updated_digest,
      }
    case UPDATING_DIGEST_FAILED:
      return {
        ...state,
        saving: false,
      }
    case DELETED_DIGEST:
      const clear_id = action.data
      const { [clear_id]:_, ...new_list } = state.list
      return {
        ...state,
        list: new_list,
      }
  }
  return state
}