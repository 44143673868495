import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Document } from 'react-pdf/dist/entry.parcel'
import { Page } from 'react-pdf'

import styles from './main.styl'

const PDF = ({ file, className, onLoadError })=> {

  const [ pageNumber, setPageNumber ] = useState(1)
  const [ pageCount, setPageCount ] = useState()
  const [ previewWidth, setPreviewWidth ] = useState()
  const [ previewHeight, setPreviewHeight ] = useState()
  const [ src, setSrc ] = useState()
  const ref = useRef()
  
  const _onLoad = ({ numPages })=> {
    setPageCount(numPages)
    const av_width = ref.current.offsetWidth
    const av_height = ref.current.offsetHeight
    setPreviewWidth(av_width)
    setPreviewHeight(av_height)
  }

  const _onLoadError = (error)=> {
    if(src && src !== file)
      setSrc(file)
    else if(src)
      setSrc(undefined)
    if(onLoadError)
      onLoadError(error)
  }

  useEffect(()=> {
    if(!src)
      setSrc(file)
  },[ file ])

  return (
    <div
      ref={ ref }
      className={[
        className,
        styles.container
      ].join(' ')}
    >
      <Document
        file={ src }
        onLoadSuccess={ _onLoad }
        onLoadError={ _onLoadError }
      >
        <Page
          pageNumber={ pageNumber }
          width={ previewWidth }
          height={ previewHeight }
        />
      </Document>
    </div>
  )
}

PDF.propTypes = {
  file: PropTypes.string
}

PDF.defaultProps = {
  file: undefined
}

export default PDF