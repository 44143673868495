import { useState, useRef, useCallback, useEffect, useLayoutEffect } from 'react'
import throttle from 'lodash/throttle'

import { history } from  '|/store'

const scrollTo = (el,target=false,opts={})=> {
  const options = {
    dir: opts.dir || 'vertical',
    duration: opts.duration || 400,
    offset: opts.offset || 0,
    onScrollEnd: opts.onScrollEnd || null
  }
  let timeout = null
  const edge = options.dir === 'horizontal' ? 'Left' : 'Top'
  const start = el[`scroll${edge}`]
  let target_position = target ? target[`offset${edge}`] : el.offsetHeight
  let change = (target_position + options.offset) - start
  let currentTime = 0
  const increment = 20
  function animate() {
    currentTime += increment
    target_position = target ? target[`offset${edge}`] : 0
    const scrollTo = Math.easeInOutQuad(currentTime,start,change,options.duration)
    el[`scroll${edge}`] = scrollTo
    if(currentTime < options.duration)
      timeout = setTimeout(animate, increment)
    else
      clearTimeout(timeout)
      if(options.onScrollEnd) options.onScrollEnd()
  }
  animate()
}

export const useScrollTo = ()=> {

  return scrollTo
}

export const useInfiniteScroll = (callback)=> {
  const [ hasReachedEnd, setHasReachedEnd ] = useState(false)

  const _handleScroll = ()=> {
    if(
        document.documentElement.clientHeight === document.documentElement.offsetHeight
      ||
        document.documentElement.clientHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight
      ||
        hasReachedEnd
      )
        setHasReachedEnd(true)
    else
      return
  }

  const _throttledHandleScroll = throttle(_handleScroll,400)

  useLayoutEffect(()=> {
    window.addEventListener('scroll', _throttledHandleScroll)
    return ()=> window.removeEventListener('scroll', _throttledHandleScroll)
  }, [])

  useEffect(()=> {
    if(!hasReachedEnd)
      return
    if(callback)
      callback()
  }, [ hasReachedEnd ])

  return [ hasReachedEnd, setHasReachedEnd ]
}

export const useScrollY = (el)=> {

  const [ top, setTop ] = useState(0)
  const [ bottom, setBottom ] = useState(0)
  const ref = useRef(null)

  const _getScrollTop = ()=> {
    const top = ref.current.scrollTop
    const height = ref.current.clientHeight
    const scrollHeight = ref.current.scrollHeight
    setTop(top)
    setBottom(scrollHeight - (height + top))
  }

  const _throttledGetScrollTop = throttle(_getScrollTop,40)

  const setRef = useCallback( node => {
    if(node && !ref.current) {
      ref.current = node
      node.addEventListener('scroll', _throttledGetScrollTop)
      _getScrollTop()
    } else if(ref.current) {
      ref.current.removeEventListener('scroll', _throttledGetScrollTop)
    }
  },[])

  return [
    {
      top,
      bottom,
    },
    setRef,
    ref,
  ]
}