import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useInterval } from '|/hooks/interval'

import { getExport } from '|/store/exports/selectors'

import {
  FETCH_EXPORT,
} from '|/store/constants'

import styles from './main.styl';

const Export = ({ id })=> {

  const dispatch = useDispatch()
  const exprt = useSelector( state => getExport(state,id) )

  useInterval(()=> {
    dispatch({
      type: FETCH_EXPORT,
      extension: exprt.extension,
      id
    })
  },4000)

  return (
    <div className={ styles.poll }>
      { exprt.status === 'RUNNING' &&
        `Generating ${exprt.extension.toUpperCase()}...`
      }
    </div>
  );
}

export default Export