import React from 'react'
import PropTypes from 'prop-types'

import * as types from './types'

import styles from './main.styl'

const Input = props => {

  const InputElement = types[props.type]
  const classList = [ styles.container ]

  // console.log(props)

  return (
    <fieldset className={ classList.join(' ') }>
      <label htmlFor="">{ props.label }</label>
      <InputElement { ...props } ></InputElement>
    </fieldset>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

Input.defaultProps = {
  name: '',
  onChange: ()=> {}
}

export default Input