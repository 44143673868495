import { createSelector } from 'reselect'

const tagsSelector = state => state.tags.list

const getTagsByParents = (parents,tags)=> {
  return parents.map( tag => {
    const children = tags
      .filter( t => t.parent_tag_id === tag.id )
    return {
      ...tag,
      ...(children.length > 0 ? { options: getTagsByParents(children,tags) } : {})
    }
  })
}

const getTagTree = (tags)=> {
  const tags_array = Object.values(tags)
  const roots = tags_array
    .filter( tag => !tag.parent_tag_id )
  return getTagsByParents(roots,tags_array)
}

export default createSelector(
  [tagsSelector],
  getTagTree
)