import { createSelector } from 'reselect'

const postsSelector = state => state.posts.list
const idSelector = (state,id)=> id

const getComments = (posts,id)=> {
  return Object.values(posts)
    .filter( pst => pst.parent_post_id === parseInt(id) )
}

export default createSelector(
  [postsSelector,idSelector],
  getComments
)