import {
	AUTHENTICATING,
	AUTHENTICATED,
	SET_REDIRECT,
	CLEAR_REDIRECT,
	LOGGING_IN,
	LOGGED_IN,
	LOGGED_OUT,
	GO_OFFLINE,
	RESET_PASSWORD_SENT,
	RESET_PASSWORD_FAILED,
	SET_PASSWORD_SENT,
	SET_PASSWORD_FAILED,
} from '../constants'

const INITIAL_STATE = {
	authenticated: false,
	authenticating: true,
	offline: false,
	current_user_id: null,
	error: undefined,
	info: undefined,
	redirectTo: '/'
}

export default (state=INITIAL_STATE,action)=> {
	switch(action.type) {
		case AUTHENTICATING:
			return {
				...state,
				authenticating: true,
				authenticated: false
			}
		case AUTHENTICATED:
			return {
				...state,
				authenticating: false,
				authenticated: true,
				current_user_id: action.user_id,
				error: false,
				offline: false
			}
		case RESET_PASSWORD_SENT:
			return {
				...state,
				info: action.info,
				error: false
			}
		case RESET_PASSWORD_FAILED:
			return {
				...state,
				error: action.error
			}
		case SET_PASSWORD_SENT:
			return {
				...state,
				info: action.info,
				error: false
			}
		case SET_PASSWORD_FAILED:
			return {
				...state,
				error: action.error
			}
		case SET_REDIRECT:
			return {
				...state,
				redirectTo: action.redirectTo
			}
		case CLEAR_REDIRECT:
			return {
				...state,
				redirectTo: '/'
			}
		case GO_OFFLINE:
			return {
				...state,
				offline: true,
				authenticating: false,
				error: action.error
			}
		case LOGGING_IN:
			return {
				...state,
				authenticating: true,
				authenticated: false
			}
		case LOGGED_IN:
			return {
				...state,
				authenticated: true,
				authenticating: false,
				offline: false,
				current_user_id: action.user_id,
			}
		case LOGGED_OUT:
			return {
				...state,
				authenticated: false,
				authenticating: false,
				current_user_id: null,
				error: action.error
			}
	}
	return state
}