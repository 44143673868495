import React from 'react'
import PropTypes from 'prop-types'

import styles from './main.styl'

const Loader = ({ dotCount, style })=> (
  <div
    className={ styles.loader }
    style={{ ...style }}
  >
    { new Array(dotCount).fill().map((a,i)=>
      <div
        key={`loader-${i}`}
        className={ styles[i] }
        style={{
          animationDelay: `${1-(dotCount/i)}s`
        }}
      />
    )}
  </div>
)

Loader.propTypes = {
  dotCount: PropTypes.number,
}

Loader.defaultProps = {
  dotCount: 5,
}

export default Loader