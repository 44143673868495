import moment from 'moment'
import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Collapse from '@material-ui/core/Collapse'

import { getDigestsByDigest } from '|/store/digests/selectors'
import { createGetUser } from '|/store/users/selectors/getUser'
import { createGetContext } from '|/store/contexts/selectors/getContext'
import { LeftEditorContext } from '|/contexts/LeftEditor'
import { useQuery } from '|/hooks/pathState'

import Digest from './Digest'
import DigestsList from './DigestsList'

import ListItem from '|/components/ListItem'
import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'
import SelectButton from '|/helpers/SelectButton'
import ExpandButton from '|/helpers/ExpandButton'

import styles from './main.styl'

import { AccordionContext } from '|/contexts/Accordion'

import {
  FETCH_CONTEXT
} from '|/store/constants'

const DigestListItem = ({ digest, context, mainContext })=> {

  const { digest_ids } = useQuery()
  const [ dids, setDids ] = useState([])
  const { record, setRecord, minimised, setMinimised } = useContext(LeftEditorContext)
  const [ expanded, handleChange ] = useContext(AccordionContext)
  const digests = useSelector( state => getDigestsByDigest(state, digest.id))
  const [ created_at, setCreated_at ] = useState()
  const getContext = useMemo(createGetContext,null)
  const channel = useSelector( state => getContext(state, digest.channel_id))
  const getUser = useMemo(createGetUser,null)
  const user = useSelector( state => getUser(state, digest.creator_id))

  const has_digests = digests.length > 0

  const _handleOnEditClick = (e)=> {
    setRecord({
      ...digest,
      type: 'digest',
    })
    setMinimised(false)
  }

  useEffect(()=> {
    if(!isEqual(digest_ids,dids))
      setDids(Array.isArray(digest_ids) ? digest_ids : [digest_ids])
  }, [ digest_ids ])

  useEffect(()=> {
    setCreated_at(moment(digest.created_on).format('YYYY.MM.DD - HH:mm'))
  },[ digest ])

  const _handleOnNewClick = ()=> {
    setRecord({
      context_id: digest.context_id,
      parent_digest_id: digest.id,
      type: 'digest'
    })
  }

  return (
    <div className={ styles.digest }>
      <ListItem className={ styles.listItem }>
        <Plink
          className={ styles.filter }
          to={{
            search: {
              toggle: {
                digest_ids: digest.id
              }
            }
          }}
        >
          <div className={ styles.creator }>
            { channel ?
                `${ channel.title }`
              : user &&
                `${ user.usable_name }`
            }
          </div>
          <div className={ styles.created_at }>
            { created_at }
          </div>
          { has_digests &&
            <ExpandButton
              onClick={ e => handleChange(digest.id) }
              expanded={ expanded == digest.id }
            />
          }
          <SelectButton
            selected={ dids.indexOf(digest.id) !== -1 }
          />
        </Plink>
      </ListItem>
      <Digest digest={ digest } />
      <div className={ styles.footer }>
        { has_digests &&
          <Button
            className={ styles.more }
            material={ false }
            onClick={ e => handleChange(digest.id) }
          >
            { `${expanded != digest.id ? `Show ${digests.length}` : 'Hide'} ${digests.length === 1 ? 'reply' : 'replies'}` }
          </Button>
        }
        { mainContext && mainContext.is_member &&
          <Button
            kind="constructive"
            onClick={ _handleOnNewClick }
            disabled={ record }
          >{ `Reply +` }</Button>
        }
        { digest.is_owner &&
          <Button
            onClick={ _handleOnEditClick }
            disabled={ record && record.id !== digest.id }
          >
            Edit
          </Button>
        }
      </div>
      { has_digests &&
        <Collapse
          in={ expanded === digest.id }
          className={ styles.listBody }
        >
          <DigestsList digests={ digests } context={ context } mainContext={ mainContext } />
        </Collapse>
      }
    </div>
  )
}

DigestListItem.propTypes = {
  digest: PropTypes.object
}

export default DigestListItem