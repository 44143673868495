import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useInterval } from '|/hooks/interval'

import { getExport } from '|/store/exports/selectors'

import Poll from './Poll'
import Download from './Download'

import styles from './main.styl';

const Export = ({ id })=> {

  const dispatch = useDispatch()
  const exprt = useSelector( state => getExport(state,id) )

  return (
    <div className={ [ styles.export, styles[exprt.status] ].join(' ') }>
      { exprt.status !== 'COMPLETED' ?
          <Poll id={ id } />
        :
          <Download id={ id } />
      }
    </div>
  );
}

export default Export