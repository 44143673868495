import React, { Fragment, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useParams } from '|/hooks/router'

import Detail from './Detail'
import Form from './ContextForm'
import ContextsList from './ContextsList'
import { getContextsByType, getSortedContexts, getContext } from '|/store/contexts/selectors'
import { AccordionProvider } from '|/contexts/Accordion'
import { LeftEditorContext } from '|/contexts/LeftEditor'

import ListSubheader from '@material-ui/core/ListSubheader'

import styles from './main.styl'

import {
  FETCH_CONTEXTS,
  FETCH_CONTEXT,
} from '|/store/constants'

const Contexts = ({ ...props })=> {

  const dispatch = useDispatch()
  const params = useParams()
  const isLoadingContexts = useSelector( state => state.contexts.loading )
  const context = useSelector( state => getContext(state,params.context_id) )
  const contextsByType = useSelector( state => getContextsByType(state,params.context_id,'projects'))
  const { record } = useContext(LeftEditorContext)
  const { authenticated } = useSelector( state => state.auth )

  useEffect(()=> {
    if(params.context_id && params.context_id != 0)
      dispatch({
        type: FETCH_CONTEXT,
        id: params.context_id
      })
    dispatch({
      type: FETCH_CONTEXTS,
      id: params.context_id != 0 ? params.context_id : null
    })
  },[ params.context_id ])

  return (
    <Fragment>
      { (record && authenticated) &&
        <Form />
      }
      { context &&
        <Detail context={ context } />
      }
      <AccordionProvider>
        { contextsByType &&
          context &&
          context.type === 'project' &&
            <Fragment>
              <ContextsList contextId={ params.context_id } mainContext={ context } type="milestone" />
              <ContextsList contextId={ params.context_id } mainContext={ context } type="channel" />
            </Fragment>
          || !context &&
            <ContextsList type="project" />
        }
      </AccordionProvider>
    </Fragment>
  )
}

export default Contexts