import { useContext } from 'react'
import { __RouterContext } from 'react-router'

export const useLocation = ()=> {
  const context = useContext(__RouterContext)
  return context.location
}

export const useMatch = ()=> {
  const context = useContext(__RouterContext)
  return context.match
}

export const useParams = ()=> {
  const context = useContext(__RouterContext)
  return context.match.params
}

export const usePathname = ()=> {
  const context = useContext(__RouterContext)
  return context.location.pathname
}

export const useHash = ()=> {
  const context = useContext(__RouterContext)
  return context.location.hash
}

export const useSearch = ()=> {
  const context = useContext(__RouterContext)
  return context.location.search
}