import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createGetFilterItemByKey } from '|/store/selectors/getFilterItemByKey'

import { Fa } from '|/icons'

import Button from '|/helpers/Button'
import Plink from '|/helpers/Plink'

import styles from './main.styl'

const FilterItem = ({ item })=> {

  console.log(item)

  const getFilterItemByKey = useMemo(createGetFilterItemByKey,[])
  const object = useSelector( state => getFilterItemByKey(state, item.key, item.value))

  if(!object) return null

  return (
    <Button
      material={ false }
      className={ styles.filteritem }
      el='div'
    >
      { object.title ?
          <Plink
            to={{
              left: {
                c: item.value
              }
            }}
          >
            { object.title }
          </Plink>
        :
          object.name || '[digest]'
      }
      <Plink
        to={{
          search: {
            toggle: {
              [item.key]: item.value
            }
          }
        }}
      >
        <Button
          className={ styles.remove }
          round={ true }
          material={ false }
        >
          <Fa icon="times" />
        </Button>
      </Plink>
    </Button>
  )
}

export default FilterItem