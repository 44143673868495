import { createSelector } from 'reselect'

const usersSelector = state => state.users.list
const roleSelector = (state,role)=> role

const getUsersByRole = createSelector(
  [usersSelector,roleSelector],
  (users,role)=> {
    return Object.values(users).filter( u => u.role === role )
  }
)

export default getUsersByRole

export const createGetUsersByRole = ()=> getUsersByRole