import { createSelector } from 'reselect'

const usersSelector = state => state.users.list
const idsSelector = (state,user_ids)=> user_ids

const getUsersByIds = createSelector(
  [usersSelector,idsSelector],
  (users,user_ids)=> {
    if(!user_ids) return []
    const result = user_ids.reduce((arr,id)=> users[id] ? [...arr,users[id]] : arr ,[])
    return result
  }
)

export default getUsersByIds

export const createGetUsersByIds = ()=> getUsersByIds