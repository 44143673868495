import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getContext } from '|/store/contexts/selectors'

import Button from '|/helpers/Button'
import Plink from '|/helpers/Plink'

import styles from './main.styl'

const DefaultForm = props => {

  return (
    <div>
      Digest
    </div>
  )
}

DefaultForm.propTypes = {
  context: PropTypes.object
}

export default DefaultForm