import merge from 'lodash/merge'

import {
  FETCHING_USERS,
  FETCHED_USERS,
  FETCHING_USER,
  FETCHED_USER,
  FETCHING_USER_FAILED,
  UPDATING_CURRENT_USER,
  UPDATED_CURRENT_USER,
  UPDATING_CURRENT_USER_FAILED,
  INVITED_USER,
} from '../constants'

const INITIAL_STATE = {
  list: {},
  loading: false,
  saving: false,
  error: false,
}

export default function(state=INITIAL_STATE,action) {
  switch(action.type) {
    case FETCHING_USERS:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_USERS:
      const { data } = action
      const new_users = data.reduce((obj,usr)=> {
        obj[usr.id] = usr
        return obj
      },{})
      return {
        ...state,
        loading: false,
        error: false,
        list: {
          ...state.list,
          ...new_users
        },
      }
    case FETCHING_USER:
      return {
        ...state,
        loading: true,
      }
    case FETCHED_USER:
      const usr = action.data
      return {
        ...state,
        loading: false,
        error: false,
        list: merge({},state.list,{[usr.id]:{
          ...usr
        }}),
      }
    case FETCHING_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case INVITED_USER:
      const member = action.data
      return {
        ...state,
        loading: false,
        error: false,
        list: merge({},state.list,{[member.id]:{
          ...member
        }}),
      }
    case UPDATING_CURRENT_USER:
      return {
        ...state,
        saving: true,
      }
    case UPDATED_CURRENT_USER:
      const updated_usr = action.data
      return {
        ...state,
        saving: false,
        error: false,
        list: merge({},state.list,{[updated_usr.id]:{
          ...updated_usr
        }}),
      }
    case UPDATING_CURRENT_USER_FAILED:
      console.log('ACTION',action)
      return {
        ...state,
        saving: false,
        error: action.error,
      }
  }
  return state
}