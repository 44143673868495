import React, { Fragment, useState, useEffect, useContext } from 'react'

import Button from '|/helpers/Button'

import styles from './main.styl'

const AccountView = ({ user, onEdit })=> {

  if(!user) return null
  return (
    <div className={ styles.user }>
      <div className={ styles.field }>
        <span className={ styles.label }>First name:</span> { user.firstname }
      </div>
      <div className={ styles.field }>
        <span className={ styles.label }>Last name:</span> { user.lastname }
      </div>
      <div className={ styles.field }>
        <span className={ styles.label }>Email:</span> { user.email }
      </div>
      {/* <Button */}
      {/*   onClick={ onEdit } */}
      {/* > */}
      {/*   Edit */}
      {/* </Button> */}
    </div>
  )
}

export default AccountView