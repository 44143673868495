import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { history } from '|/store'
import { init } from '|/routes'
import { Switch, Route } from 'react-router-dom';

import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { getCurrentUser } from '|/store/users/selectors'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'

import View from './View'
import Form from './Form'

import styles from './main.styl'

const Account = ({ match })=> {

  const pathState = usePathState()
  const { verification_code } = useParams()
  const user = useSelector( state => getCurrentUser(state) )
  const saving = useSelector( state => state.users.saving )
  const [ editing, setEditing ] = useState(false)
  const [ verified, setVerified ] = useState(false)

  useEffect(()=> {
    if(editing && !saving && verified)
      setEditing(false)
  },[ saving ])

  useEffect(()=> {
    if(user && user.has_usuable_password)
      setVerified(true)
  },[ user ])

  // useEffect(()=> {
    // if(!editing)
    //   history.replace(pathState.toString(user.has_usuable_password ? match.path : init))
  // },[ editing ])

  return (
    <Overlay
      className={ styles.container }
      disableClose={ verification_code }
      onClose={ ()=> {
        const to = verification_code ? 
            init
          :
            {
              overlay: undefined
            }
        history.push(pathState.toString(pathState.merge(to)))
      }}
    >
      <div className={ styles.content }>
        <Switch>
          <Route exact path={ match.path }>
            <View user={ user } onEdit={ e => setEditing(true) } />
          </Route>
          <Route path={`${match.path}/manage/:verification_code?`}>
            <Form user={ user } onCancel={ e => setEditing(false) } />
          </Route>
        </Switch>
      </div>
    </Overlay>
  )
}

export default Account