import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import Img from '|/helpers/Img'
import Button from '|/helpers/Button'

import styles from './main.styl'

const Attachments = props => {

  const _onAttachmentsChange = (e)=> {
    const value = [...props.value,...e.target.files]
    e.target.value = null
    props.onChange(props.name,value)
  }

  const _removeAttachment = (id)=> {
    const value = props.value.without(props.value.find( a => a.id === id ))
    props.onChange(props.name,value)
  }

  const _onAttachmentsFocus = (e)=> {
    
  }

  return (
    <div>
      <div className={ styles.attachments }>
        <input
          type="file"
          { ...props.config }
          name={ props.name }
          onChange={ _onAttachmentsChange }
          onFocus={ _onAttachmentsFocus }
        />
        { props.value && props.value.map( a => 
          <div
            key={ `${a.id}-${a.filename}` }
            className={[ styles.attachment, !a.id ? styles.loading : undefined ].join(' ')}
          >
            <div
              style={{
                width: `${a.progress * 100}%`,
              }}
              className={ styles.loader }
            />
            <Img
              className={ styles.thumb }
              src={ a.thumb }
              onLoad={ props.onLoad }
            />
            <div className={ styles.filename }>{ a.filename }</div>
            <Button
              className={ styles.remove }
              kind="destructive"
              round={ true }
              onClick={ e => _removeAttachment(a.id) }
            >
              <Fa icon="times"/>
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

Attachments.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onLoad: PropTypes.func,
}

Attachments.defaultProps = {
  name: '',
  onChange: ()=> null,
  onLoad: ()=> null,
}

export default Attachments