import { createSelector } from 'reselect'

const tagsSelector = state => state.tags.list
const postSelector = (state,post)=> post

const getTagsByPost = (tags,post)=> {
  if(post && post.tag_ids)
    return Object.values(tags).filter( t => post.tag_ids.indexOf(t.id) !== -1 )
  return []
}

export default createSelector(
  [tagsSelector,postSelector],
  getTagsByPost
)