import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import styles from './main.styl'

import Post from './Post'

const PostsList = ({ posts })=> {
  return (
    <Fragment>
      { posts.map((post)=>
        <Post key={ post.id } post={ post } />
      )}
    </Fragment>
  )
}

PostsList.defaultProps = {
  posts: PropTypes.array
}

export default PostsList