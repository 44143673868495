import React, { Fragment, useRef, useState, useEffect, useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getTagTree } from '|/store/tags/selectors'
import { getUsers, getCurrentUser, getUsersByIds } from '|/store/users/selectors'

import { useValidation } from '|/hooks/validation'

import {
  INVITE_USER
} from '|/store/constants'

import Label from '|/helpers/Label'
import Button from '|/helpers/Button'
import Input from '|/helpers/Input'

import styles from './main.styl'

const ProjectForm = props => {

  const validate = useValidation()
  const dispatch = useDispatch()
  const currentUser = useSelector( state => getCurrentUser(state) )
  const users = useSelector( state => getUsers(state) )
  const [ adminIds, setAdminIds ] = useState()
  const [ memberIds, setMemberIds ] = useState()
  const admins = useSelector( state => getUsersByIds(state,adminIds) )
  const members = useSelector( state => getUsersByIds(state,memberIds) )
  const tags = useSelector( state => getTagTree(state) )
  const [ canInvite, setCanInvite ] = useState(false)

  useEffect(()=> {
    setCanInvite(currentUser && currentUser.role === 'teacher')
  },[ currentUser ])

  useEffect(()=> {
    if(props.nextRecord && currentUser) {
      setAdminIds(props.nextRecord.admin_ids ? props.nextRecord.admin_ids : [currentUser.id])
      setMemberIds(props.nextRecord.member_ids ? props.nextRecord.member_ids : [])
    }
  },[ props.nextRecord ])

  const _onInviteClick = (email,callback)=> {
    dispatch({
      type: INVITE_USER,
      email,
      role: 'student',
      callback,
    })
  }

  const _onAdminInviteClick = (email)=> {
    _onInviteClick(email,_onAdminInvited)
  }

  const _onMemberInviteClick = (email)=> {
    _onInviteClick(email,_onMemberInvited)
  }

  const _onAdminInvited = ({ id })=> {
    props.onChange('admin_ids',props.nextRecord.admin_ids ? props.nextRecord.admin_ids.with(id) : [id])
  }

  const _onMemberInvited = ({ id })=> {
    props.onChange('member_ids',props.nextRecord.member_ids ? props.nextRecord.member_ids.with(id) : [id])
  }

  const _onChange = (key,val)=> {
    props.onChange(key,val)
  }

  const _onSubmit = (e)=> {
    props.onSubmit(e)
  }

  const inviteAdminRenderer = (value)=> {
    if(!canInvite) return null
    const is_email = validate(value,{email:true})
    if(is_email)
      return <Button
        kind="constructive"
        className={ styles.invite }
        onClick={ e => _onAdminInviteClick(value) }
      >Invite</Button>
  }

  const inviteMemberRenderer = (value)=> {
    if(!canInvite) return null
    const is_email = validate(value,{email:true})
    if(is_email)
      return <Button
        kind="constructive"
        className={ styles.invite }
        onClick={ e => _onMemberInviteClick(value) }
      >Invite</Button>
  }

  const valueRenderer = ({ is_verified, usable_name, email })=>
    <div className={ styles.person }>
      <div className={ styles.name }>{ usable_name }</div>
      <div className={ styles.email }>{ email }</div>
      { (!is_verified && canInvite) &&
        <Button
          size="small"
          onClick={ e => _onInviteClick(email) }
        >Resend invitation</Button>
      }
      { (!is_verified && !canInvite) &&
        <Label>Pending invitation</Label>
      }
    </div>

  return (
    <form onSubmit={ _onSubmit }>
      { Object.keys(props.controls).map((key)=>
          <Input
            key={ `left-formElement-${key}` }
            name={ key }
            {...{
              ...props.controls[key],
              ...( key === 'admin_ids' ? { options:users, padded_value:admins, valueRenderer, inviteRenderer:inviteAdminRenderer } : {} ),
              ...( key === 'member_ids' ? { options:users, padded_value:members, valueRenderer, inviteRenderer:inviteMemberRenderer } : {} ),
              ...( key === 'tag_ids' ? { options:tags } : {} ),
            }}
            onChange={ _onChange }
          />
      )}
    </form>
  )
}


export default ProjectForm