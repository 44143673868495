import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = props => {

  const _onCheckboxChange = (e)=> {
    const value = e.target.checked
    props.onChange(props.name,value)
  }

  const _onCheckboxFocus = (e)=> {
    
  }

  return (
    <div>
      <input
        type="checkbox"
        { ...props.config }
        name={ props.name }
        checked={ props.value }
        onChange={ _onCheckboxChange }
        onFocus={ _onCheckboxFocus }
      />
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  name: '',
  onChange: ()=> {}
}

export default Checkbox