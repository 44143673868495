import React, { createContext, useState } from 'react'

export const AccordionContext = createContext()

export const AccordionProvider = ({ children })=> {

  const [ expanded, setExpanded ] = useState(false)

  const handleChange = key =>
    setExpanded(key !== expanded ? key : false)

  const accordionContext = [
    expanded,
    handleChange,
  ]

  return <AccordionContext.Provider
      value={ accordionContext }
    >
      { children }
    </AccordionContext.Provider>
}