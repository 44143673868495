import union from 'lodash/union'
import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'

import {
  FETCH_CONTEXTS,
  FETCHING_CONTEXTS,
  FETCHED_CONTEXTS,
  FETCHING_CONTEXTS_FAILED,
  FETCH_CONTEXT,
  FETCHING_CONTEXT,
  FETCHED_CONTEXT,
  FETCHING_CONTEXT_FAILED,
  CREATE_CONTEXT,
  CREATING_CONTEXT,
  CREATED_CONTEXT,
  CREATING_CONTEXT_FAILED,
  UPDATE_CONTEXT,
  UPDATING_CONTEXT,
  UPDATED_CONTEXT,
  UPDATING_CONTEXT_FAILED,
  UPDATE_MEMBERS,
  UPDATING_MEMBERS,
  UPDATED_MEMBERS,
  UPDATING_MEMBERS_FAILED,
  DELETE_CONTEXT,
  DELETING_CONTEXT,
  DELETED_CONTEXT,
  DELETING_CONTEXT_FAILED,
} from '|/store/constants'

function* fetchContexts({ id }) {
  console.log('fetching contexts')
  yield put({
    type: FETCHING_CONTEXTS
  })
  let token = yield call(storage.getItem,'token')
  const { results, error } = yield call(api.fetchContexts, id, token)
  if(error) {
    yield put({
      type: FETCHING_CONTEXTS_FAILED,
      error: error
    })
  } else {
    yield put({
      type: FETCHED_CONTEXTS,
      data: results
    })
  }
}

function* fetchContext({ id }) {
  console.log('fetching context')
  if(!id)
    return yield put({
      type: FETCHING_CONTEXT_FAILED
    })
  yield put({
    type: FETCHING_CONTEXT
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchContext, id, token)
  if(response.error) {
    yield put({
      type: FETCHING_CONTEXT_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: FETCHED_CONTEXT,
      data: response
    })
  }
}

function* createContext({ context_id, context }) {
  yield put({
    type: CREATING_CONTEXT
  })
  const { member_ids } = context
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.createContext, context_id, context, token)
  console.log(response)
  if(response.error) {
    return yield put({
      type: CREATING_CONTEXT_FAILED,
      error: response.error
    })
  } else if(context.type !== 'milestone') {
    const { id, admin_ids } = response
    const members_response = yield updateMembers({
      context_id: id,
      member_ids: member_ids ? member_ids : response.member_ids,
      admin_ids
    })
    console.log(members_response)
    if(members_response.error) {
      return yield put({
        type: CREATING_CONTEXT_FAILED,
        error: response.error
      })
    }
  }
  yield put({
    type: CREATED_CONTEXT,
    data: {
      ...response,
      member_ids: member_ids ? member_ids : undefined
    }
  })
}

function* updateContext({ id, context }) {
  yield put({
    type: UPDATING_CONTEXT
  })
  const { member_ids, admin_ids } = context
  console.log(context)
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.updateContext, id, context, token)
  if(response.error) {
    return yield put({
      type: UPDATING_CONTEXT_FAILED,
      error: response.error
    })
  } else if(context.type !== 'milestone') {
    // const { admin_ids } = response
    const members_response = yield updateMembers({
      context_id: id,
      member_ids,
      admin_ids
    })
    if(members_response.error) {
      return yield put({
        type: UPDATING_CONTEXT_FAILED,
        error: response.error
      })
    }
  }
  yield put({
    type: UPDATED_CONTEXT,
    data: {
      ...response,
      admin_ids: admin_ids ? admin_ids : undefined,
      member_ids: member_ids ? member_ids : undefined,
    }
  })
}

function* updateMembers({ context_id, member_ids, admin_ids }) {
  yield put({
    type: UPDATING_MEMBERS
  })
  // console.log('UNION',member_ids,admin_ids)
  const members = union(member_ids,admin_ids).reduce((arr,id)=> {
    if(id)
      arr.push({
        id,
        is_admin: admin_ids.indexOf(id) !== -1
      })
    return arr
  },[])
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.updateMembers, context_id, members, token)
  if(response.error) {
    yield put({
      type: UPDATING_MEMBERS_FAILED,
      error: response.error
    })
    return { error: response.error }
  } else {
    yield put({
      type: UPDATED_MEMBERS,
      data: {
        ...response
      }
    })
    return response
  }
}

function* deleteContext({ context_id, callback }) {
  yield put({
    type: DELETING_CONTEXT
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.deleteContext, context_id, token)
  if(response.error) {
    yield put({
      type: DELETING_CONTEXT_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: DELETED_CONTEXT,
      data: context_id
    })
    if(callback)
      callback(context_id)
  }
}

export default function* contexts(App) {
  yield all([
    takeEvery(FETCH_CONTEXTS,fetchContexts),
    takeEvery(FETCH_CONTEXT,fetchContext),
    takeEvery(CREATE_CONTEXT,createContext),
    takeEvery(UPDATE_CONTEXT,updateContext),
    takeEvery(UPDATE_MEMBERS,updateMembers),
    takeEvery(DELETE_CONTEXT,deleteContext),
  ])
}