import React from 'react'

import Button from '|/helpers/Button'

import {
  ISSUE_REPO_URL
} from '|/settings'

import styles from './main.styl'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.errorRef = undefined
    this.state = {
      error: undefined,
      errorInfo: undefined,
      copied: false,
    }
  }

  static getDerivedStateFromError(error,errorInfo) {
    return {
      error,
      errorInfo,
    }
  }

  componentDidCatch(error,errorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  select(el) {
    const selection  = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(el)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  deselect() {
    const selection  = window.getSelection()
    selection.removeAllRanges()
  }

  _onCopyClick(e) {
    try {
      this.select(this.errorRef)
      document.execCommand('copy')
      this.deselect()
      this.setState({
        copied: true
      })
    } catch(e) {

    }
  }

  render() {
    if(this.state.errorInfo)
      return (
        <div className={ styles.container }>
          <div
            className={ styles.error }
            ref={ el => this.errorRef = el }
          >
            <pre className={ styles.details }>{ '```' }</pre>
            <h1>{this.state.error.toString()}</h1>
            <pre className={ styles.details }>{ this.state.errorInfo.componentStack+'\n```' }</pre>
          </div>
          <pre className={ styles.guide }>
            <Button
              onClick={ this._onCopyClick.bind(this) }
              kind={ this.state.copied ? 'constructive' : '' }
              disabled={ this.state.copied }
            >
              { this.state.copied ? `Copied!` : `Click here` }
            </Button>
            to copy this error, and paste it when <a href={ ISSUE_REPO_URL } target="_blank">creating an issue</a>.
          </pre>
        </div>
      )
    return this.props.children
  }
}