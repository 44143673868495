// VIDEO
export const NA = 'NA'
export const UPLOADING = 'UPLOADING'
export const INSTANTIATED = 'INSTANTIATED'
export const RUNNING = 'RUNNING'
export const SUCCEEDED = 'SUCCEEDED'
export const PAUSED = 'PAUSED'
export const FAILED = 'FAILED'
export const STOPPED = 'STOPPED'

// AUTH
export const CODE_SIGNIN = 'CODE_SIGNIN'
export const ACTIVATE = 'ACTIVATE'
export const ACTIVATING = 'ACTIVATING'
export const AUTHENTICATE = 'authenticate'
export const AUTHENTICATING = 'authenticating'
export const AUTHENTICATED = 'authenticated'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN'
export const REFRESH_TOKEN_SENT = 'REFRESH_TOKEN_SENT'
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED'
export const SET_REDIRECT = 'SET_REDIRECT'
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT'
export const LOG_IN = 'log_in'
export const LOGGING_IN = 'logging_in'
export const LOGGED_IN = 'logged_in'
export const LOG_OUT = 'log_out'
export const LOGGED_OUT = 'logged_out'
export const GO_OFFLINE = 'go_offline'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESETTING_PASSWORD = 'RESETTING_PASSWORD'
export const RESET_PASSWORD_SENT = 'RESET_PASSWORD_SENT'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SETTING_PASSWORD = 'SETTING_PASSWORD'
export const SET_PASSWORD_SENT = 'SET_PASSWORD_SENT'
export const SET_PASSWORD_FAILED = 'SET_PASSWORD_FAILED'

// ATTACHMENTS
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT'
export const ADDING_ATTACHMENT = 'ADDING_ATTACHMENT'
export const ADDED_ATTACHMENT = 'ADDED_ATTACHMENT'
export const ADDED_ATTACHMENT_FAILED = 'ADDED_ATTACHMENT_FAILED'
export const SIGN_ATTACHMENT = 'SIGN_ATTACHMENT'
export const SIGNING_ATTACHMENT = 'SIGNING_ATTACHMENT'
export const SIGNED_ATTACHMENT = 'SIGNED_ATTACHMENT'
export const SIGNING_ATTACHMENT_FAILED = 'SIGNING_ATTACHMENT_FAILED'
export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT'
export const UPLOADING_ATTACHMENT = 'UPLOADING_ATTACHMENT'
export const UPLOADED_ATTACHMENT = 'UPLOADED_ATTACHMENT'
export const UPLOADING_ATTACHMENT_FAILED = 'UPLOADING_ATTACHMENT_FAILED'

// CONTEXTS
export const FETCH_CONTEXTS = 'FETCH_CONTEXTS'
export const FETCHING_CONTEXTS = 'FETCHING_CONTEXTS'
export const FETCHED_CONTEXTS = 'FETCHED_CONTEXTS'
export const FETCHING_CONTEXTS_FAILED = 'FETCHING_CONTEXTS_FAILED'
export const CLEAR_CONTEXTS_ERRORS = 'CLEAR_CONTEXTS_ERRORS'

// CONTEXT
export const CREATE_CONTEXT = 'CREATE_CONTEXT'
export const CREATING_CONTEXT = 'CREATING_CONTEXT'
export const CREATED_CONTEXT = 'CREATED_CONTEXT'
export const CREATING_CONTEXT_FAILED = 'CREATING_CONTEXT_FAILED'
export const FETCH_CONTEXT = 'FETCH_CONTEXT'
export const FETCHING_CONTEXT = 'FETCHING_CONTEXT'
export const FETCHED_CONTEXT = 'FETCHED_CONTEXT'
export const FETCHING_CONTEXT_FAILED = 'FETCHING_CONTEXT_FAILED'
export const SEARCH_CONTEXT = 'SEARCH_CONTEXT'
export const SEARCHING_CONTEXT = 'SEARCHING_CONTEXT'
export const SEARCHED_CONTEXT = 'SEARCHED_CONTEXT'
export const SEARCHING_CONTEXT_FAILED = 'SEARCHING_CONTEXT_FAILED'
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT'
export const UPDATING_CONTEXT = 'UPDATING_CONTEXT'
export const UPDATED_CONTEXT = 'UPDATED_CONTEXT'
export const UPDATING_CONTEXT_FAILED = 'UPDATING_CONTEXT_FAILED'
export const DELETE_CONTEXT = 'DELETE_CONTEXT'
export const DELETING_CONTEXT = 'DELETING_CONTEXT'
export const DELETED_CONTEXT = 'DELETED_CONTEXT'
export const DELETING_CONTEXT_FAILED = 'DELETING_CONTEXT_FAILED'

// DIGESTS
export const FETCH_DIGESTS = 'FETCH_DIGESTS'
export const FETCHING_DIGESTS = 'FETCHING_DIGESTS'
export const FETCHED_DIGESTS = 'FETCHED_DIGESTS'
export const FETCHING_DIGESTS_FAILED = 'FETCHING_DIGESTS_FAILED'
export const CLEAR_DIGESTS_ERRORS = 'CLEAR_DIGESTS_ERRORS'

// DIGEST
export const CREATE_DIGEST = 'CREATE_DIGEST'
export const CREATING_DIGEST = 'CREATING_DIGEST'
export const CREATED_DIGEST = 'CREATED_DIGEST'
export const CREATING_DIGEST_FAILED = 'CREATING_DIGEST_FAILED'
export const UPDATE_DIGEST = 'UPDATE_DIGEST'
export const UPDATING_DIGEST = 'UPDATING_DIGEST'
export const UPDATED_DIGEST = 'UPDATED_DIGEST'
export const UPDATING_DIGEST_FAILED = 'UPDATING_DIGEST_FAILED'
export const DELETE_DIGEST = 'DELETE_DIGEST'
export const DELETING_DIGEST = 'DELETING_DIGEST'
export const DELETED_DIGEST = 'DELETED_DIGEST'
export const DELETING_DIGEST_FAILED = 'DELETING_DIGEST_FAILED'

// POSTS
export const FETCH_POSTS = 'FETCH_POSTS'
export const FETCHING_POSTS = 'FETCHING_POSTS'
export const FETCHED_POSTS = 'FETCHED_POSTS'
export const FETCHING_POSTS_FAILED = 'FETCHING_POSTS_FAILED'
export const SEARCH_POSTS = 'SEARCH_POSTS'
export const SEARCHING_POSTS = 'SEARCHING_POSTS'
export const SEARCHED_POSTS = 'SEARCHED_POSTS'
export const SEARCHING_POSTS_FAILED = 'SEARCHING_POSTS_FAILED'
export const SEARCH_NEXT_POSTS = 'SEARCH_NEXT_POSTS'
export const SEARCHING_NEXT_POSTS = 'SEARCHING_NEXT_POSTS'
export const SEARCHED_NEXT_POSTS = 'SEARCHED_NEXT_POSTS'
export const SEARCHING_NEXT_POSTS_FAILED = 'SEARCHING_NEXT_POSTS_FAILED'
export const CLEAR_POSTS_ERRORS = 'CLEAR_POSTS_ERRORS'

// POST
export const CREATE_POST = 'CREATE_POST'
export const CREATE_COMMENT = 'CREATE_COMMENT'
export const CREATING_POST = 'CREATING_POST'
export const CREATED_POST = 'CREATED_POST'
export const CREATING_POST_FAILED = 'CREATING_POST_FAILED'
export const UPDATE_POST = 'UPDATE_POST'
export const UPDATING_POST = 'UPDATING_POST'
export const UPDATED_POST = 'UPDATED_POST'
export const UPDATING_POST_FAILED = 'UPDATING_POST_FAILED'
export const DELETE_POST = 'DELETE_POST'
export const DELETING_POST = 'DELETING_POST'
export const DELETED_POST = 'DELETED_POST'
export const DELETING_POST_FAILED = 'DELETING_POST_FAILED'
export const FETCH_POST = 'FETCH_POST'
export const FETCHING_POST = 'FETCHING_POST'
export const FETCHED_POST = 'FETCHED_POST'
export const FETCHING_POST_FAILED = 'FETCHING_POST_FAILED'

// EXPORT
export const CREATE_EXPORT = 'CREATE_EXPORT'
export const CREATING_EXPORT = 'CREATING_EXPORT'
export const CREATED_EXPORT = 'CREATED_EXPORT'
export const CREATING_EXPORT_FAILED = 'CREATING_EXPORT_FAILED'
export const FETCH_EXPORT = 'FETCH_EXPORT'
export const FETCHING_EXPORT = 'FETCHING_EXPORT'
export const FETCHED_EXPORT = 'FETCHED_EXPORT'
export const FETCHING_EXPORT_FAILED = 'FETCHING_EXPORT_FAILED'
export const CLEAR_EXPORT = 'CLEAR_EXPORT'
export const CLEARING_EXPORT = 'CLEARING_EXPORT'
export const CLEARED_EXPORT = 'CLEARED_EXPORT'
export const CLEARING_EXPORT_FAILED = 'CLEARING_EXPORT_FAILED'
export const CLEAR_EXPORTS_ERRORS = 'CLEAR_EXPORTS_ERRORS'

// TAGS
export const FETCH_TAGS = 'FETCH_TAGS'
export const FETCHING_TAGS = 'FETCHING_TAGS'
export const FETCHED_TAGS = 'FETCHED_TAGS'
export const FETCHING_TAGS_FAILED = 'FETCHING_TAGS_FAILED'
export const CLEAR_TAGS_ERRORS = 'CLEAR_TAGS_ERRORS'

// USERS
export const FETCH_USERS = 'FETCH_USERS'
export const FETCHING_USERS = 'FETCHING_USERS'
export const FETCHED_USERS = 'FETCHED_USERS'
export const FETCHING_USERS_FAILED = 'FETCHING_USERS_FAILED'
export const CLEAR_USERS_ERRORS = 'CLEAR_USERS_ERRORS'

// USER
export const FETCH_USER = 'FETCH_USER'
export const FETCHING_USER = 'FETCHING_USER'
export const FETCHED_USER = 'FETCHED_USER'
export const FETCHING_USER_FAILED = 'FETCHING_USER_FAILED'

// CURRENT USER
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const FETCHING_CURRENT_USER = 'FETCHING_CURRENT_USER'
export const FETCHED_CURRENT_USER = 'FETCHED_CURRENT_USER'
export const FETCHING_CURRENT_USER_FAILED = 'FETCHING_CURRENT_USER_FAILED'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATING_CURRENT_USER = 'UPDATING_CURRENT_USER'
export const UPDATED_CURRENT_USER = 'UPDATED_CURRENT_USER'
export const UPDATING_CURRENT_USER_FAILED = 'UPDATING_CURRENT_USER_FAILED'

// INVITES
export const INVITE_USERS = 'INVITE_USERS'
export const INVITING_USERS = 'INVITING_USERS'
export const INVITED_USERS = 'INVITED_USERS'
export const INVITING_USERS_FAILED = 'INVITING_USERS_FAILED'
export const INVITE_USER = 'INVITE_USER'
export const INVITING_USER = 'INVITING_USER'
export const INVITED_USER = 'INVITED_USER'
export const INVITING_USER_FAILED = 'INVITING_USER_FAILED'

// MEMBERS
export const FETCH_MEMBERS = 'FETCH_MEMBERS'
export const FETCHING_MEMBERS = 'FETCHING_MEMBERS'
export const FETCHED_MEMBERS = 'FETCHED_MEMBERS'
export const FETCHING_MEMBERS_FAILED = 'FETCHING_MEMBERS_FAILED'
export const UPDATE_MEMBERS = 'UPDATE_MEMBERS'
export const UPDATING_MEMBERS = 'UPDATING_MEMBERS'
export const UPDATED_MEMBERS = 'UPDATED_MEMBERS'
export const UPDATING_MEMBERS_FAILED = 'UPDATING_MEMBERS_FAILED'