import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'

import {
  FETCH_TAGS,
  FETCHING_TAGS,
  FETCHED_TAGS,
  FETCHING_TAGS_FAILED
} from '|/store/constants'

function* fetchTags({ tag }) {
  yield put({
    type: FETCHING_TAGS
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchTags, tag, token)
  if(response && response.error) {
    yield put({
      type: FETCHING_TAGS_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: FETCHED_TAGS,
      data: response
    })
  }
}

export default function* tags(App) {
  yield all([
    takeEvery(FETCH_TAGS,fetchTags),
  ])
}