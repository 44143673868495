import { useEffect, useState } from 'react'

const storage = {
	setItem(key, val) {
    try {
      return window.localStorage.setItem(key, JSON.stringify(val))
    } catch(e) {
      return undefined
    }
	},
	getItem(key) {
    try{
  		return JSON.parse(window.localStorage.getItem(key))
    } catch(e) {
      return undefined
    }
	},
	removeItem(key) {
    try {
  		return window.localStorage.removeItem(key)
    } catch(e) {
      return undefined
    }
	}
}

export default storage

export const useStorage = (key)=> {

  const [ stateValue, setStateValue ] = useState(undefined)

  useEffect(()=> {
    setStateValue(storage.getItem(key))
  },[])

  useEffect(()=> {
    if(stateValue === undefined) {
      storage.removeItem(key)
    } else {
      storage.setItem(key,stateValue)
    }
  },[ stateValue ])

  return [
    stateValue,
    setStateValue
  ]
}