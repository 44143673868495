import { useEffect, useRef } from 'react'

const useValidation = (value, rules)=> {

  const _validate = (value, rules)=> {
    let is_valid = true

    if(rules.required)
      is_valid = value.trim() !== '' && is_valid

    if(rules.email)
      is_valid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(value) && is_valid

    return is_valid
  }

  return _validate
}

export {
  useValidation,
}