import { createSelector } from 'reselect'

const postsSelector = state => state.posts.saved

const getSavedPost = (post)=> {
  return post
}

export default createSelector(
  [postsSelector],
  getSavedPost
)