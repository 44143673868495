import { useEffect, useRef } from 'react'

const useInterval = (callback, delay)=> {

  const _callback = useRef()

  useEffect(()=> {
    _callback.current = callback
  },[ callback ])

  useEffect(()=> {
    const tick = ()=> {
      _callback.current()
    }
    if(delay !== null) {
      let int = setInterval(tick,delay)
      return ()=> clearInterval(int)
    }
  },[ delay ])
}

export {
  useInterval,
}