import React from 'react'
import PropTypes from 'prop-types'

const Textarea = props => {

  const _onChange = (e)=> {
    const value = e.target.value
    props.onChange(props.name,value)
  }

  const _onFocus = (e)=> {
    props.onFocus(e)
  }

  const _onBlur = (e)=> {
    props.onBlur(e)
  }

  return (
    <div>
      <textarea
        { ...props.config }
        name={ props.name }
        value={ props.value }
        onChange={ _onChange }
        onFocus={ _onFocus }
        onBlur={ _onBlur }
      />
    </div>
  )
}

Textarea.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

Textarea.defaultProps = {
  name: '',
  onChange: ()=> {},
  onFocus: ()=> {},
  onBlur: ()=> {},
}

export default Textarea