import React, { Fragment, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getContext } from '|/store/contexts/selectors'
import { LeftEditorContext } from '|/contexts/LeftEditor'
import { useQuery } from '|/hooks/pathState'
import { Fa } from '|/icons'

import * as types from './types'
import Button from '|/helpers/Button'
import SelectButton from '|/helpers/SelectButton'
import Plink from '|/helpers/Plink'

import styles from './main.styl'

import {
  FETCH_CONTEXT,
  FETCH_MEMBERS,
} from '|/store/constants'

const Context = ({ context })=> {

  const dispatch = useDispatch()
  const parent = useSelector( state => getContext(state, context.parent_context_id))
  const { record, setRecord, setMinimised } = useContext(LeftEditorContext)
  const { context_ids } = useQuery()
  const [ checked_ids, setChecked_ids ] = useState([])

  const _handleEditOnClick = (e)=> {
    setRecord(context)
    setMinimised(false)
  }

  useEffect(()=> {
    dispatch({
      type: FETCH_CONTEXT,
      id: context.parent_context_id
    })
    dispatch({
      type: FETCH_MEMBERS,
      id: context.parent_context_id || context.id
    })
  },[])

  useEffect(()=> {
    if(!isEqual(context_ids,checked_ids))
      setChecked_ids(Array.isArray(context_ids) ? context_ids : [context_ids])
  }, [ context_ids ])

  const Type = types[context.type.capitalise()]

  if(!context) return null
  return (
    <Fragment>
      <div className={ styles.header }>
        <Plink
          className={ styles.back }
          to={{
            left: {
              c: context.parent_context_id || 0
            },
            search: {
              main_context_id: context.parent_context_id || undefined
            }
          }}
        >
          { parent ?
              <Button
                material={ false }
                link={ true }
              >
                <Fa icon="arrow-left" />
                { parent.title }
              </Button>
            :
              <Button
                material={ false }
                link={ true }
              >
                <Fa className="icon" icon="arrow-left" />
                { 'All projects' }
              </Button>
          }
        </Plink>
        {(
            (context.type !== 'milestone' && context.is_admin) || 
            (context.type === 'milestone' && parent && parent.is_admin)
          ) &&
          <Button
            onClick={ _handleEditOnClick }
            disabled={ record && record.id !== context.id }
          >
            Edit
          </Button>
        }
      </div>
      <h2 className={ styles.title }>
        <span>
          { context.title }
        </span>
        <Plink
          className={ styles.filter }
          to={{
            search: {
              toggle: {
                context_ids: context.id
              }
            }
          }}
        >
          <SelectButton
            selected={ checked_ids.indexOf(context.id) !== -1 }
          />
        </Plink>
      </h2>
      <Type context={ context } />
    </Fragment>
  )
}

Context.propTypes = {
  context: PropTypes.object
}

export default Context