import { createSelector } from 'reselect'
import { search2array } from '|utils'

const search = state => state.router.location.search

const getParamsAsArray = (search)=> {
  return search2array(search)
}

export default createSelector(
  [search],
  getParamsAsArray
)