import { createSelector } from 'reselect'

const postsSelector = state => state.posts.list
const idSelector = (state,id)=> id

const getPost = (posts,id)=> {
  return posts[id]
}

export default createSelector(
  [postsSelector,idSelector],
  getPost
)