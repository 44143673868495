import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route } from 'react-router'
import { history } from '|/store'

import { renderChildrenWithProps } from '|/utils'

import {
  SET_REDIRECT
} from '|/store/constants'

const AuthRoute = ({ children, ...props })=> {
  
  const [ pathname, setPathname ] = useState('')
  const dispatch = useDispatch()
  const { authenticated, authenticating } = useSelector( state => state.auth )

  useEffect(()=> {
    setPathname(history.location.pathname)
  }, [])

  useEffect(()=> {
    if(!authenticated && !authenticating) {
      console.log(history)
      console.log('set redirect:',history.location)
      dispatch({
        type: SET_REDIRECT,
        redirectTo: pathname
      })
    }
  }, [ authenticated, authenticating ])

  return (
    <Route { ...props }>
      { !authenticating && !authenticated ?
          <Redirect to="/login" />
        :
          authenticated ?
            renderChildrenWithProps(children, { ...props })
          :
            null
      }
    </Route>
  )
}

export default AuthRoute