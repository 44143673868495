import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import { useParams } from '|/hooks/router'
import { renderChildrenWithProps } from '|/utils'

import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'

import styles from './main.styl'

const Panel = ({ children, ...props })=> {
  
  const [ status, setStatus ] = useState(false)
  const [ root, setRoot ] = useState(false)
  const params = useParams()

  // useEffect(()=> {
  //   const nextRoot = params.root === 
  // },[params.root])

  useEffect(()=> {
    setStatus(params[`${props.side}_status`])
  }, [ params[`${props.side}_status`] ])

  console.log(props.side, styles[status])

  return (
    <div
      className={ [styles.container, props.side, styles[status]].join(' ') }
    >
      <div className={ styles.content }>
        { renderChildrenWithProps(children,{...props}) }
      </div>
      <Plink
        seo={ true }
        className={ styles.toggler }
        to={{
          [props.side]: {
            s: status === 'max' ? 'min' : 'max'
          }
        }}
      >
        <Button
          material={ false }
          round={ true }
          className={ styles.arrow }
        >
          <Fa icon="chevron-right" />
        </Button>
      </Plink>
    </div>
  )
}

Panel.propTypes = {
  expandedIfRoot: PropTypes.array,
  side: PropTypes.string
}

Panel.defaultProps = {
  expandedIfRoot: [],
  side: 'left'
}

export default Panel