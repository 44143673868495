import { createSelector } from 'reselect'

const contextsSelector = state => state.contexts.saved

const getSavedContext = (context)=> {
  return context
}

export default createSelector(
  [contextsSelector],
  getSavedContext
)