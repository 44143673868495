import React, { useEffect, useContext, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getDigestsByContext } from '|/store/digests/selectors'
import { getContext } from '|/store/contexts/selectors'

import { LeftEditorContext } from '|/contexts/LeftEditor'

import DigestsList from './DigestsList'
import Button from '|/helpers/Button'

import styles from './main.styl'

import {
  FETCH_DIGESTS
} from '|/store/constants'

const Digest = ({ context })=> {

  const dispatch = useDispatch()
  const digests = useSelector( state => getDigestsByContext(state, context.id))
  const mainContext = useSelector( state => getContext(state, context.parent_context_id) )
  const { record, setRecord, minimised, setMinimised } = useContext(LeftEditorContext)

  useEffect(()=> {
    dispatch({
      type: FETCH_DIGESTS,
      id: context.id
    })
  },[])

  const _handleOnNewClick = ()=> {
    setRecord({
      context_id: context.id,
      type: 'digest'
    })
  }

  return (
    <Fragment>
      <DigestsList context={ context } mainContext={ mainContext } digests={ digests } />
      <div className={ styles.footer }>
        { mainContext && mainContext.is_member &&
          <Button
            kind="constructive"
            onClick={ _handleOnNewClick }
            disabled={ record }
          >{ `Create new digest +` }</Button>
        }
      </div>
    </Fragment>
  )
}

export default Digest