import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { history } from '|/store'
import { getUsers, getUsersByIds, getUsersByRole } from '|/store/users/selectors'
import { useValidation } from '|/hooks/validation'

import { Fa } from '|/icons'
import Label from '|/helpers/Label'
import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import ConfirmButton from '|/helpers/ConfirmButton'
import Input, { checkValidity } from '|/helpers/Input'

import {
  FETCH_USERS,
  INVITE_USER,
} from '|/store/constants'

import styles from './main.styl'

const UsersForm = ({ user, onCancel })=> {

  const dispatch = useDispatch()
  const validate = useValidation()
  const [ saving, setSaving ] = useState(false)
  const [ form_is_valid, setForm_is_valid ] = useState(false)
  const teachers = useSelector( state => getUsersByRole(state,'teacher') )
  const students = useSelector( state => getUsersByRole(state,'student') )
  const [ control, setControl ] = useState({
    type: 'MultiSearchSelect',
    label: 'Add teachers',
    config: {
      placeholder: 'Search for name or email...',
      filter_attributes: [
        'firstname',
        'lastname',
        'email',
        'usable_name',
      ]
    },
    value: [],
    options: [],
    touched: false,
    canInvite: true,
    remove: false,
  })

  const _onChange = (key, value)=> {
    setControl({
      ...control,
      value,
      // valid: checkValidity(e.target.value, controls[controlName].validation),
      touched: true,
    })
  }

  const _onInviteClick = ({ email })=> {
    dispatch({
      type: INVITE_USER,
      email,
      role: 'teacher',
      callback: _onMemberInvited
    })
  }

  const _onRemoveClick = ({ email })=> {
    dispatch({
      type: INVITE_USER,
      email,
      role: 'student',
      callback: _onMemberInvited
    })
  }

  const _onMemberInvited = ({ id })=> {
    _onChange('teachers',control.value.with(id))
  }

  const _onSubmit = (e) => {
    // e.preventDefault()
    // setSaving(true)
    // const emails = controls.teachers.padded_value.reduce((arr,v)=> arr.push(v.email),[])
    // dispatch({
    //   type: INVITE_USERS,
    //   emails,
    //   role: 'teacher',
    // })
  }

  useEffect(()=>{
    dispatch({
      type: FETCH_USERS
    })
  },[])

  return (
    <form onSubmit={ _onSubmit } autoComplete="false">
      <Input
        name={ control.name }
        {...{
          ...control,
          ...{
            options: students,
            padded_value: teachers,
            inviteRenderer: (value)=> {
              const is_email = validate(value,{email:true})
              console.log('IS EMAIL',is_email)
              if(is_email)
                return <Button
                  size="small"
                  kind="constructive"
                  className={ styles.invite }
                  onClick={ ()=> _onInviteClick({ email:value }) }
                >Invite</Button>
            },
            valueRenderer: (value)=> (
              <Fragment>
                <div className={ styles.value }>{ value.usable_name }</div>
                { (!value.is_verified) &&
                  <Button
                    size="small"
                    onClick={ ()=> _onInviteClick(value) }
                  >Resend invitation</Button>
                }
              </Fragment>
            ),
            removeRenderer: (value)=> (
              <ConfirmButton
                className={ styles.remove }
                size="small"
                kind="destructive"
                onClick={ e => _onRemoveClick(value) }
              >
                Demote
              </ConfirmButton>
            ),
            optionRenderer: ({ opt, ...props })=> (
              <Button
                { ...props }
                material={ false }
                className={ styles.option }
              >Promote { opt.usable_name }</Button>
            ),
            addHandler: _onInviteClick,
          },
        }}
        onChange={ _onChange }
      />
    </form>
  )
}


export default UsersForm