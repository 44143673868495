import moment from 'moment'
import { createSelector } from 'reselect'

const postsSelector = state => state.posts.list
const searchSelector = (state)=> state.posts.search_result_ids

const getSearchResultsTimeline = createSelector(
  [postsSelector,searchSelector],
  (posts,search_result_ids)=> {
    console.log(search_result_ids)
    const psts = search_result_ids
      .map( id => posts[id] )
    console.log(psts)
    const days = psts.reduce((obj,p)=> {
        const date = moment(p.created_on).startOf('day').unix()
        return {
          ...obj,
          [date]: [
            ...(obj[date] ? obj[date] : []),
            p
          ]
        }
      },{})
    console.log(days)
    return days
  }
)

export default getSearchResultsTimeline

export const createGetSearchResultsTimeline = ()=> getSearchResultsTimeline