import { fork, take, all, call, put, takeEvery } from 'redux-saga/effects'
import api from '|/api'
import storage from '|/utils/storage'

import {
  FETCH_MEMBERS,
  FETCHING_MEMBERS,
  FETCHED_MEMBERS,
  FETCHING_MEMBERS_FAILED,
  FETCH_USERS,
  FETCHING_USERS,
  FETCHED_USERS,
  FETCHING_USERS_FAILED,
  FETCH_USER,
  FETCHING_USER,
  FETCHED_USER,
  FETCHING_USER_FAILED,
  UPDATE_CURRENT_USER,
  UPDATING_CURRENT_USER,
  UPDATED_CURRENT_USER,
  UPDATING_CURRENT_USER_FAILED,
  INVITE_USER,
  INVITING_USER,
  INVITED_USER,
  INVITING_USER_FAILED,
  INVITE_USERS,
  INVITING_USERS,
  INVITED_USERS,
  INVITING_USERS_FAILED,
} from '|/store/constants'

function* fetchMembers({ id }) {
  yield put({
    type: FETCHING_MEMBERS
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchMembers, id, token)
  if(response.error) {
    yield put({
      type: FETCHING_MEMBERS_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: FETCHED_USERS,
      data: response
    })
  }
}

function* fetchUsers() {
  yield put({
    type: FETCHING_USERS
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchUsers, token)
  if(response.error) {
    yield put({
      type: FETCHING_USERS_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: FETCHED_USERS,
      data: response
    })
  }
}

function* fetchUser({ user_id }) {
  yield put({
    type: FETCHING_USER
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.fetchUser, user_id, token)
  if(response.error) {
    yield put({
      type: FETCHING_USER_FAILED,
      error: response.error
    })
  } else {
    yield put({
      type: FETCHED_USER,
      data: response
    })
  }
}

function* inviteUser({ email, role, callback }) {
  console.log('EMAIL',email)
  yield put({
    type: INVITING_USER
  })
  let token = yield call(storage.getItem,'token')
  const response = yield call(api.inviteUser, email, role, token)
  if(response.error) {
    yield put({
      type: INVITING_USER_FAILED,
      error: response.error
    })
    return response.error
  } else {
    yield put({
      type: INVITED_USER,
      data: response
    })
    if(callback) callback(response)
    return response
  }
}

function* inviteUsers({ emails, role, callback }) {
  yield put({
    type: INVITING_USERS
  })
  const invited_users = yield all(emails.map(function(email) {
    return call(inviteUser,email,role)
  }))
  if(!invited_users) {
    yield put({
      type: INVITING_USERS_FAILED,
      error: "Failed to invite users",
    })
  } else {
    yield put({
      type: INVITED_USERS,
      data: invited_users
    })
    if(callback) callback(response)
  }
}

function* updateCurrentUser({ user }) {
  yield put({
    type: UPDATING_CURRENT_USER
  })
  let token = yield call(storage.getItem,'token')
  const { error, ...response } = yield call(api.updateCurrentUser, user, token)
  if(error) {
    yield put({
      type: UPDATING_CURRENT_USER_FAILED,
      error: error
    })
  } else {
    yield put({
      type: UPDATED_CURRENT_USER,
      data: response
    })
  }
}

export default function* users(App) {
  yield all([
    takeEvery(FETCH_MEMBERS,fetchMembers),
    takeEvery(FETCH_USERS,fetchUsers),
    takeEvery(FETCH_USER,fetchUser),
    takeEvery(INVITE_USER,inviteUser),
    takeEvery(INVITE_USERS,inviteUsers),
    takeEvery(UPDATE_CURRENT_USER,updateCurrentUser),
  ])
}