import { createSelector } from 'reselect'

const facetSelector = (state,facet)=> state.posts.facets[facet]
const idSelector = (state,facet,id)=> id

const getFacetCount = createSelector(
  [facetSelector,idSelector],
  (facet,id)=> {
    let count = 0
    if(facet) {
      if(Array.isArray(id)) {
        count = facet.buckets
          .reduce((cnt,bucket)=> {
            if(id.indexOf(bucket.key) !== -1)
              cnt += bucket.doc_count
            return cnt
          },0)
      } else {
        count = facet.buckets
          .reduce((cnt,bucket)=> {
            if(bucket.key == id) {
              cnt = bucket.doc_count
            }
            return cnt
          },0)
      }
    }
    return count
})

export default getFacetCount

export const createGetFacetCount = ()=> getFacetCount