import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { history } from '|/store'

import {
  LOG_IN,
  CLEAR_REDIRECT,
  RESET_PASSWORD,
} from '|/store/constants'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import Input from '|/helpers/Input'

import styles from './main.styl'

const LoginForm = props => {

  const dispatch = useDispatch()
  const { authenticated, authenticating, error, info, redirectTo } = useSelector( state => state.auth )
  const [ loading, setLoading ] = useState(false)
  const [ controls, setControls ] = useState({
    email: {
      type: 'Input',
      label: 'Email',
      config: {
        type: 'email',
        placeholder: 'Email address',
        'data-cy': 'emailField',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      touched: false,
      onChange: _onChange,
    },
    password: {
      type: 'Input',
      label: 'Password',
      config: {
        type: 'password',
        placeholder: 'Password',
        'data-cy': 'passwordField',
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      onChange: _onChange,
    },
  })

  const _onChange = (key, value)=> {
    const new_controls = {
      ...controls,
    }
    new_controls[key] = {
      ...controls[key],
      value,
      // valid: checkValidity(e.target.value, controls[controlName].validation),
      touched: true,
    }
    setControls(new_controls)
  }

  const _onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = {}
    for(let key in controls) {
      formData[key] = controls[key].value
    }
    dispatch({
      type: LOG_IN,
      ...formData
    })
  }

  const _resetPassword = (e) => {
    setLoading(true)
    const email = controls.email.value
    dispatch({
      type: RESET_PASSWORD,
      email,
    })
  }

  const formElements = []
  for(let key in controls) {
    formElements.push({
      key,
      control: controls[key],
    })
  }

  useEffect(()=> {
    if(authenticated) {
      console.log('redirect:',redirectTo)
      history.replace(redirectTo)
      dispatch({
        type: CLEAR_REDIRECT
      })
    }
  }, [ authenticated ])

  return (
    <Overlay
      title={ `Log in` }
      disableClose={ true }
    >
      <div className={ styles.content }>
        { info &&
          <div className="info">{ info }</div>
        }
        { error &&
          <div className="error">{ error }</div>
        }
        <form onSubmit={ _onSubmit }>
          { formElements.map(({ key, control })=> <Input
                key={ key }
                name={ key }
                { ...control }
                onChange={ _onChange }
              /> ) }
          <Button
            type="submit"
            data-cy="submitButton"
          >Log in</Button>
        </form>
        <div className={ styles.footer }>
          <Button
            link={ true }
            material={ false }
            onClick={ _resetPassword }
            data-cy="resetPasswordButton"
          >
            {`Reset password`}
          </Button>
        </div>
      </div>
    </Overlay>
  )
}


export default LoginForm