import React from 'react'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import Button from '|/helpers/Button'

import styles from './main.styl'

const SelectButton = ({ selected, hasSelected, className, children, ...props })=> {
  const classList = [ styles.container, className ]
  if(selected) classList.push('active')
  if(hasSelected) classList.push(styles.hasSelected)
  return (
    <Button
      { ...props }
      className={ classList.join(' ') }
      material={ false }
      round={ true }
    >
      { selected || hasSelected ?
          <Fa icon="check-circle" />
        :
          <Fa icon="circle" />
      }
    </Button>
  )
}

SelectButton.propTypes = {
  selected: PropTypes.bool,
  hasSelected: PropTypes.bool,
}

SelectButton.defaultProps = {
  selected: false,
  hasSelected: false,
}

export default SelectButton