import { createSelector } from 'reselect'

const tagsSelector = state => state.tags.list

const getTagsByParents = (tags)=> {
  return Object.values(tags)
    .filter( tag => !tag.parent_tag_id )
    .reduce((obj,tag)=> {
      obj[tag.name] = Object.values(tags).filter( t => t.parent_tag_id === tag.id )
      return obj
    },{})
}

export default createSelector(
  [tagsSelector],
  getTagsByParents
)