import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import TagsListItem from './TagsListItem'

import styles from './main.styl'

const TagsList = ({ tags })=> {

  return (
    <Fragment>
      { tags.map((tag)=>
        <TagsListItem
          key={ `tag-${tag.id}` }
          tag={ tag }
        />
      )}
    </Fragment>
  )
}

TagsList.propTypes = {
  groups: PropTypes.object
}

export default TagsList