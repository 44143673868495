import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { getExports } from '|/store/exports/selectors'

import Export from './Export'

import styles from './main.styl';

const Exports = props => {

  const exports = useSelector( state => getExports(state) )
  console.log(exports)

  return (
    <div className={ styles.container }>
      { exports.map( exp =>
        <Export key={`export-${exp.id}`} id={ exp.id } />
      )}
    </div>
  );
}

export default Exports