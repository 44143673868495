import moment from 'moment'
import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHash } from '|/hooks/router'
import { useScrollTo, useInfiniteScroll } from '|/hooks/scroller'
import { useQuery } from '|/hooks/pathState'

import PostsList from './PostsList'
import { getSearchStringAsObject } from '|/store/router/selectors'
import { getNext, getSearchResultsTimeline, getSearchResultCount } from '|/store/posts/selectors'
import { getContext } from '|/store/contexts/selectors'

import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'
import Loader from '|/helpers/Loader'

import FilterList from './FilterList'

import { PostContext } from '|/contexts/Post'

import styles from './main.styl'

import {
  SEARCH_POSTS,
  SEARCH_NEXT_POSTS,
  CREATE_EXPORT,
} from '|/store/constants'

const Posts = (props)=> {
  
  const dispatch = useDispatch()
  const params = useParams()
  const hash = useHash()
  const { main_context_id } = useQuery()
  const [ layout, setLayout ] = useState('grid')
  const [ loading, setLoading ] = useState(true)
  const [ prev_search_result_count, setPrev_search_result_count ] = useState(0)
  const { editing, didSave } = useContext(PostContext)
  const [ listenForPostChange, setListenForPostChange ] = useState(false)
  const query = useSelector( state => getSearchStringAsObject(state) )
  const days = useSelector( state => getSearchResultsTimeline(state) )
  const search_result_count = useSelector( state => getSearchResultCount(state) )
  const context = useSelector( state => getContext(state,params.context_id) )
  const mainContext = useSelector( state => getContext(state,main_context_id) )
  const next = useSelector( state => getNext(state) )
  
  const _fetchPosts = ()=> {
    if(next) {
      setLoading(true)
      dispatch({
        type: SEARCH_NEXT_POSTS,
        url: next
      })
    }
  }
  
  const [ hasReachedEnd, setHasReachedEnd ] = useInfiniteScroll(_fetchPosts)
  const scrollTo = useScrollTo()

  useEffect(()=> {
    setHasReachedEnd(false)
    setLoading(false)
  }, [ days ])

  useEffect(()=> {
    if(params.center_status !== layout)
      setLayout(params.center_status)
  }, [ params.center_status ])

  useEffect(()=> {
    // if(!hasReachedEnd) {
      setLoading(true)
      dispatch({
        type: SEARCH_POSTS,
        query
      })
    // }
  },[ query ])

  useEffect(()=> {
    //
  },[ hasReachedEnd ])

  useEffect(()=> {
    if(listenForPostChange && didSave) {
      dispatch({
        type: SEARCH_POSTS,
        query
      })
      setListenForPostChange(false)
    }
  },[ didSave ])

  useEffect(()=> {
    if(editing)
      setListenForPostChange(true)
  },[ editing ])

  useEffect(()=> {
    if(hash)
      scrollTo(document.documentElement,document.querySelector(hash),{ offset: -124 })
  },[ hash, days ])

  const _onSearchResultCounterComplete = ()=> {
    setPrev_search_result_count(search_result_count)
  }

  return (
    <div className={ [ styles.container, styles[layout] ].join(' ') }>
      <div className={ styles.posts }>
      { layout === 'timeline' ?
          Object.keys(days).reverse().map( key =>
            <Fragment key={ key }>
              <div className={ styles.date }>{ moment.unix(key).format('dddd, Do MMMM YYYY') }</div>
              <PostsList posts={ days[key] } />
            </Fragment> 
          )
        :
          <PostsList posts={ Object.keys(days).reverse().reduce((arr,key)=> [ ...arr, ...days[key] ] ,[]) } />
      }
      </div>
      { loading &&
        <Loader
          dotCount={ 3 }
          style={{
            top: 'auto',
            padding: '2em',
          }}
        />
      }
      <div className={ styles.header }>
        <FilterList />
        <div className={ styles.toggles }>
          <div className={ styles.toggle }>
            { (search_result_count && layout === 'grid') &&
              <div className={ styles.result_count }>
                { `${search_result_count}` }
              </div>
            }
            <Plink
              to={{
                left: {
                  s: 'min'
                },
                right: {
                  s: 'min'
                },
                center: {
                  s: 'grid'
                }
              }}
            >
              <Button
                material={ false }
                toggle={ true }
                active={ layout === 'grid' }
              >Grid</Button>
            </Plink>
          </div>
          <div className={ styles.toggle }>
            <Plink
              to={{
                left: {
                  s: IS_MOBILE ? 'min' : 'max'
                },
                right: {
                  s: IS_MOBILE ? 'min' : 'max'
                },
                center: {
                  s: 'timeline'
                }
              }}
            >
              <Button
                material={ false }
                toggle={ true }
                active={ layout === 'timeline' }
              >Timeline</Button>
            </Plink>
            { (search_result_count && layout === 'timeline') &&
              <div className={ styles.result_count }>
                { `${search_result_count}` }
              </div>
            }
          </div>
        </div>
      </div>
      <div className={ styles.footer }>
        { context && context.is_member &&
          <Plink
            to={{
              overlay: {
                p: 'NEW'
              }
            }}
          >
            <Button
              kind="constructive"
            >{ `Create new post +` }</Button>
          </Plink>
        }
      </div>
    </div>
  )
}

export default Posts