import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { history } from '|/store'
import { useLocation } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'

const Plink = ({ seo, to, persistQuery, ...link_props })=> {
  
  const location = useLocation()
  const pathState = usePathState()
  const [ href, setHref ] = useState('')

  let nextPathState = {}

  const onClick = (e)=> {
    e.preventDefault()
    if(link_props.onClick)
      link_props.onClick(e)
    else
      history.push(_getHref(),nextPathState)
  }

  const _getHref = ()=> {
    if(typeof to === 'string')
      return to
    else
      return pathState.toString(pathState.merge(to))
  }

  useEffect(()=> {
    if(seo) {
      setHref(_getHref())
    }
  }, [ to, location ])

  return (
    <a
      {...link_props}
      onClick={ onClick }
      href={ href }
    />
  )
}

Plink.propTypes = {
  seo: PropTypes.bool,
  persistQuery: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}

Plink.defaultProps = {
  seo: false,
  persistQuery: false,
}

export default Plink