import React, { Fragment, useMemo, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useParams } from '|/hooks/router'
import { useQuery } from '|/hooks/pathState'
import { createGetFacetCount } from '|/store/posts/selectors/getFacetCount'
import { getTagsByParent } from '|/store/tags/selectors'

import { AccordionContext } from '|/contexts/Accordion'

import Collapse from '@material-ui/core/Collapse'
import ListItem from '|/components/ListItem'
import ExpandButton from '|/helpers/ExpandButton'
import SelectButton from '|/helpers/SelectButton'
import Plink from '|/helpers/Plink'
import TagsList from './TagsList'

import styles from './main.styl'

const TagsListItem = ({ tag })=> {

  const params = useParams()
  const [ expanded, setExpanded ] = useState(false)
  const getFacetCount = useMemo(createGetFacetCount,[])
  const facetCount = useSelector( state => getFacetCount(state,'tag_ids',tag.id))
  const tags = useSelector( state => getTagsByParent(state,tag.id) )
  const { tag_ids } = useQuery()
  const [ selected_ids, setSelected_ids ] = useState([])
  const [ selected, setSelected ] = useState(false)
  const [ hasSelected, setHasSelected ] = useState(false)

  useEffect(()=> {
    const id_array = Array.isArray(tag_ids) ? tag_ids : [tag_ids]
    const new_selected_ids = tags.reduce((arr,{ id })=> {
      if(id_array.indexOf(id) !== -1)
        arr.push(id)
        return arr
    },[])
    if(!isEqual(new_selected_ids,selected_ids)) {
      setSelected_ids(new_selected_ids)
    }
    const selected = id_array.indexOf(tag.id) !== -1
    setSelected(selected)
  }, [ tag_ids ])

  useEffect(()=> {
    const shouldHaveSelected = selected_ids.length > 0
    if(shouldHaveSelected && !hasSelected)
      setHasSelected(true)
    else if(!shouldHaveSelected && hasSelected)
      setHasSelected(false)
  }, [ selected_ids ])

  const _onExpanderClick = (e)=> {
    setExpanded(!expanded)
  }

  return (
    <Fragment>
      <ListItem
        className={ [
          styles.tag,
          facetCount === 0 ? styles.empty : undefined,
        ].join(' ') }
      >
        <div
          className="label"
          onClick={ _onExpanderClick }
        >
          { tag.name }
        </div>
        { tags.length > 0 &&
          <ExpandButton
            round={ true }
            expanded={ expanded }
            onClick={ _onExpanderClick }
          />
        }
        <Plink
          to={{
            search: {
              toggle: {
                tag_ids: tag.id
              }
            }
          }}
        >
          <SelectButton
            selected={ selected }
            hasSelected={ hasSelected }
          />
        </Plink>
        <div className={ styles.facet }>{ facetCount }</div>
      </ListItem>
      { tags.length > 0 &&
        <Collapse
          in={ expanded }
          className={ styles.listBody }
        >
          <div className={ styles.tags }>
            <TagsList
              tags={ tags }
            />
          </div>
        </Collapse>
      }
    </Fragment>
  )
}

TagsListItem.propTypes = {
  tag: PropTypes.object,
}

export default TagsListItem