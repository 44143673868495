import { createSelector } from 'reselect'

const contextsSelector = state => state.contexts.list
const mainSelector = (state,main_context_id)=> main_context_id

const getMyChannels = (contexts,main_context_id)=> {
  return Object.values(contexts)
    .reduce((arr,ctx)=> {
      if(
          ctx.type === 'channel'
        // &&
          // ctx.is_member
        &&
          (ctx.parent_context_id == main_context_id || ctx.id === main_context_id)
      )
        arr.push(ctx)
      return arr
    },[])
}

export default createSelector(
  [contextsSelector,mainSelector],
  getMyChannels
)