import { createSelector } from 'reselect'

const exportsSelector = state => state.exports.list
const idSelector = (state,id)=> id

const getExport = (exports,id)=> {
  return exports[id]
}

export default createSelector(
  [exportsSelector,idSelector],
  getExport
)