import React from 'react'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fa'
import {
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faBars,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faChevronSquareRight,
  faChevronSquareLeft,
  faCircle,
  faCompress,
  faExpand,
  faExternalLink,
  faLevelUp,
  faLongArrowLeft,
  faPaperclip,
  faTimes,
} from '@fal'
import {
  
} from '@fa'
import {
  faEllipsisV,
  faBraille,
  faCircle as fasCircle,
} from '@fas'

library.add(
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faBars,
  faBraille,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faChevronSquareRight,
  faChevronSquareLeft,
  faCircle,
  faCompress,
  faEllipsisV,
  faExpand,
  faExternalLink,
  faLevelUp,
  faLongArrowLeft,
  faPaperclip,
  faTimes,
  fasCircle
)

export const Fa = ({ icon, className, style })=>
  <FontAwesomeIcon className={[ 'fa', ...className.split(' ') ].join(' ')} icon={[ style, icon ]} />

Fa.propTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
}

Fa.defaultProps = {
  style: 'fal',
  className: '',
  icon: undefined
}