import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getSearchStringAsObject } from '|/store/router/selectors'

import FilterItem from './FilterItem'

import styles from './main.styl'

const FilterList = ()=> {

  const query = useSelector( state => getSearchStringAsObject(state))
  const [ filterRefs, setFilterRefs ] = useState([])

  useEffect(()=> {
    const new_filterRefs = Object.keys(query).reduce((arr,key)=> {
      const value_array = Array.isArray(query[key]) ? query[key] : [query[key]]
      value_array.map( value => {
        arr.push({
          key,
          value
        })
      })
      return arr
    },[])
    if(!isEqual(new_filterRefs,filterRefs))
      setFilterRefs(new_filterRefs)
  }, [ query ])

  if(filterRefs.length === 0) return null

  return (
    <div className={ styles.filterlist }>
      { filterRefs.map((ref,i)=> 
        <FilterItem key={ i } item={ ref } />
      )}
    </div>
  )
}

export default FilterList