import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { Fa } from '|/icons'

import { history } from '|/store'
import { useScrollY } from '|/hooks/scroller'

import Clickable from '|/helpers/Clickable'
import Button from '|/helpers/Button'

import styles from './main.styl'

const Overlay = (props)=> {

  const [ scrollY, setScrollRef ] = useScrollY()
  const [ overlay, setOverlay ] = useState(null)

  useEffect(()=> {
    const _container = props.container ? document.querySelector(props.container) : document.body
    const _overlay = document.createElement('div')
    _overlay.classList.add('overlay')
    if(props.container)
      _overlay.style.position = 'absolute'
    setOverlay(_overlay)
    _container.appendChild(_overlay)
    return ()=> {
      _container.removeChild(_overlay)
    }
  },[])

  useEffect(()=> {
    if(props.onScrollY)
      props.onScrollY(scrollY)
  },[ scrollY ])

  const _handleMinimise = (e)=> {
    e.preventDefault()
    if(!props.minimised && props.onMinimise)
      return props.onMinimise()
    else if(props.minimised && props.onUnMinimise)
      return props.onUnMinimise()
  }

  const _handleClose = (e)=> {
    if(disableClose) return null
    e.preventDefault()
    if(props.onClose)
      return props.onClose()
    history.goBack()
  }

  const _handleMaximise = (e)=> {
    e.preventDefault()
    if(!props.maximised && props.onMaximise)
      return props.onMaximise()
    else if(props.maximised && props.onUnMaximise)
      return props.onUnMaximise()
  }

  const {
    title,
    onMinimise,
    onMaximise,
    children,
    minimised,
    maximised,
    className,
    disableClose,
    disableMaximise,
  } = props
  
  if(!overlay) return null
  return createPortal(
    <Fragment>
      <div
        className={[
          styles.BG,
          minimised ? styles.minimised : null,
        ].join(' ')}
        onClick={ onMinimise ? _handleMinimise : _handleClose }
      />
      <div className={[
        styles.container,
        className ? className : null,
        minimised ? styles.minimised : null,
        maximised ? styles.maximised : null,
        ].join(' ')}>
        { onMinimise ?
            <Clickable
              className={ styles.header }
              onClick={ _handleMinimise }
            >
              <div className={ styles.title }>{ title }</div>
              <Fa icon="chevron-down" />
            </Clickable>
          :
            <div className={ styles.header }>
              <div className={ styles.title }>{ title }</div>
              { onMaximise &&
                <Button
                  className={ styles.maximiser }
                  onClick={ _handleMaximise }
                  round={ true }
                  material={ false }
                  link={ true }
                  icon={ maximised ? 'compress' : 'expand' }
                />
              }
              { !disableClose &&
                <Button
                  className={ styles.close }
                  round={ true }
                  material={ false }
                  link={ true }
                  onClick={ _handleClose }
                  icon="times"
                />
              }
            </div>
        }
        <div
          ref={ setScrollRef }
          className={ styles.content }
        >
          { children }
        </div>
        { props.footer &&
          <div className={ styles.footer }>
            { props.footer() }
          </div>
        }
      </div>
    </Fragment>,
    overlay
  )
}

Overlay.propTypes = {
  round: PropTypes.bool,
  container: PropTypes.string,
  title: PropTypes.string,
  minimised: PropTypes.bool,
  disableMaximise: PropTypes.bool,
  disableClose: PropTypes.bool,
  onClose: PropTypes.func,
  onMinimise: PropTypes.func,
  onUnMinimize: PropTypes.func,
  onMaximise: PropTypes.func,
  onUnMaximise: PropTypes.func,
  onScrollY: PropTypes.func,
}

Overlay.defaultProps = {
  round: false,
  container: undefined,
  title: undefined,
  minimised: undefined,
  disableMaximise: true,
  disableClose: false,
  onClose: undefined,
  onMinimise: undefined,
  onUnMinimize: undefined,
  onMaximise: undefined,
  onUnMaximise: undefined,
  onScrollY: undefined,
}

export default Overlay