import React from 'react'
import PropTypes from 'prop-types'

import Button from '|/helpers/Button'

import styles from './main.styl'

const MultiSelect = props => {

  const _onMultiSelectChange = (e)=> {
    const numValue = toNumber(e.target.value)
    if(e.target.checked)
      props.onChange(props.name,props.value.with(numValue))
    else
      props.onChange(props.name,props.value.without(numValue))
  }

  const _onMultiSelectFocus = (e)=> {
    
  }

  if(!props.options) return null
  const option = (opt)=>
    <div
      key={`option_${opt.id}`}
      className={ styles.container }
    >
      <div key={ `tag-${opt.group}-${opt.id}` } className={ styles.option }>
        <input
          type="checkbox"
          name={ `${props.name}[]` }
          value={ opt.id }
          checked={ props.value.indexOf(opt.id) !== -1 }
          onChange={ _onMultiSelectChange }
          onFocus={ _onMultiSelectFocus }
        />
        <Button material={ false } className={ styles.name }>{ opt.name || opt.title }</Button>
      </div>
      { opt.options &&
        <div className={ styles.options }>
          { opt.options.map( option ) }
        </div>
      }
    </div>

  const classList = [ styles.container ]

  return (
    <div>
      <div className={ styles.multiselect }>
        { props.options.map( option ) }
      </div>
    </div>
  )
}

MultiSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

MultiSelect.defaultProps = {
  name: '',
  onChange: ()=> {}
}

export default MultiSelect