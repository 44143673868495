import { createSelector } from 'reselect'

const digestsSelector = state => state.digests.list
const idSelector = (state,id)=> id

const getDigests = (digests,id)=> {
  return Object.values(digests)
    .reduce((arr,dgst)=> {
      if(!dgst.has_parent_digest && dgst.context_id == id)
        arr.push(dgst)
      return arr
    },[])
}

export default createSelector(
  [digestsSelector,idSelector],
  getDigests
)