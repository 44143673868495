import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect } from 'react-router'

import { history } from '|/store'

import {
  LOG_OUT,
} from '|/store/constants'

const LogoutForm = props => {

  const dispatch = useDispatch()

  useEffect(()=> {
    dispatch({
      type: LOG_OUT
    })
  }, [])

  return (
    <Redirect to="/" />
  )
}


export default LogoutForm