import { createSelector } from 'reselect'

const digestsSelector = state => state.digests.saved

const getSavedDigest = (context)=> {
  return context
}

export default createSelector(
  [digestsSelector],
  getSavedDigest
)