import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '|/store'

import { useQuery } from '|/hooks/pathState'
import { useParams } from '|/hooks/router'

import {
  CODE_SIGNIN,
} from '|/store/constants'

import Overlay from '|/helpers/Overlay'

import styles from './main.styl'

const Activate = ({ children })=> {

  const dispatch = useDispatch()
  const { id, code } = useParams()
  const query = useQuery()
  const { error, authenticated } = useSelector( state => state.auth )

  useEffect(()=> {
    if(id && code)
      dispatch({
        type: CODE_SIGNIN,
        id,
        code,
      })
  },[])

  useEffect(()=> {
    if(authenticated) {
      if(query['reset-password'])
        history.replace(`/newpassword/${code}`)
      else
        history.replace(`/initiate/${code}`)
    }
  },[ authenticated ])

  return (
    <React.Fragment>
      { error ?
          <Overlay
            title={ `Token authentication` }
            disableClose={ true }
          >
            <div className={ styles.content }>
              <div
                className="error"
              >
                { error }
              </div>
            </div>
          </Overlay>
        :
          children
      }
      { authenticated &&
        children
      }
    </React.Fragment>
  )
}


export default Activate