import { createSelector } from 'reselect'

const contextsSelector = state => state.contexts.list
const parentIdSelector = (state,parent_id)=> parent_id
const userIdSelector = state => state.auth.current_user_id

const getTaggableContexts = (contexts,parent_id,user_id)=> {
  return Object.values(contexts)
    .reduce((arr,ctx)=> {
      if(
        ctx.type !== 'milestone' && (
          ctx.parent_context_id == parent_id ||
          ctx.id === parent_id
        ) && (
          ctx.member_ids && ctx.member_ids.indexOf(user_id) !== -1
        )
      )
        arr.push(ctx)
      return arr
    },[])
}

export default createSelector(
  [contextsSelector,parentIdSelector,userIdSelector],
  getTaggableContexts
)