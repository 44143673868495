import differenceBy from 'lodash/differenceBy'
import unionBy from 'lodash/unionBy'
import React, { Fragment, useRef, useState, useEffect, useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getContext, getSavedContext, getMyChannels } from '|/store/contexts/selectors'
import { getSavedDigest } from '|/store/digests/selectors'
import { getTagsByGroup } from '|/store/tags/selectors'
import { getUsers, getCurrentUser, getUsersByIds } from '|/store/users/selectors'

import { history } from '|/store'
import { useValidation } from '|/hooks/validation'
import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { LeftEditorContext } from '|/contexts/LeftEditor'

import {
  ADD_ATTACHMENT,
  FETCH_CONTEXTS,
  FETCH_CONTEXT,
  CREATE_CONTEXT,
  UPDATE_CONTEXT,
  DELETE_CONTEXT,
  CREATE_DIGEST,
  UPDATE_DIGEST,
  FETCH_USERS,
} from '|/store/constants'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import ConfirmButton from '|/helpers/ConfirmButton'
import Input from '|/helpers/Input'
import Plink from '|/helpers/Plink'

import styles from './main.styl'

const MilestoneForm = props => {

  const {
    controls,
    nextRecord,
    parentContext,
    onChange,
    onSubmit,
  } = props

  if(!parentContext || !nextRecord) return null

  const _onChange = (key,val)=> {
    onChange(key,val)
  }

  const _onSubmit = (e)=> {
    onSubmit(e)
  }

  return (
    <form onSubmit={ _onSubmit }>
      { Object.keys(controls).map((key)=>
          <Input
            key={ `left-formElement-${key}` }
            name={ key }
            {...{
              ...controls[key],
            }}
            onChange={ _onChange }
          />
      )}
    </form>
  )
}


export default MilestoneForm