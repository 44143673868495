import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '|/store'
import { init as initRoute } from '|/routes'

import { usePathState } from '|/hooks/pathState'
import { getCurrentUser, getUsersByRole } from '|/store/users/selectors'

import ListItem from '|/components/ListItem'
import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'

import Form from './Form'

import styles from './main.styl'

const Teachers = props => {

  const pathState = usePathState()
  const teachers = useSelector( state => getUsersByRole(state,'teacher') )
  const saving = useSelector( state => state.users.saving )
  const [ editing, setEditing ] = useState(false)

  useEffect(()=> {
    if(editing && !saving)
      setEditing(false)
  },[ saving ])

  return (
    <Overlay
      title="Manage teachers"
      className={ styles.container }
      onClose={ ()=> {
        history.push(pathState.toString(initRoute))
      }}
    >
      <div className={ styles.content }>
        <Form />
        {/*<div className={ styles.teachers }>
          { teachers.map( teacher =>
            <ListItem key={`teacher_${teacher.id}`}>
              { teacher.usable_name }
            </ListItem>
          )}
        </div>*/}
      </div>
    </Overlay>
  )
}

export default Teachers