import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { history } from '|/store'

import {
  UPDATE_CURRENT_USER,
} from '|/store/constants'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import Input, { checkValidity } from '|/helpers/Input'

import styles from './main.styl'

const LoginForm = ({ user, onCancel })=> {

  const dispatch = useDispatch()
  const [ saving, setSaving ] = useState(false)
  const [ controls, setControls ] = useState({
    firstname: {
      type: 'Input',
      label: 'First name',
      config: {
        placeholder: 'First name',
      },
      value: '',
      touched: false,
      onChange: onChange,
    },
    lastname: {
      type: 'Input',
      label: 'Last name',
      config: {
        placeholder: 'Last name',
      },
      value: '',
      touched: false,
      onChange: onChange,
    },
    email: {
      type: 'Input',
      label: 'Email',
      config: {
        type: 'email',
        placeholder: 'Email address',
        autoComplete: "false",
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      touched: false,
      onChange: onChange,
    },
    current_password: {
      type: 'Input',
      label: 'Password',
      config: {
        type: 'password',
        placeholder: 'Password',
        autoComplete: "new-password",
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      onChange: onChange,
    },
    password: {
      type: 'Input',
      label: 'New password',
      config: {
        placeholder: 'Password',
        autoComplete: "new-password",
      },
      value: '',
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
      onChange: onChange,
    },
  })

  const onChange = (key, value)=> {
    setControls({
      ...controls,
      [key]: {
        ...controls[key],
        value,
        // valid: checkValidity(e.target.value, controls[controlName].validation),
        touched: true,
      }
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setSaving(true)
    const formData = {}
    for(let key in controls) {
      formData[key] = controls[key].value
    }
    dispatch({
      type: UPDATE_CURRENT_USER,
      user: formData
    })
  }

  const formElements = []
  for(let key in controls) {
    formElements.push({
      key,
      control: controls[key],
    })
  }

  useEffect(()=> {
    if(user) {
      const new_controls = Object.keys(controls).reduce((obj,key)=> {
        obj[key] = {
          ...controls[key],
          value: user[key] ? user[key] : controls[key].value,
        }
        return obj
      },{})
      setControls(new_controls)
    }
  },[ user ])

  return (
    <form onSubmit={ onSubmit } autoComplete="false">
      { formElements.map(({ key, control })=> <Input
            key={ key }
            name={ key }
            { ...control }
            onChange={ onChange }
          /> ) }
      <Button type="submit" disabled={ saving }>
        { saving ? 'Saving...' : 'Save' }
      </Button>
      <Button
        onClick={ onCancel }
      >
        { 'Cancel' }
      </Button>
    </form>
  )
}


export default LoginForm