import { createSelector } from 'reselect'

const tagsSelector = state => state.tags.list
const keySelector = (state,key)=> key

const getSortedTags = (tags,key)=> {
  return Object.values(tags).sort((a,b)=> {
    if(a[key] < b[key])
      return -1
    if(a[key] > b[key])
      return 1
    return 0
  })
}

export default createSelector(
  [tagsSelector,keySelector],
  getSortedTags
)