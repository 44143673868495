import React from 'react'
import PropTypes from 'prop-types'

const Input = props => {

  const _onChange = (e)=> {
    const value = e.target.value === "0" ? null : e.target.value
    props.onChange(props.name,value)
  }

  const _onFocus = (e)=> {
    
  }

  if(!props.options) return null

  return (
    <div>
      <select
        onChange={ _onChange }
        value={ props.value || 0 }
      >
        <option value="0">-- no channel --</option>
        { props.options.map( opt =>
          <option
            key={ opt.id }
            value={ opt.id }
          >{ opt.title }</option>
        )}
      </select>
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
}

Input.defaultProps = {
  name: '',
  onChange: ()=> {}
}

export default Input