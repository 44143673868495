import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Plink from '|/helpers/Plink'

import Markdown from '|/components/Markdown'

import styles from './main.styl'

const Head = ({ context })=> {

  if(!context) return null
  return (
    <Plink
      data-cy={`${context.type}_list_item-${context.title}`}
      className={[ 'label', context.is_member ? 'is_member' : undefined ].join(' ')}
      to={{
        left: {
          c: context.id
        },
        search: {
          main_context_id: context.id
        }
      }}
    >
      { context.title }
    </Plink>
  )
}

Head.propTypes = {
  context: PropTypes.object
}

const Body = ({ context })=> {

  if(!context) return null
  return (
    <Fragment>
      <div className={ styles.description }>
        <Markdown source={ context.description } />
      </div>
    </Fragment>
  )
}

Body.propTypes = {
  context: PropTypes.object
}

export default {
  Head,
  Body
}