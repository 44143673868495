import { createSelector } from 'reselect'

const postsSelector = state => state.posts.list
const keySelector = (state,key)=> key

const getSortedPosts = (posts,key)=> {
  return Object.values(posts).sort((a,b)=> {
    if(a[key] < b[key])
      return -1
    if(a[key] > b[key])
      return 1
    return 0
  })
}

export default createSelector(
  [postsSelector,keySelector],
  getSortedPosts
)