import React, { createContext, useState } from 'react'

export const DigestContext = createContext()

export const DigestProvider = ({ children })=> {

  const [ digesting, setDigesting ] = useState(false)
  const [ main_context_id, setMain_context_id ] = useState(undefined)
  const [ post_id, setPost_id ] = useState(false)
  const [ api, setApi ] = useState(undefined)

  const digestContext = {
    digesting,
    setDigesting,
    post_id,
    setPost_id,
    main_context_id,
    setMain_context_id,
  }

  return <DigestContext.Provider
      value={ digestContext }
    >
      { children }
    </DigestContext.Provider>
}