import React, { Fragment, useContext } from 'react'

import { PostContext } from '|/contexts/Post'
import { useParams } from '|/hooks/router'

import View from './View'
import PostForm from './PostForm'

const Post = props => {

  const { post_id } = useParams()
  const { editing } = useContext(PostContext)

  console.log('editing',editing)

  return (
    <Fragment>
      { editing || post_id === 'NEW' ?
          <PostForm />
        :
          <View />
      }
    </Fragment>
  )
}

export default Post