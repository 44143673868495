import React, { useEffect, createContext, useState } from 'react'

import Overlay from '|/helpers/Overlay'

export const NetworkContext = createContext()

export const NetworkProvider = ({ children })=> {

  const [ error, setError ] = useState()

  const _onError = function(msg, url, line, col, error) {
    console.log('ERROR',error)
    switch(msg) {
      case 'Uncaught Error: MAINTENANCE':
        setError({
          message: "K.o.M. is currently down for maintenance. Please check back in a while."
        })
        break
      default:
        break
    }
  }

  useEffect(()=> {
    window.onerror = _onError
  },[])


  const networkContext = [
    error,
  ]

  return <NetworkContext.Provider
      value={ networkContext }
    >
      { error ?
        <Overlay
          title={ 'System maintenance' }
          disableClose={ true }
        >
          <div style={{
            padding: '1em',
          }}>
            { error.message }
          </div>
        </Overlay>
      :
        <>
          { children }
        </>
      }
    </NetworkContext.Provider>
}