import merge from 'lodash/merge'
import React, { useState, createContext, useContext } from 'react'
import {
  search2obj,
  obj2search,
  search2array,
  array2search,
  toggleInSearchObj,
  addToSearchObj,
  removeFromSearchObj
} from '|/utils'
import { history } from '|/store'

const PathStateContext = createContext()

export const Provider = ({ init, children })=> (
  <PathStateContext.Provider value={ init }>
    { children }
  </PathStateContext.Provider>
)

export const usePathState = ()=> {

  const initialState = useContext(PathStateContext)

  const getPathStateObject = ()=> {
    if(initialState) {
      const pathname = window.location.pathname.replace('/','')
      const pathParts = pathname.split('/')
      const obj = Object.keys(initialState).reduce((obj,key,i)=> {
        let pathPart = pathParts[i]
        if(pathPart) {
          if(
            (typeof initialState[key] !== 'string')
            &&
            (pathPart.indexOf(':') !== -1)
          ) {
            pathPart = JSON.parse('{"'+pathPart.replace(/;/g,'","').replace(/:/g,'":"')+'"}')
          }
          console.log('pathPart',pathPart)
          obj[key] = pathPart
        } else {
          obj[key] = initialState[key]
        }
        return obj
      },{})
      obj.search = useQuery()
      return obj
    } else {
      return {}
    }
  }

  const mergePathStates = (newStateParts={})=> {
    const { search, ...pathState } = getPathStateObject()
    console.log('pathState',pathState)
    let { search: newSearchCreator, ...newStatePartsExceptSearch } = newStateParts
    const newState = Object.keys(newStatePartsExceptSearch).reduce((obj,key)=> {
      if(typeof newStateParts[key] === 'string') {
        obj[key] = newStateParts[key]
      } else {
        obj[key] = {
          ...pathState[key],
          ...newStateParts[key],
        }
      }
      return obj
    },{})
    console.log('newState',newState)
    if(newSearchCreator) {
      let { toggle, add, remove, ...newSearch } = newSearchCreator
      if(add)
        newSearch = addToSearchObj(search,add)
      if(remove)
        newSearch = removeFromSearchObj(search,remove)
      if(toggle)
        newSearch = toggleInSearchObj(search,toggle)
      newState.search = newSearch
    } else {
      newState.search = search
    }
    return merge(pathState,newState)
  }

  const convertPathStateToString = (obj=initialState)=> {
    if(obj === undefined) return ''
    const { search, ...pathState } = obj
    let string = Object.keys(pathState).reduce((string,part,i)=> {
      if(pathState[part] && typeof pathState[part] !== 'string') {
        const param_string = Object.keys(pathState[part]).reduce((arr,param,i)=> {
          arr.push(`${param}:${pathState[part][param]}`)
          return arr
        },[]).join(';')
        string += `/${param_string}`
      } else {
        string += `/${pathState[part]}`
      }
      return string
    },'')
    return search ? string + `?${obj2search(search)}` : string
  }

  const setPathState = (stateObject)=> {
    const [ pathStateStructure, setPathStateStructure ] = useState({})
  }

  return {
    get: getPathStateObject,
    set: setPathState,
    merge: mergePathStates,
    toString: convertPathStateToString,
  }
}

export const useQuery = ()=> {
  return search2obj(window.location.search)
}

export const useGoBack = ()=> {
  return history.goBack
}