import { createSelector } from 'reselect'

const listSelector = (state,key)=> {
  let store = null
  switch(key) {
    case 'context_ids':
      store = 'contexts'
      break
    case 'digest_ids':
      store = 'digests'
      break
    case 'tag_ids':
      store = 'tags'
      break
    case 'main_context_id':
      store = 'contexts'
      break
  }
  return state[store].list
}
const idSelector = (state,key,id)=> id

const getFilterItemByKey = createSelector(
  [listSelector,idSelector],
  (list,id)=> {
    return list[id]
  }
)

export default getFilterItemByKey

export const createGetFilterItemByKey = ()=> getFilterItemByKey