const _controls = {
  project: {
    title: {
      type: 'Input',
      label: 'Title',
      config: {
        placeholder: 'A title...',
        'data-cy': 'titleField',
      },
      validation: {
        required: true,
      },
      value: '',
      valid: false,
      touched: false,
    },
    attachments: {
      type: 'Attachments',
      label: 'Images',
      config: {
        'data-cy': 'attachmentsField',
      },
      value: [],
      touched: false,
    },
    description: {
      type: 'Textarea',
      label: 'Description',
      config: {
        placeholder: 'A description...',
        rows: 12,
        'data-cy': 'descriptionField',
      },
      value: '',
      touched: false,
    },
    is_public: {
      type: 'Checkbox',
      label: 'Public',
      config: {
        'data-cy': 'isPublicField',
      },
      value: true,
      touched: false,
    },
    admin_ids: {
      type: 'MultiSearchSelect',
      label: 'Project admins',
      config: {
        placeholder: 'Search for name or email...',
        'data-cy': 'adminsField',
        filter_attributes: [
          'firstname',
          'lastname',
          'email',
          'usable_name',
        ]
      },
      value: [],
      options: [],
      touched: false,
    },
    member_ids: {
      type: 'MultiSearchSelect',
      label: 'Project Members',
      config: {
        placeholder: 'Search for name or email...',
        'data-cy': 'membersField',
        filter_attributes: [
          'firstname',
          'lastname',
          'email',
          'usable_name',
        ]
      },
      value: [],
      options: [],
      touched: false,
    },
    tag_ids: {
      type: 'MultiSelect',
      label: 'Tags',
      config: {
        'data-cy': 'tagsField',
      },
      value: [],
      options: [],
      touched: false,
    },
  },
  milestone: {
    title: {
      type: 'Input',
      label: 'Title',
      config: {
        placeholder: 'A title...',
        'data-cy': 'titleField',
      },
      validation: {
        required: true,
      },
      value: '',
      touched: false,
    },
    attachments: {
      type: 'Attachments',
      label: 'Images',
      config: {
        'data-cy': 'attachmentsField',
      },
      value: [],
      touched: false,
    },
    description: {
      type: 'Textarea',
      label: 'Description',
      config: {
        placeholder: 'A description...',
        'data-cy': 'descriptionField',
        rows: 12,
      },
      value: '',
      touched: false,
    },
    is_completed: {
      type: 'Checkbox',
      label: 'Completed',
      config: {
        'data-cy': 'completedField',
      },
      value: false,
      touched: false,
    },
  },
  channel: {
    title: {
      type: 'Input',
      label: 'Title',
      config: {
        placeholder: 'A title...',
        'data-cy': 'titleField',
      },
      validation: {
        required: true,
      },
      value: '',
      touched: false,
    },
    attachments: {
      type: 'Attachments',
      label: 'Images',
      config: {
        'data-cy': 'attachmentsField',
      },
      value: [],
      touched: false,
    },
    description: {
      type: 'Textarea',
      label: 'Description',
      config: {
        placeholder: 'A description...',
        'data-cy': 'decsriptionField',
        rows: 12,
      },
      value: '',
      touched: false,
    },
    is_public: {
      type: 'Checkbox',
      label: 'Public',
      config: {
        
      },
      value: true,
      touched: false,
    },
    admin_ids: {
      type: 'MultiSearchSelect',
      label: 'Channel admins',
      config: {
        placeholder: 'Search for name or email...',
        'data-cy': 'adminsField',
        filter_attributes: [
          'firstname',
          'lastname',
          'email',
          'usable_name',
        ]
      },
      value: [],
      options: [],
      touched: false,
    },
    member_ids: {
      type: 'MultiSearchSelect',
      label: 'Members',
      config: {
        placeholder: 'Search for name or email...',
        'data-cy': 'membersField',
        filter_attributes: [
          'firstname',
          'lastname',
          'email',
          'usable_name',
        ]
      },
      value: [],
      options: [],
      touched: false,
    },
    default_tag_ids: {
      type: 'MultiSelect',
      label: 'Default tags',
      config: {
        'data-cy': 'defaultTagsField',
      },
      value: [],
      options: [],
      touched: false,
    },
  },
  digest: {
    body: {
      type: 'Textarea',
      label: 'Text',
      config: {
        placeholder: 'A text...',
        'data-cy': 'textField',
        rows: 12,
      },
      value: '',
      touched: false,
    },
    channel_id: {
      type: 'Select',
      label: 'Channel',
      config: {
        'data-cy': 'channelField',
      },
      value: null,
      touched: false,
    },
  }
}

export default (record,nextRecord)=> {

  const controls = Object.keys(_controls[nextRecord.type]).reduce((obj,attribute)=> {
    obj[attribute] = {
      ..._controls[nextRecord.type][attribute],
      value: nextRecord[attribute] !== undefined ? nextRecord[attribute] : _controls[nextRecord.type][attribute].value,
      touched: isEqual(nextRecord[attribute],record[attribute]),
    }
    // console.log(obj)
    return obj
  },{})

  return controls
}