import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import { useValidation } from '|/hooks/validation'

import Button from '|/helpers/Button'

import {
  INVITE_USER
} from '|/store/constants'

import styles from './main.styl'

const MultiSearchSelect = props => {

  const dispatch = useDispatch()
  const validate = useValidation()
  const [ focused, setFocused ] = useState(false)
  const [ input_value, setInput_value ] = useState('')
  const [ filtered_options, setFiltered_options ] = useState([])
  const [ highlighted_option, setHighlighted_option ] = useState([])

  useEffect(()=> {
    if(props.padded_value.length > 0 && props.value.length === 0)
      props.onChange(props.name,props.padded_value.map( v => v.id ))
  },[ props.padded_value ])

  useEffect(()=> {
    if(props.options && props.value)
      filterBySearchInput()
  },[ props.value, props.options, input_value ])

  useEffect(()=> {
    setInput_value('')
  },[ props.value ])

  const _onMultiSearchSelectChange = (e)=> {
    const numValue = toNumber(e.target.value)
    if(e.target.checked)
      props.onChange(props.name,props.value.with(numValue))
    else
      props.onChange(props.name,props.value.without(numValue))
  }

  const _onMultiSearchSelectFocus = (e)=> {
    //
  }

  const _onMultiSearchSelectKeyDown = (e)=> {
    let index
    switch(e.key) {
      case 'ArrowDown':
        index = filtered_options.indexOf(highlighted_option)
        if(index === filtered_options.length - 1)
          return false
        setHighlighted_option(filtered_options[index+1])
        break
      case 'ArrowUp':
        index = filtered_options.indexOf(highlighted_option)
        if(index === 0)
          return false
        setHighlighted_option(filtered_options[index-1])
        break
      case 'Enter':
        e.preventDefault()
        _addMultiSearchSelectOption(highlighted_option.id)
        break
    }
  }

  const _onMultiSearchSelectInputChange = (e)=> {
    setInput_value(e.target.value)
  }

  const _onMultiSearchSelectInputFocus = (e)=> {
    setFocused(true)
  }

  const _onMultiSearchSelectInputBlur = (e)=> {
    setFocused(false)
  }

  const _addMultiSearchSelectOption = (opt)=> {
    if(props.addHandler)
      return props.addHandler(opt)
    props.onChange(props.name,props.value.with(opt.id))
  }

  const _removeMultiSearchSelectOption = (opt)=> {
    const value = props.value.without(opt.id)
    props.onChange(props.name,value)
  }

  const filterBySearchInput = ()=> {
    const { filter_attributes } = props.config
    let opts = []
    if(!filter_attributes) return null
    opts = props.options.filter( opt => props.value.indexOf(opt.id) === -1 )
    if(input_value !== '') {
      opts = opts.filter( opt => {
        let hit = false
        filter_attributes.map( attr => {
          if(!opt[attr]) return false
          hit = opt[attr].toLowerCase().indexOf(input_value.toLowerCase()) !== -1
        })
        return hit
      })
    }
    setFiltered_options(opts)
    setHighlighted_option(opts[0])
  }

  if(!props.options || !props.padded_value) return null
  // console.log(props.padded_value)
  const searchOption = (opt)=> {
    const active = opt.id === highlighted_option.id
    return <div
      key={ `user-${opt.id}` }
      className={ styles.option }>
      { props.optionRenderer ?
          <props.optionRenderer
            opt={ opt }
            active={ active }
            onMouseDown={ e => _addMultiSearchSelectOption(opt) }
          />
        :
          <Button
            active={ active }
            material={ false }
            className={ styles.label }
            onMouseDown={ e => _addMultiSearchSelectOption(opt) }
          >{ opt.usable_name }</Button>
      }
    </div>
  }

  return (
    <div>
      <div className={ styles.multisearchselect }>
        <div className={[
          styles.input,
          (focused && styles.focused),
        ].join(' ')}>
          <input
            type="search"
            onChange={ _onMultiSearchSelectInputChange }
            onFocus={ _onMultiSearchSelectInputFocus }
            onBlur={ _onMultiSearchSelectInputBlur }
            onKeyDown={ _onMultiSearchSelectKeyDown }
            value={ input_value }
            placeholder={ props.config.placeholder }
          />
          { (props.inviteRenderer && filtered_options.length === 0) &&
            props.inviteRenderer(input_value)
          }
          <div className={ styles.options }>
            { focused && filtered_options.map( searchOption ) }
          </div>
        </div>
        { props.padded_value && props.padded_value.map( value => 
          <div key={ `searchselect-${value.id}` } className={ styles.value }>
            { props.valueRenderer ?
                props.valueRenderer(value)
              :
                <div className={ styles.name }>{ value.usable_name }</div>
            }
            { props.removeRenderer ?
                props.removeRenderer(value)
              :
                <Button
                  className={ styles.remove }
                  round={ true }
                  kind="destructive"
                  onClick={ e => _removeMultiSearchSelectOption(value) }
                >
                  <Fa icon="times"/>
                </Button>
            }
          </div>
        )}
      </div>
    </div>
  )
}

MultiSearchSelect.propTypes = {
  name: PropTypes.string,
  exclude: PropTypes.array,
  onChange: PropTypes.func,
  inviteRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  optionRenderer: PropTypes.func,
  removeRenderer: PropTypes.func,
  addHandler: PropTypes.func,
}

MultiSearchSelect.defaultProps = {
  name: '',
  onChange: ()=> {},
  inviteRenderer: undefined,
  valueRenderer: undefined,
  optionRenderer: undefined,
  removeRenderer: undefined,
  addHandler: undefined,
}

export default MultiSearchSelect