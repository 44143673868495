import { createSelector } from 'reselect'

const usersSelector = state => state.users.list

const getUsers = createSelector(
  [usersSelector],
  (users)=> {
    return Object.values(users)
  }
)

export default getUsers

export const createGetUsers = ()=> getUsers