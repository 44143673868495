import React from 'react'
import PropTypes from 'prop-types'
import { Fa } from '|/icons'

import styles from './main.styl'

const Button = ({ el, active, size, icon, iconStyle, round, link, kind, material, toggle, className, children, ...props })=> {
  const classList = [ styles.container, className ]
  const El = el
  if(size) classList.push(styles[size])
  if(active) classList.push('active')
  if(round) classList.push(styles.round)
  if(link) classList.push(styles.link)
  if(kind) classList.push(styles[kind])
  if(material) classList.push(styles.material)
  if(toggle) classList.push(styles.toggle)
  return (
    <El { ...props } className={ classList.join(' ') }>
      { children }
      { icon &&
        <Fa className="icon" style={ iconStyle } icon={ icon } />
      }
    </El>
  )
}

Button.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  material: PropTypes.bool,
  toggle: PropTypes.bool,
  round: PropTypes.bool,
  link: PropTypes.bool,
  kind: PropTypes.string,
  el: PropTypes.string,
}

Button.defaultProps = {
  active: false,
  size: 'medium',
  type: 'button',
  icon: undefined,
  iconStyle: 'fal',
  material: true,
  toggle: false,
  round: false,
  link: false,
  el: 'button',
}

export default Button