import React from 'react'

import Markdown from '|/components/Markdown'

import styles from './main.styl'

const Digest = ({ digest })=> {
  
  return (
    <div className={ styles.body }>
      <Markdown source={ digest.body } />
    </div>
  )
}

export default Digest