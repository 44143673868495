import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useHash } from '|/hooks/router'
import { useDispatch } from 'react-redux'

import Markdown from '|/components/Markdown'

import { DigestContext } from '|/contexts/Digest'

import Plink from '|/helpers/Plink'
import Button from '|/helpers/Button'
import Img from '|/helpers/Img'
import Loader from '|/helpers/Loader'
import PDF from '|/helpers/PDF'
import { Fa } from '|/icons'

import {
  FETCH_POST
} from '|/store/constants'

import styles from './main.styl'

const Post = ({ post })=> {
  const {
    id,
    created_on,
    body,
    cover_thumb_url,
    media_attachments,
    has_data_attachment,
    has_processing_running,
  } = post

  const dispatch = useDispatch()
  const { digesting, setPost_id, main_context_id } = useContext(DigestContext)
  const [ isHighlighted, setIsHighlighted ] = useState(false)
  const [ created_at, setCreated_at ] = useState()
  const [ ownerUsableName, setOwnerUsableName ] = useState()
  const [ can_digest, setCan_digest ] = useState(false)
  const [ pdfThumb, setPdfThumb ] = useState()
  const [ has_refetched_post, setHas_refetched_post ] = useState(false)
  const hash = useHash()

  const thumb = cover_thumb_url ? 
      cover_thumb_url
    :
      null
  const anchor = `post-${id}`

  const _onAddToDigestClick = (e)=> {
    setPost_id(post.id)
  }

  const _onPDFLoadError = ()=> {
    if(!has_refetched_post) {
      setHas_refetched_post(true)
      dispatch({
        type: FETCH_POST,
        post_id: post.id,
      })
    }
  }

  useEffect(()=> {
    const shouldHighlight = hash == '#'+anchor
    if(!isHighlighted && shouldHighlight)
      setIsHighlighted(true)
    else if(isHighlighted && !shouldHighlight)
      setIsHighlighted(false)
  }, [ hash ])

  useEffect(()=> {
    setCan_digest(main_context_id === post.main_context_id)
  },[ main_context_id ])

  useEffect(()=> {
    setCreated_at(moment(created_on).format('YYYY.MM.DD - HH:mm'))
    setOwnerUsableName(post.owner_usable_name)
    if(post.data_attachments) {
      const pdf_thumb = post.data_attachments.map( a => a.filetype ? a.filetype === 'application/pdf' && a.attachment : a.indexOf('.pdf?') !== -1 && a )[0]
      if(pdf_thumb !== pdfThumb)
        setPdfThumb(pdf_thumb)
    }
  },[ post ])

  return (
    <div
      id={ anchor }
      className={[
        styles.post,
        'btn',
        isHighlighted ? styles.highlighted : null,
        can_digest && digesting ? styles.digesting : null,
      ].join(' ')}
    >
      <div className={ styles.header }>
        { `${created_at} by ${ownerUsableName}` }
        { post.has_data_attachment &&
          <Fa className={ styles.paperclip } icon={'paperclip'} />
        }
      </div>
      <div className={ styles.wrapper }>
        <div
          className={ styles.some }
          style={{
            height: `${post.relevance}%`,
          }}
        >
          <div
            className={ styles.content }
            style={{
              width: `${post.relevance}%`,
              fontSize: `${post.relevance/100}em`,
            }}
          >
            { cover_thumb_url &&
              <img src={ cover_thumb_url } className={ styles.thumb } />
            }
            { (!cover_thumb_url && pdfThumb) &&
              <PDF
                file={ pdfThumb }
                className={ styles.thumb }
                onLoadError={ _onPDFLoadError }
              />
            }
            { has_processing_running && 
              <div className={ styles.processing }>{`Processing video...`}</div>
            }
            { body &&
              <div className={ styles.body }>
                <Markdown source={ body } />
              </div>
            }
          </div>
        </div>
      </div>
      { post.comment_count > 0 &&
        <div
          className={ styles.comment_count }
        >
          <div className={ styles.count }>
            { post.comment_count }
          </div>
        </div>
      }
      <Plink
        to={{
          post: {
            p: post.id
          }
        }}
      />
      <div className={ styles.bookmark }>
        { (can_digest && digesting) &&
          <Button
            material={ false }
            link={ true }
            onClick={ _onAddToDigestClick }
          >{`+`}</Button>
        }
      </div>
    </div>
  )
}

Post.defaultProps = {
  post: PropTypes.object
}

export default Post