import React, { createContext, useState } from 'react'

export const PostContext = createContext()

export const PostProvider = ({ children })=> {

  const [ editing, setEditing ] = useState(false)
  const [ currentComment, setCurrentComment ] = useState(false)
  const [ didSave, setDidSave ] = useState(false)

  const postContext = {
    editing,
    setEditing,
    currentComment,
    setCurrentComment,
    didSave,
    setDidSave,
  }

  return <PostContext.Provider
      value={ postContext }
    >
      { children }
    </PostContext.Provider>
}