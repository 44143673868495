import differenceBy from 'lodash/differenceBy'
import unionBy from 'lodash/unionBy'
import React, { Fragment, useRef, useState, useEffect, useContext, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getContext, getSavedContext, getMyChannels } from '|/store/contexts/selectors'
import { getSavedDigest } from '|/store/digests/selectors'
import { getTagsByGroup } from '|/store/tags/selectors'
import { getUsers, getCurrentUser, getUsersByIds } from '|/store/users/selectors'

import { history } from '|/store'
import { useValidation } from '|/hooks/validation'
import { useParams } from '|/hooks/router'
import { usePathState } from '|/hooks/pathState'
import { LeftEditorContext } from '|/contexts/LeftEditor'

import controlsGenerator from './controlsGenerator'

import {
  ADD_ATTACHMENT,
  FETCH_CONTEXTS,
  FETCH_CONTEXT,
  CREATE_CONTEXT,
  UPDATE_CONTEXT,
  DELETE_CONTEXT,
  CREATE_DIGEST,
  UPDATE_DIGEST,
  DELETE_DIGEST,
  FETCH_USERS,
  FETCH_DIGESTS,
} from '|/store/constants'

import * as types from './types'

import Overlay from '|/helpers/Overlay'
import Button from '|/helpers/Button'
import ConfirmButton from '|/helpers/ConfirmButton'
import Input from '|/helpers/Input'
import Plink from '|/helpers/Plink'

import styles from './main.styl'

const ContextForm = props => {

  const pathState = usePathState()
  const dispatch = useDispatch()
  const validate = useValidation()
  // const query = useSelector( state => getSearchStringAsObject(state) )
  const savedContext = useSelector( state => getSavedContext(state) )
  const savedDigest = useSelector( state => getSavedDigest(state) )
  const [ context_id, setContext_id ] = useState(undefined)
  const [ parent_context_id, setParent_context_id ] = useState(undefined)
  const { record, setRecord, nextRecord, setNextRecord, minimised, setMinimised } = useContext(LeftEditorContext)
  const context = useSelector( state => getContext(state,context_id) )
  const parentContext = useSelector( state => getContext(state,parent_context_id) )
  const [ attachmentUploadState, setAttachmentUploadState ] = useState(undefined)
  const [ form_is_valid, setForm_is_valid ] = useState(false)
  const [ saving, setSaving ] = useState(false)
  const [ controls, setControls ] = useState(undefined)

  const _onChange = (key,value)=> {
    switch(key) {
      case 'attachments':
        if(!nextRecord.attachments || value.length > nextRecord.attachments.length) {
          const new_value = differenceBy(value,nextRecord.attachments,'filename')
          new_value.map( attachment =>
            dispatch({
              type: ADD_ATTACHMENT,
              attachment,
              state: attachmentUploadState,
              setState: setAttachmentUploadState,
            })
          )
        } else {
          _updateFormValue(key,value)
        }
        break
      default:
        _updateFormValue(key,value)
    }
  }

  const _updateFormValue = (key,value)=> {
    const new_controls = {
      ...controls,
    }
    new_controls[key] = {
      ...controls[key],
      value,
      touched: true,
      valid: controls[key].validation ? validate(value,controls[key].validation) : true,
    }
    const _valid = Object.values(new_controls).reduce((bool,ctrl)=> (ctrl.valid === undefined || ctrl.valid) && bool ? true : false, true )
    setForm_is_valid(_valid)
    setControls(new_controls)
    setNextRecord({
      ...nextRecord,
      [key]: value,
    })
  }

  const _onOverlayMinimise = ()=> {
    setMinimised(true)
  }

  const _onOverlayUnMinimise = ()=> {
    setMinimised(false)
  }

  const _onOverlayScroll = ({ top, bottom })=> {
    setIs_at_bottom(bottom === 0)
  }

  const _onCancelClick = (e)=> {
    cancel()
  }

  const _onSubmitClick = (e)=> {
    _submitForm()
  }

  const _onDeleteClick = ()=> {
    switch(nextRecord.type) {
      case 'digest':
        dispatch({
          type: DELETE_DIGEST,
          digest_id: nextRecord.id,
          callback: postDigestDelete,
        })
        break
      default:
        dispatch({
          type: DELETE_CONTEXT,
          context_id: nextRecord.id,
          callback: cancelAndLeave,
        })
    }
  }

  const postDigestDelete = ()=> {
    setRecord(undefined)
    setNextRecord(undefined)
    dispatch({
      type: FETCH_DIGESTS,
      id: parent_context_id
    })
  }

  const cancel = ()=> {
    setRecord(undefined)
    setNextRecord(undefined)
  }

  const cancelAndLeave = ()=> {
    const to = {
      left: {
        c: nextRecord.parent_context_id,
      }
    }
    const redirectTo = pathState.toString(pathState.merge(to))
    console.log('TO',to)
    history.replace(redirectTo)
    cancel()
  }

  const createRecord = ()=> {
    const formData = nextRecord
    formData.context_ids = [
      ...(formData.context_ids ? formData.context_ids : []),
      context ? context.id : undefined
    ]
    switch(nextRecord.type) {
      case 'digest':
        dispatch({
          type: CREATE_DIGEST,
          context_id: nextRecord.context_id,
          digest_id: nextRecord.parent_digest_id,
          digest: formData,
        })
        break
      default:
        dispatch({
          type: CREATE_CONTEXT,
          context_id: nextRecord.parent_context_id,
          context: formData,
        })
    }
  }

  const updateRecord = ()=> {
    const formData = Object.keys(controls).reduce((obj,key)=> {
      obj[key] = controls[key].value
      return obj
    },{})
    switch(nextRecord.type) {
      case 'digest':
        dispatch({
          type: UPDATE_DIGEST,
          id: nextRecord.id,
          digest: formData,
        })
        break
      default:
        dispatch({
          type: UPDATE_CONTEXT,
          id: nextRecord.id,
          context: {
            ...formData,
            type: nextRecord.type
          },
        })
    }
  }

  const _onSubmit = (e)=> {
    e.preventDefault()
    _submitForm()
  }

  const _submitForm = ()=> {
    setSaving(true)
    if(nextRecord.id)
      updateRecord()
    else
      createRecord()
  }

  useEffect(()=> {
    dispatch({
      type: FETCH_USERS
    })
  },[])

  useEffect(()=> {
    // if(record && !nextRecord) {
    if(record) {
      setNextRecord({
        ...record,
      })
    }
    setContext_id(record.context_id || record.id)
    setParent_context_id(record.parent_context_id)
  },[ record ])

  useEffect(()=> {
    dispatch({
      type: FETCH_CONTEXT,
      id: context_id
    })
  },[ context_id ])

  // useEffect(()=> {
  //   dispatch({
  //     type: FETCH_CONTEXT,
  //     id: parent_context_id
  //   })
  //   dispatch({
  //     type: FETCH_CONTEXTS,
  //     id: parent_context_id
  //   })
  // },[ parent_context_id ])

  useEffect(()=> {
    // TODO
    if(nextRecord && !controls) {
      const new_controls = {
        ...controlsGenerator(record,nextRecord)
      }
      Object.keys(new_controls).map( key => {
        new_controls[key].valid = new_controls[key].validation ? validate(new_controls[key].value,new_controls[key].validation) : true
      })
      setControls(new_controls)
      const _valid = Object.values(new_controls).reduce((bool,ctrl)=> (ctrl.valid === undefined || ctrl.valid) && bool ? true : false, true )
      setForm_is_valid(_valid)
    }
  },[ nextRecord ])

  useEffect(()=> {
    if(saving && (savedContext || savedDigest)) {
      setRecord(undefined)
      setNextRecord(undefined)
    }
  },[ savedContext, savedDigest ])

  useEffect(()=> {
    if(attachmentUploadState) {
      const new_attachments = Object.values(attachmentUploadState).reduce((arr,a)=> ( arr.push(a), arr ),[])
      _updateFormValue('attachments',unionBy(new_attachments,nextRecord.attachments,'filename'))
      setAttachmentUploadState(undefined)
    }
  },[ attachmentUploadState ])

  if(!nextRecord) return null

  const Type = types[nextRecord.type.capitalise()]

  const Footer = ()=>
    <div>
      <Button
        type="submit"
        disabled={ saving || !form_is_valid }
        onClick={ _onSubmitClick }
      >
        { saving ? 'Saving...' : 'Save' }
      </Button>
      <Button
        onClick={ _onCancelClick }
      >
        { 'Cancel' }
      </Button>
      { nextRecord.id &&
        <ConfirmButton
          kind={ 'destructive' }
          onClick={ _onDeleteClick }
        >
          Delete
        </ConfirmButton>
      }
    </div>

  return (
    <Overlay
      title={ `${record.id ? 'Editing' : 'Creating'} ${record.type}` }
      className={ styles.container }
      container=".left"
      minimised={ minimised }
      onMinimise={ _onOverlayMinimise }
      onUnMinimise={ _onOverlayUnMinimise }
      footer={ Footer }
    >
      <div
        className={ styles.content }
      >
        <div className={ styles.header }>
          { context ?
              <Plink
                to={{
                  left: {
                    s: 'max',
                    c: context.id
                  },
                  search: {
                    main_context_id: context.parent_context_id || context.id
                  }
                }}
              >
                <Button
                  material={ false }
                  link={ true }
                  icon="arrow-left"
                >{ context.title }</Button>
              </Plink>
            :
              nextRecord.parent_digest_id ?
                ( `New reply` )
              :
                ( `New ${nextRecord.type}` )
          }
          { parentContext &&
            <Fragment>
            &nbsp;in&nbsp;
            <Plink
              to={{
                left: {
                  s: 'max',
                  c: parentContext.id
                }
              }}
            >
              <Button
                material={ false }
                link={ true }
                icon="arrow-left"
              >{ parentContext.title }</Button>
            </Plink>
            </Fragment>
          }
        </div>
        { controls &&
          <Type
            onChange={ _onChange }
            onSubmit={ _onSubmit }
            controls={ controls }
            nextRecord={ nextRecord }
            context={ context }
            parentContext={ parentContext }
            minimised={ minimised }
          />
        }
      </div>
    </Overlay>
  )
}


export default ContextForm