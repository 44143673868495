import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '|/helpers/Button'

import styles from './main.styl'

const ConfirmButton = ({ className, children, prompt, ...props })=> {

  const [ prompted, setPrompted ] = useState(false)

  const _onClick = (e)=> {
    if(prompted && props.onClick)
      props.onClick()
    else
      setPrompted(true)
  }

  const classList = [ styles.container, className ]
  
  return (
    <Button
      { ...props }
      onClick={ _onClick }
      className={ classList.join(' ') }
    >
      { prompted ?
          prompt
        :
          children
      }
    </Button>
  )
}

ConfirmButton.propTypes = {
  prompt: PropTypes.string,
  onClick: PropTypes.func,
}

ConfirmButton.defaultProps = {
  prompt: 'Are you sure?',
  onClick: undefined,
}

export default ConfirmButton